import { useEffect, useState } from 'react';
import postal from 'postal';
import { defineMessages } from '@alltrails/shared/react-intl';
import iconListSrc from 'images/icons/search/icon-list-view.svg';
import iconMapSrc from 'images/icons/search/icon-map-view.svg';

import useFormatMessage from '@alltrails/shared/hooks/useFormatMessage';

import * as styles from './styles/styles.module.scss';

const TOGGLE_STRINGS = defineMessages({
  MAP_VIEW: { defaultMessage: 'Map view' },
  LIST_VIEW: { defaultMessage: 'List view' }
});

const messagingChannel = postal.channel('explore_messagingChannel');

type MobileExploreMapSwitchProps = {
  showMapMobile: boolean;
};

const MobileExploreMapSwitch = ({ showMapMobile }: MobileExploreMapSwitchProps): JSX.Element => {
  const [listView, setListView] = useState(!showMapMobile);
  const {
    formattedDefaultMessages: { MAP_VIEW, LIST_VIEW }
  } = useFormatMessage(TOGGLE_STRINGS);

  useEffect(() => {
    messagingChannel.subscribe('map.show', () => setListView(false));
    messagingChannel.subscribe('map.hide', () => setListView(true));
  });

  const handleClick = () => {
    messagingChannel.publish(listView ? 'map.show' : 'map.hide');
    setListView(!listView);
  };

  return (
    <button type="button" className={styles.exploreMapSwitch} onClick={handleClick}>
      <img alt={listView ? MAP_VIEW : LIST_VIEW} src={listView ? iconMapSrc : iconListSrc} />
    </button>
  );
};

export default MobileExploreMapSwitch;
