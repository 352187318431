import isEqual from 'underscore/modules/isEqual';
import { FormattedMessage } from '@alltrails/shared/react-intl';
import mapboxgl from 'mapbox-gl';
import { decodeSegmentLngLat, getLineColor, getSegments } from '@alltrails/maps/utils/legacyGeoJSONConversions';
import turfDistance from '@turf/distance';
import { routePoints } from './routing';
import { encodeSegmentLngLat, lngLatBoundsToAtBounds, lngLatToAtLocation, buildAtMap } from '../at_map_helpers';
import { getFirstPointMatch, getNodePosition, pointComesFirst, isSamePoint } from './overlap_helpers';
import { RoutePolyline } from './route_polyline';
import { RouteNode } from './route_node';

class TrailPlanner {
  constructor({
    onRouteInfoChange,
    route,
    routingActive,
    routingMode = 'hiking',
    updateProgressDialog,
    routeStart = null,
    isInsertTrailPlanner = false,
    insertEndPoint = null,
    isMultiLineCut = false,
    slidRoute = null
  }) {
    this.hasChanged = false; // Flag for whether or not the route has been modified
    this.onRouteInfoChange = onRouteInfoChange; // Called on checkRoute()
    this.route = route;
    this.color = getLineColor(route);
    this.endsCoincide = false;
    this.routingActive = routingActive; // Flag for smart-routing
    this.routingMode = routingMode; // Transit-mode for smart-routing
    this.routeStart = routeStart; // lngLatLike
    this.routePolylines = []; // [RoutePolyline] - All of the edges composing a new route
    this.routingInProgress = false;
    this.routeNodes = []; // [RouteNode] - All of the nodes composing a new route
    this.undoStack = []; // [[ RouteState ]]
    this.redoStack = []; // [[ RouteState ]]
    this.updateProgressDialog = updateProgressDialog;
    this.lastAddedRouteNode = null;
    this.multiPointNodeCoords = [];
    this.isInsertTrailPlanner = isInsertTrailPlanner;
    this.insertEndPoint = insertEndPoint; // lngLatLike | null
    this.isMultiLineCut = isMultiLineCut; // boolean, if initiating cut mode between 2 multipoint nodes
    this.slidRoute = slidRoute;
  }

  initRoutePolylines() {
    const segments = getSegments(this.route);
    if (segments.length < 1) {
      this.checkRoute();
      this.updateUndoStack();
      return;
    }
    segments.forEach(segment => {
      this.addPolyline(decodeSegmentLngLat(segment));
    });
  }

  outAndBack() {
    // Turn into "out and back" with simple retrace along current path.

    // Do nothing if no line, or if end point is already the same as the start point:
    if (this.routePolylines.length < 1) {
      return;
    }
    const startPt = this.getEndPt(0);
    const endPt = this.getEndPt(1);
    if (isEqual(startPt, endPt)) {
      return;
    }

    for (let i = this.routePolylines.length - 1; i >= 0; i -= 1) {
      // ignore start point
      this.routePolylines.push(this.routePolylines[i].getCopy(true));
    }
    this.checkRoute();
    this.updateUndoRedoStacks();
  }

  reverseRoute() {
    // Do nothing if no line
    if (this.routePolylines.length < 1) {
      return;
    }

    const reversed = [];
    for (let i = this.routePolylines.length - 1; i >= 0; i -= 1) {
      reversed.push(this.routePolylines[i].getCopy(true));
    }
    this.routePolylines = reversed;

    this.hasChanged = true;
    this.checkRoute();
    this.updateUndoRedoStacks();
  }

  returnToStart() {
    // Try to route back to start through trail network.

    // Do nothing if no line, or if end point is already the same as the start point:
    if (this.routePolylines.length < 1) {
      return;
    }
    const startPt = this.getEndPt(0);
    const endPt = this.getEndPt(1);
    if (isEqual(startPt, endPt)) {
      return;
    }

    // Route back to start point
    this.routeToPoint(startPt);
  }

  routeToPoint(lngLat, ignoreOrigEnd = false) {
    // Skip this point if routing to another point is still in progress to prevent using a stale endpoint
    if (this.routingInProgress) {
      return;
    }

    this.routingInProgress = true;
    if (!this.routingActive || !this.routeStart) {
      this.addPoint(lngLat);
      this.routingInProgress = false;
      return;
    }
    const endPt = this.getEndPt(1);
    return routePoints(this.routingMode, [endPt, lngLat], false, ignoreOrigEnd, this.slidRoute)
      .then(points => {
        this.routePolylines.push(new RoutePolyline(points));
        // notify rest of system that change has been made to route
        this.hasChanged = true;
        this.checkRoute(true, this.routePolylines.length);
        this.updateUndoRedoStacks();
        this.routingInProgress = false;
      })
      .catch(err => {
        this.handleRoutingError(err);
        this.routingInProgress = false;
      });
  }

  pointsAsPromise(pts, ignoreOrigStart, ignoreOrigEnd) {
    if (!this.routingActive) {
      return Promise.resolve(pts);
    }
    return routePoints(this.routingMode, pts, ignoreOrigStart, ignoreOrigEnd, this.slidRoute).catch(err => this.handleRoutingError(err));
  }

  handleRoutingError(error) {
    if (!this.updateProgressDialog) {
      return;
    }
    // Error code of 442 is returned by Valhalla when it is impossible to route to a specific point (e.g. no graph edges reach the destination)
    if (error && error.error_code == 442) {
      this.updateProgressDialog('error-cannot-route');
    } else {
      this.updateProgressDialog('error-routing');
    }
  }

  addPoint(lngLat) {
    this.hasChanged = true;
    if (!this.routeStart) {
      this.routeStart = lngLat;
    } else {
      this.addGapFillIfNeeded(lngLat);
    }
    this.checkRoute();
    this.updateUndoRedoStacks();
  }

  addPolyline(points) {
    this.hasChanged = true;
    if (!this.routeStart) {
      this.routeStart = points[0];
    } else {
      this.addGapFillIfNeeded(points[0]);
    }
    this.routePolylines.push(new RoutePolyline(points));
    this.checkRoute();
    this.updateUndoRedoStacks();
  }

  addGapFillIfNeeded(lngLat) {
    const endPt = this.getEndPt(1);
    if (!isEqual(endPt, lngLat)) {
      this.routePolylines.push(new RoutePolyline([endPt, lngLat]));
    }
  }

  // used for dragging to draw polyline
  startDynamicPolyline(lngLat) {
    const points = [];
    const endPt = this.getEndPt(1);
    if (endPt) {
      points.push(endPt);
    }
    if (lngLat) {
      points.push(lngLat);
    }
    this.routePolylines.push(new RoutePolyline(points));
    this.checkRoute(false);
  }

  endDynamicPolyline(lngLat) {
    this.hasChanged = true;
    const dynamicPolyline = this.routePolylines[this.routePolylines.length - 1];
    const { points } = dynamicPolyline;
    if (lngLat) {
      points.push(lngLat);
      // route to endpoint if the drag end is within 10m of endpoint (only for insert mode)
      if (this.isInsertTrailPlanner) {
        const closePoint = this.insertEndPoint || this.routeStart;
        const startToEnd = turfDistance(lngLat, closePoint) * 1000;
        if (startToEnd <= 10) {
          points.push(closePoint);
        }
      }
    }
    if (dynamicPolyline && dynamicPolyline.points.length < 2) {
      this.routePolylines.pop();
    } else {
      this.updateUndoRedoStacks();
    }
    this.checkRoute();
  }

  updateDynamicPolyline(lngLat) {
    const dynamicPolyline = this.routePolylines[this.routePolylines.length - 1];
    const { points } = dynamicPolyline;
    points.push(lngLat);
    if (points.length > 1) {
      this.hasChanged = true;
      this.checkRoute(false);
    }
  }

  updateUndoRedoStacks() {
    this.updateUndoStack();
    this.updateRedoStack();
  }

  updateUndoStack() {
    this.undoStack.push({
      routeStart: this.routeStart ? this.routeStart.slice() : null,
      routePolylines: this.routePolylines.slice(),
      multiPointNodeCoords: this.multiPointNodeCoords ? this.multiPointNodeCoords.slice() : []
    });
  }

  updateRedoStack() {
    this.redoStack = [];
  }

  updateRouteState(routeState) {
    this.routeStart = routeState.routeStart ? routeState.routeStart.slice() : null;
    this.routePolylines = routeState.routePolylines.slice();
    this.multiPointNodeCoords = routeState.multiPointNodeCoords ? routeState.multiPointNodeCoords.slice() : [];
  }

  undo() {
    const { undoStack } = this;
    if (undoStack.length > 0) {
      this.hasChanged = true;
      // remove state from undo stack and tack onto redoStack
      this.redoStack.push(this.undoStack.pop());
      // update routePolylines to last state's routePolylines
      const routeState = undoStack[undoStack.length - 1] || { routePolylines: [], routeStart: null };
      this.updateRouteState(routeState);
      // update rendering of route
      this.checkRoute();
    }
  }

  redo() {
    const { redoStack } = this;
    if (redoStack.length > 0) {
      this.hasChanged = true;
      // remove state from redoStack
      const routeState = redoStack.pop();
      // update state to that of popped state
      this.updateRouteState(routeState);
      // move popped state back onto undoStack
      this.updateUndoStack();
      // update rendering of route
      this.checkRoute();
    }
  }

  buildRouteNodes(lastAddedRouteNodeIdx = null) {
    let distance = 0.0;
    const routeNodes = [];
    this.routePolylines.forEach((routePolyline, i) => {
      distance += routePolyline.getLength();
      const endPts = routePolyline.getEndPts();
      const position = getNodePosition(this.routePolylines, i);

      routeNodes.push(new RouteNode([i], endPts[0], position));

      // create end point marker node
      if (i === this.routePolylines.length - 1) {
        routeNodes.push(new RouteNode([i + 1], endPts[1]));
        if (this.insertEndPoint) {
          routeNodes.push(new RouteNode([i + 2], this.insertEndPoint));
        }
      }
    });
    if (routeNodes.length < 1) {
      routeNodes.push(new RouteNode([0], this.routeStart));
      if (this.insertEndPoint) {
        routeNodes.push(new RouteNode([1], this.insertEndPoint));
      }
    }

    // remove duplicate nodes if they are multipoint nodes (one node for multiple overlapping points)
    // but keep idx in node.idxs array for proper reference to routePolylines
    this.routeNodes = routeNodes.reduce((nodes, node, i) => {
      // if it's not one of the mutlipoint nodes, keep it
      if (this.multiPointNodeCoords && !this.multiPointNodeCoords.find(coords => isSamePoint(coords, node.lngLat))) {
        nodes.push(node);
        // if it is one of the multipoint nodes, only keep one
      } else {
        const multiNode = nodes.find(n => isSamePoint(n.lngLat, node.lngLat));
        if (multiNode) {
          multiNode.idxs.push(i);
          multiNode.position = 'all';
        } else {
          nodes.push(node);
        }
      }
      return nodes;
    }, []);

    this.lastAddedRouteNode = lastAddedRouteNodeIdx !== null ? this.routeNodes.find(({ idxs }) => idxs.includes(lastAddedRouteNodeIdx)) : null;

    return distance;
  }

  checkRoute(triggerFullChange = true, lastAddedRouteNodeIdx = null) {
    const distance = this.buildRouteNodes(lastAddedRouteNodeIdx);
    const routeStartPt = this.getEndPt(0);
    const routeEndPt = this.getEndPt(1);
    this.endsCoincide = isEqual(routeStartPt, routeEndPt);
    this.onRouteInfoChange({
      distance: distance * 1000, // km -> m
      undo_available: this.undoStack.length > 0,
      redo_available: this.redoStack.length > 0,
      rtsAvailable: !this.endsCoincide && !this.insertEndPoint,
      routeStartPt,
      routeEndPt,
      endsCoincide: this.endsCoincide,
      fullChange: triggerFullChange,
      hasChanged: this.hasChanged,
      plannerMap: this.toAtMap()
    });
  }

  getEndPt(end) {
    if (this.routePolylines.length === 0) {
      return this.routeStart;
    }
    const routeItem = end === 0 ? this.routePolylines[0] : this.routePolylines[this.routePolylines.length - 1];
    if (!routeItem) {
      return null;
    }
    return routeItem.getEndPt(end);
  }

  // A route node may represent multiple polyline crop points:
  // (start) ---polyline1---(Node1 on out )---polyline2---
  // ( end ) ---polyline3---(Node1 on back)---polyline2---
  // Node1 would have the following properties:
  // id: 1, idxs: [1,2]
  async routeNodeMoved(node, lngLat) {
    // the node passed is from the mapbox dragend feature properties. mapbox converts arrays into strings for some reason
    // the following 2 lines will convert the strings back to arrays
    const fromLngLat = this.mbPropStringToArray(node.lngLat);
    const idxs = this.mbPropStringToArray(node.idxs);
    for (const idx of idxs) {
      let spliceAt = idx;
      const toAddPromises = [];

      if (idx === 0) {
        // cannot move the start point when in insert mode
        if (this.isInsertTrailPlanner) {
          return;
        }
        this.routeStart = lngLat;
      }
      const prevPolyline = this.routePolylines[idx - 1];
      // any node but the start
      if (prevPolyline) {
        spliceAt -= 1;
        // ignore origin end
        toAddPromises.push(this.pointsAsPromise([prevPolyline.getEndPt(0), lngLat], false, true));
      }
      const nextPolyline = this.routePolylines[idx];
      if (nextPolyline) {
        // ignore origin start
        toAddPromises.push(this.pointsAsPromise([lngLat, nextPolyline.getEndPt(1)], true, false));
      }
      const toRemove = [prevPolyline, nextPolyline].filter(p => !!p);
      await this.updatePolylinesHelper(spliceAt, toRemove, toAddPromises);
    }
    // if the node moved was a multipoint node, update multiPointNodeCoords before checkRoute
    const nodeCoordsIndex = this.multiPointNodeCoords.findIndex(point => isSamePoint(fromLngLat, point));
    if (nodeCoordsIndex >= 0) {
      this.multiPointNodeCoords[nodeCoordsIndex] = lngLat;
    }
    this.checkRoute();
    this.updateUndoRedoStacks();
  }

  // A route node may represent multiple polyline crop points. see comment above this.routeNodeMoved
  async routeNodeRemoved(node) {
    // the node passed is from the mapbox click feature properties. mapbox converts arrays into strings for some reason
    // the following 2 lines will convert the strings back to arrays
    const fromLngLat = this.mbPropStringToArray(node.lngLat);
    const idxs = this.mbPropStringToArray(node.idxs);
    let joins = 0;
    for (const idx of idxs) {
      // if a multipoint node is being removed, we must adjust the index after each polyline has been joined
      // polylines will be joined in order from trail start to trail end, so the adjustment will always remove 1 from the node index
      const adjustedIdx = idx - joins;
      let spliceAt = adjustedIdx;
      const toAddPromises = [];

      const prevPolyline = this.routePolylines[adjustedIdx - 1];
      if (prevPolyline) {
        spliceAt -= 1;
        if (adjustedIdx === 1) {
          this.routeStart = prevPolyline.getEndPt(0);
        }
      }
      const nextPolyline = this.routePolylines[adjustedIdx];
      if (nextPolyline && adjustedIdx === 0) {
        this.routeStart = nextPolyline.getEndPt(1);
      }
      if (prevPolyline && nextPolyline) {
        toAddPromises.push(this.pointsAsPromise([prevPolyline.getEndPt(0), nextPolyline.getEndPt(1)]));
        joins++;
      } else if (!prevPolyline && !nextPolyline && adjustedIdx === 0) {
        this.routeStart = null;
      }
      const toRemove = [prevPolyline, nextPolyline].filter(p => !!p);
      await this.updatePolylinesHelper(spliceAt, toRemove, toAddPromises);
    }
    const nodeCoordsIndex = this.multiPointNodeCoords.findIndex(point => isSamePoint(fromLngLat, point));
    if (nodeCoordsIndex >= 0) {
      this.multiPointNodeCoords.splice(nodeCoordsIndex, 1);
    }
    this.checkRoute();
    this.updateUndoRedoStacks();
  }

  // merge the given trail planner into this instance.
  // replace will cut the line before the insert point (for cut mode)
  mergeRoutes(insertTrailPlanner, replace = false) {
    // find node along this instance that is the same as the given planner's start point
    const cropNode = this.routeNodes.find(({ lngLat }) => isSamePoint(insertTrailPlanner.routeStart, lngLat));
    const spliceAt = cropNode.idxs[0];
    const toRemove = replace ? 1 : 0;

    this.routePolylines.splice(spliceAt, toRemove, ...insertTrailPlanner.routePolylines);
    // if the cut boundaries are both multipoint nodes
    if (insertTrailPlanner.isMultiLineCut) {
      // reverse the line inserted above and insert it before the second point of the multipoint node
      const reversed = [];
      for (let i = insertTrailPlanner.routePolylines.length - 1; i >= 0; i -= 1) {
        reversed.push(insertTrailPlanner.routePolylines[i].getCopy(true));
      }
      // add lines added and subtract 2 to get index. One for the line removed in the above splice and one to get the polyline previous to the point
      // if it's a multi-line cut and the crop node does not have a second idx, the crop node must be the first/last (ends coincide)
      const secondSpliceAt = cropNode.idxs[1] ? cropNode.idxs[1] - 2 + insertTrailPlanner.routePolylines.length : this.routePolylines.length - 1;
      this.routePolylines.splice(secondSpliceAt, toRemove, ...reversed);
    }
    this.hasChanged = true;
    this.checkRoute();
    this.updateUndoRedoStacks();
  }

  async updatePolylinesHelper(spliceAt, toRemove, toAddPromises) {
    const values = await Promise.all(toAddPromises);
    const toAdd = values.map(points => new RoutePolyline(points));
    this.routePolylines.splice(spliceAt, toRemove.length, ...toAdd);
    this.hasChanged = true;
  }

  // Will force the route to be split on either the out or back portion of an overlapping point on the route
  getSplitAdjustment({ forceSplitOnTopLine = false, forceSplitOnBottomLine = false, forceSplitOnBothLines = false }, originalLocation) {
    if (!forceSplitOnTopLine && !forceSplitOnBottomLine && !forceSplitOnBothLines) {
      return originalLocation;
    }

    const newLocation = getFirstPointMatch(this.routePolylines, originalLocation.polylineIdx, originalLocation.cropIdx);
    const nextPointHasMatch = !!getFirstPointMatch(this.routePolylines, originalLocation.polylineIdx, originalLocation.cropIdx + 1);

    // if the crop index that comes before the new node location is the very last point of an overlapping segment, we dont adjust the location
    // because this means the new node is not on an overlapping segment
    if (!nextPointHasMatch) {
      return originalLocation;
    }

    const matchOnBack = newLocation && pointComesFirst(originalLocation, newLocation);
    const matchOnOut = newLocation && !matchOnBack;
    const changeLocation = (forceSplitOnTopLine && matchOnBack) || (forceSplitOnBottomLine && matchOnOut);
    // the closest crop point before the original node placement will be the closest crop point just after the new location.
    // this being the case, we need to subtract 1 from the crop index when adjusting to a different point so that the closest crop
    // point always comes before the node placement (to avoid drawing beyond the node and then back to it)
    if (changeLocation) {
      newLocation.cropIdx -= 1;
    }
    return changeLocation ? newLocation : originalLocation;
  }

  addNewRouteNode(newRouteNode, opts) {
    const closestIdx = newRouteNode.cropIdx;
    // Safety-first!
    if (!closestIdx && closestIdx !== 0) {
      return;
    }
    const cropLocation = { cropIdx: closestIdx, polylineIdx: newRouteNode.polylineIdx };
    const { lngLat } = newRouteNode;
    // handle forcing a split to both lines for overlapping segments. depends on opts passed
    if (opts.forceSplitOnBothLines) {
      // when cropping in 2 places, always make the first split on the top line (the latter point)
      // to avoid a cropIdx issue when new lines are added to the routePolylines array
      const location = this.getSplitAdjustment({ forceSplitOnTopLine: true }, cropLocation);
      const secondLocation = getFirstPointMatch(this.routePolylines, location.polylineIdx, location.cropIdx);
      if (secondLocation) {
        // see comment in getSplitAdjustment
        secondLocation.cropIdx -= 1;
        this.multiPointNodeCoords.push(lngLat);
        this.splitRouteAtNewNode(lngLat, location);
        this.splitRouteAtNewNode(lngLat, secondLocation);
        // Update state
        this.hasChanged = true;
        this.checkRoute(true, secondLocation.polylineIdx + 1);
        this.updateUndoRedoStacks();
        return;
      }
    }
    // handle forcing a split to top/bottom for overlapping segments. depends on opts passed
    const location = this.getSplitAdjustment(opts, cropLocation);
    this.splitRouteAtNewNode(lngLat, location);
    // Update state
    this.hasChanged = true;
    this.checkRoute(true, location.polylineIdx + 1);
    this.updateUndoRedoStacks();
  }

  splitRouteAtNewNode(lngLat, { cropIdx, polylineIdx }) {
    // Split line either just before or just after closest index
    const polyline = this.routePolylines[polylineIdx];
    const { points } = polyline;
    let firstHalf;
    let secondHalf;
    if (cropIdx === points.length - 1) {
      // break just before closest index
      firstHalf = points.slice(0, cropIdx); // end first half with prev
      secondHalf = points.slice(cropIdx, points.length); // start second half with closest
    } else {
      // break just after closest index
      firstHalf = points.slice(0, cropIdx + 1); // end first half with closest
      secondHalf = points.slice(cropIdx + 1, points.length); // start second half with next
    }
    // Push/unshift interpolated point onto split halves
    firstHalf.push(lngLat);
    secondHalf.unshift(lngLat);
    // Replace polyline with new splits
    this.routePolylines.splice(polylineIdx, 1, new RoutePolyline(firstHalf), new RoutePolyline(secondHalf));
  }

  mbPropStringToArray(lngLat) {
    if (Array.isArray(lngLat)) {
      return lngLat;
    }
    return lngLat
      .slice(1, -1)
      .split(',')
      .map(n => parseFloat(n));
  }

  toAtMap(mapConfig = {}) {
    // Build API compatible map for route
    // Based on data_manager.toJSON()
    const map = buildAtMap(
      {
        name: 'Trail Planner Map',
        ...mapConfig
      },
      <FormattedMessage defaultMessage="Map" />
    );

    const mapBounds = new mapboxgl.LngLatBounds();

    if (this.routePolylines.length > 0) {
      const { color } = this;
      let sequenceNum = 1;
      const lineSegments = this.routePolylines.map(routeItem => {
        const points = routeItem.getPts();
        points.forEach(pt => mapBounds.extend(pt));
        const lineSegment = encodeSegmentLngLat(points, sequenceNum);
        sequenceNum += 1;
        return lineSegment;
      });
      map.routes = [{ lineDisplayProperty: { color }, lineSegments, sequence_num: 1 }];
    }

    if (!mapBounds.isEmpty()) {
      map.bounds = lngLatBoundsToAtBounds(mapBounds);
      map.location = lngLatToAtLocation(mapBounds.getCenter());
    }

    return map;
  }
}

export { TrailPlanner };
