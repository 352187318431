import IconButton from '@alltrails/shared/denali/components/IconButton';
import Directions from '@alltrails/shared/icons/Directions';
import Share from '@alltrails/shared/icons/Share';
import type { User } from 'types/User';
import { ReactNode } from 'react';
import { useIntl } from '@alltrails/shared/react-intl';
import UserImageLink from '../UserImageLink';
import MenuPop from '../MenuPop';
import * as styles from './styles/styles.module.scss';

type Props = {
  handleDirectionsClick: () => void;
  handleShareClick: () => void;
  menuItems: { callback: () => void; label: ReactNode }[];
  user: User;
  menuAnchorElement?: HTMLButtonElement | null;
  setMenuAnchorElement?: (target: HTMLButtonElement | null) => void;
};

const HeaderButtons = ({ user, handleDirectionsClick, handleShareClick, menuItems, menuAnchorElement, setMenuAnchorElement }: Props) => {
  const intl = useIntl();

  return (
    <div className={styles.buttonPanel}>
      <div className={styles.userImage}>
        <UserImageLink user={user} />
      </div>
      <div className={styles.circleIcon}>
        <IconButton
          icon={{ Component: Directions }}
          onClick={handleDirectionsClick}
          title={intl.formatMessage({ defaultMessage: 'Directions' })}
          testId="map-header-directions"
          variant="elevated"
        />
      </div>
      <div className={styles.circleIcon}>
        <IconButton
          icon={{ Component: Share }}
          onClick={handleShareClick}
          title={intl.formatMessage({ defaultMessage: 'Share' })}
          testId="map-header-share"
          variant="elevated"
        />
      </div>
      <MenuPop variant="circle" items={menuItems} menuAnchorElement={menuAnchorElement} setMenuAnchorElement={setMenuAnchorElement} />
    </div>
  );
};

export default HeaderButtons;
