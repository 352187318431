import { useCallback, lazy, Suspense, useState, useContext, useEffect } from 'react';
import classNames from 'classnames';
import { object, func, bool, string } from 'prop-types';
import { useIntl, defineMessages, FormattedMessage } from '@alltrails/shared/react-intl';
import useFormatMessage from '@alltrails/shared/hooks/useFormatMessage';
import StarRating from '@alltrails/shared/denali/components/StarRating';
import Dropdown from '@alltrails/shared/components/Dropdown';
import DescriptiveRadioModal from '@alltrails/shared/components/modals/DescriptiveRadioModal';
import StarRatingInteractive from '@alltrails/shared/components/StarRatingInteractive';
import Tag from '@alltrails/shared/denali/components/Tag';
import BookmarkButton from '@alltrails/shared/components/BookmarkButton';
import { updateTrack } from '@alltrails/modules/ActivityUpload/requests';
import { useLocalizedText } from '@alltrails/shared/modules/Localization';
import { wrapEventHandler } from 'utils/handlers';
import useToggle from '@alltrails/shared/hooks/useToggle';
import BlockUserPage from '@alltrails/modules/Reporting/BlockUserPage';
import { monthDayYear } from '@alltrails/shared/utils/timeHelpers';
import { getActivityPrivacyOptions } from '@alltrails/modules/Privacy/privacyHelpers';
import useDifficultyText from '@alltrails/shared/hooks/useDifficultyText';
import CustomProvider from 'components/CustomProvider';
import useLanguageRegionCode from '@alltrails/shared/hooks/useLanguageRegionCode';
import useUser from 'hooks/useUser';
import useFeatures from 'hooks/useFeatures';
import Button from '@alltrails/shared/denali/components/Button';
import Typography from '@alltrails/shared/denali/components/Typography';
import ModalBackground from '@alltrails/shared/components/modals/ModalBackground';
import PlusBadge from '@alltrails/shared/denali/components/PlusBadge';
import { GarminContext } from 'components/GarminSettings/GarminProvider';
import hasPermission from 'utils/hasPermission';
import { buildMapMenuItems, buildTrackMenuItems } from '../../../utils/action_header_helper';
import { wrapUrlSafe } from '../../../utils/language_support_util';
import { ServerCommunicationUtil } from '../../../utils/server_communication_util';
import { getTrackProfilePhotoUrl } from '../../../utils/track_helpers';
import { visibilityToTranslatedOption } from '../../../utils/privacy_policy_helpers';
import { getImgDefaultStyle } from '../../cards/CardImage/CardImage';
import OriginalLanguageButton from '../OriginalLanguageButton';
import HeaderButtons from '../HeaderButtons';
import AggRatingStructuredData, { aggRatingDataAttributes } from '../AggRatingStructuredData';
import * as styles from './styles/styles.module.scss';

const ReviewFormModal = lazy(() => import('@alltrails/modules/TrailReviews/ReviewFormModal'));
const NewReviewFormModal = lazy(() => import('@alltrails/modules/TrailReviews/NewReviewFormModal'));

const messages = defineMessages({
  ADD_A_TRAIL_REVIEW: { defaultMessage: 'Add a trail review' },
  EDIT_REVIEW: {
    defaultMessage: 'Edit review'
  },
  ACTIVITY_PRIVACY_TITLE: { defaultMessage: 'Activity privacy level ' },
  ACTIVITY_PRIVACY_DESCRIPTION: { defaultMessage: 'Choose who can view this activity and where it will appear.' },
  SEND_TO_GARMIN: { defaultMessage: 'Send to Garmin' },
  UPDATE_ON_GARMIN: { defaultMessage: 'Update on Garmin' },
  REPORT_AN_ISSUE: { defaultMessage: 'Report an issue' }
});

const ActionHeader = ({
  context,
  data,
  isFavorite,
  handleFavoriteClick,
  bannerPhotoId,
  handleDirectionsClick,
  openDownloadModal,
  handleShareClick,
  handleDeleteClick,
  saveMapAsClone,
  type,
  editingEndpoint,
  handleEditEndpointClick,
  handleCancelEditEndpointClick,
  handleEditClick,
  excludeRatingStructuredData,
  handleRatingChange,
  rating,
  trackTrail,
  canEdit,
  openReportModal,
  setReportingSuccessToast,
  onActivityUpdate
}) => {
  const {
    formattedDefaultMessages: {
      ADD_A_TRAIL_REVIEW,
      EDIT_REVIEW,
      ACTIVITY_PRIVACY_TITLE,
      ACTIVITY_PRIVACY_DESCRIPTION,
      SEND_TO_GARMIN,
      UPDATE_ON_GARMIN,
      REPORT_AN_ISSUE
    }
  } = useFormatMessage(messages);

  const user = useUser();
  const intl = useIntl();
  let refreshMap;
  let updateHidden;
  const areReviews2Enabled = useFeatures().includes('reviews_v2');
  const [refreshed, toggleRefreshed] = useToggle(false);
  const [hidden, toggleHidden] = useToggle(data?.hidden);
  const [reviewModalOpen, setReviewModalOpen] = useState(false);
  const id = type === 'map' ? data.id : data.ID;
  const descriptionSource = data?.description_source || data?.description;
  const languageRegionCode = useLanguageRegionCode();
  const {
    canBeLocalized,
    toggleText,
    isLocalized,
    text: localizedText
  } = useLocalizedText(descriptionSource, data?.description_lang, languageRegionCode, {
    table: 'activities',
    column: 'description',
    row: id
  });
  const { canUpdateCourse, handleSendRoute, handleUpdateRoute } = useContext(GarminContext);
  const [currentRating, setCurrentRating] = useState(rating);
  const [comment, setComment] = useState(data?.comment);
  const [showActivityPrivacyModal, setShowActivityPrivacyModal] = useState(false);
  const [privacyOption, setPrivacyOption] = useState('');
  const [menuAnchorElement, setMenuAnchorElement] = useState(null);

  useEffect(() => {
    if (data?.contentPrivacy && type === 'track') {
      const translatedOption = visibilityToTranslatedOption(data.contentPrivacy, intl);
      setPrivacyOption(translatedOption);
    }
  }, [data?.contentPrivacy, intl]);

  const onReviewAdd = data => {
    setComment(data.detail?.comment);
  };

  const updateReview = newData => {
    setComment(newData.detail?.comment);
    if (areReviews2Enabled) {
      setCurrentRating(newData.detail?.rating);
      onActivityUpdate({
        ...data,
        comment: newData.detail?.comment,
        rating: newData.detail?.rating,
        ratingAttributes: newData.detail?.ratingAttributes,
        difficulty: newData.detail?.difficulty,
        obstacles: newData.detail?.obstacles,
        infoAttributes: newData.detail?.infoAttributes,
        ...(newData.detail?.associatedTrailReviewId && { associatedTrailReviewId: newData.detail?.associatedTrailReviewId })
      });
    }
  };

  if (hasPermission({ permission: 'trails:manage' })) {
    refreshMap = () => {
      ServerCommunicationUtil.getApiEndpoint(
        `/api/alltrails/maps/${id}/refresh_map_cache`,
        {},
        () => toggleRefreshed(true),
        err => console.log(err),
        () => {}
      );
    };

    updateHidden = () => {
      ServerCommunicationUtil.postApiEndpoint(
        `/api/alltrails/maps/${data.id}/metadata`,
        { hidden: !hidden },
        () => toggleHidden(!hidden),
        () => {},
        () => {}
      );
    };
  }

  const favoriteClick = () => {
    handleFavoriteClick({ type: data.presentationType, id, contentPrivacy: data.contentPrivacy });
  };

  const addAsNewMap = () => {
    window.open(wrapUrlSafe(`/trail/new?recording=${data.id}`, languageRegionCode), '_blank');
  };

  const getBannerImgStyle = useCallback(() => {
    const mapPhotoUrl = bannerPhotoId
      ? `/api/alltrails/photos/${bannerPhotoId}/image?size=large&key=${ServerCommunicationUtil.apiKey}`
      : getTrackProfilePhotoUrl(data);
    const backgroundImage = `url(${mapPhotoUrl})`;
    return getImgDefaultStyle(data.name, backgroundImage);
  }, [data, bannerPhotoId]);

  const isMapOwner = user?.id === data.user?.id;
  // eslint-disable-next-line no-nested-ternary
  const garminButtonText = user && canUpdateCourse ? UPDATE_ON_GARMIN : user ? SEND_TO_GARMIN : null;
  const garminButtonLabel = garminButtonText ? (
    <div className={styles.garminButton}>
      <PlusBadge className={styles.plusBadge} />
      {garminButtonText}
    </div>
  ) : null;
  // eslint-disable-next-line no-nested-ternary
  const garminCallback = user && canUpdateCourse ? handleUpdateRoute : user ? handleSendRoute : null;
  const reportIssueText = user && !isMapOwner ? REPORT_AN_ISSUE : null;
  const reportIssueCallback = user && !isMapOwner ? openReportModal : null;
  const blockUnblockUser =
    user && !isMapOwner && data && data.user ? (
      <BlockUserPage
        context={context}
        currentUserId={user.id}
        blockUserId={data.user.id}
        setSuccessToast={setReportingSuccessToast}
        customTitle
        onMenuClick={() => setMenuAnchorElement(null)}
      />
    ) : null;

  const getMenuItems = () => {
    if (type === 'map') {
      return buildMapMenuItems(
        data,
        openDownloadModal,
        reportIssueText,
        reportIssueCallback,
        blockUnblockUser,
        refreshed,
        refreshMap,
        hidden,
        updateHidden,
        handleDeleteClick,
        addAsNewMap,
        isMapOwner,
        saveMapAsClone,
        intl,
        user,
        garminButtonLabel,
        garminCallback
      );
    }
    if (type === 'track') {
      return buildTrackMenuItems(
        data,
        openDownloadModal,
        reportIssueText,
        reportIssueCallback,
        blockUnblockUser,
        editingEndpoint,
        handleEditEndpointClick,
        handleCancelEditEndpointClick,
        handleEditClick,
        refreshed,
        refreshMap,
        handleDeleteClick,
        saveMapAsClone,
        intl,
        user,
        garminButtonLabel,
        garminCallback
      );
    }
    return null;
  };

  const onPrivacyClick = () => {
    setShowActivityPrivacyModal(true);
  };

  const handlePrivacyChange = updatedPrivacy => {
    const activityData = {
      contentPrivacy: updatedPrivacy
    };
    updateTrack(data.id, activityData, data?.trailId)
      .then(payload => {
        setPrivacyOption(visibilityToTranslatedOption(updatedPrivacy, intl));
        if (onActivityUpdate) {
          onActivityUpdate({ ...data, contentPrivacy: payload[0].contentPrivacy });
        }
      })
      .catch(err => console.error('Updating activity privacy error:', err));
  };

  const includeRatingStructuredData = !excludeRatingStructuredData && data.rating !== null;

  const activityToUpperCase = name => name.charAt(0).toUpperCase() + name.slice(1);
  const shouldRenderRatingTags = areReviews2Enabled && data?.ratingAttributes && data?.ratingAttributes?.length > 0;
  const difficultyText = useDifficultyText(data?.difficulty || 0, true);
  const shouldRenderAttributes = data?.obstacles?.length > 0 || data?.infoAttributes?.length > 0 || difficultyText;
  const shouldRenderModal = (!areReviews2Enabled && data?.trailId && reviewModalOpen) || (areReviews2Enabled && reviewModalOpen);

  return (
    <CustomProvider>
      <div className={styles.container}>
        <div className={styles.banner} style={getBannerImgStyle()}>
          <BookmarkButton className={styles.bookmarkButton} isSelected={isFavorite} onClick={wrapEventHandler(favoriteClick)} />
        </div>
        <HeaderButtons
          user={data?.user}
          handleDirectionsClick={handleDirectionsClick}
          handleShareClick={handleShareClick}
          menuItems={getMenuItems()}
          menuAnchorElement={menuAnchorElement}
          setMenuAnchorElement={setMenuAnchorElement}
        />
        <div className={styles.metadata}>
          <div className={styles.dateAndActivity}>
            {monthDayYear(data.created_at, languageRegionCode)} {data.activity?.name ? `${'\u2022'} ${activityToUpperCase(data?.activity.name)}` : ''}
          </div>
          {type === 'track' && canEdit && (
            <div className={styles.privacyDropdown}>
              <Dropdown onClick={onPrivacyClick}>{privacyOption}</Dropdown>
            </div>
          )}
          {type === 'map' ? (
            <div className={styles.nameAndDescription}>
              <div className={styles.name}>{data.name}</div>
              <div className={styles.description}>{localizedText}</div>
              {canBeLocalized && <OriginalLanguageButton active={isLocalized} handleClick={toggleText} eventSource="Map Description" />}
            </div>
          ) : (
            <div className={styles.nameAndStars}>
              <div className={classNames(styles.trackName, areReviews2Enabled && styles.reviews2TrackName)}>{data.name}</div>
              {areReviews2Enabled ? (
                <>
                  <span className={styles.starsBlock} {...(includeRatingStructuredData ? aggRatingDataAttributes : {})}>
                    {canEdit && !reviewModalOpen ? (
                      <StarRatingInteractive config={currentRating} handler={() => setReviewModalOpen(true)} size="lg" />
                    ) : (
                      <StarRating onClick={canEdit ? () => setReviewModalOpen(true) : undefined} rating={currentRating} variant="complex" size="lg" />
                    )}
                    {includeRatingStructuredData && <AggRatingStructuredData rating={data.rating} />}
                  </span>
                  {shouldRenderRatingTags && (
                    <div className={styles.ratingTags}>
                      {data?.ratingAttributes?.map((attribute, index) => (
                        <Tag key={index} testId={attribute.uid} text={attribute.name} />
                      ))}
                    </div>
                  )}
                  {comment && <div className={styles.reviewComment}>{comment}</div>}
                  {shouldRenderAttributes && (
                    <div className={styles.attributes}>
                      {data?.obstacles?.length > 0 && (
                        <div>
                          <span>
                            <Typography variant="text200bold" className={styles.attributeTitle}>
                              <FormattedMessage defaultMessage="Conditions" />
                              {': '}
                            </Typography>
                            <Typography variant="text200">{data?.obstacles.map(obstacle => obstacle.name).join(', ')}</Typography>
                          </span>
                        </div>
                      )}
                      {data?.infoAttributes?.length > 0 && (
                        <div>
                          <span>
                            <Typography variant="text200bold" className={styles.attributeTitle}>
                              <FormattedMessage defaultMessage="Parking" />
                              {': '}
                            </Typography>
                            <Typography variant="text200">{data?.infoAttributes.map(attribute => attribute.name).join(', ')}</Typography>
                          </span>
                        </div>
                      )}
                      {data?.difficulty && (
                        <div>
                          <span>
                            <Typography variant="text200bold" className={styles.attributeTitle}>
                              <FormattedMessage defaultMessage="Difficulty" />
                              {': '}
                            </Typography>
                            <Typography variant="text200">{difficultyText}</Typography>
                          </span>
                        </div>
                      )}
                    </div>
                  )}
                  {canEdit && comment && (
                    // eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions
                    <div className={styles.editReviewLink} onClick={() => setReviewModalOpen(true)}>
                      {EDIT_REVIEW}
                    </div>
                  )}
                </>
              ) : (
                // Legacy reviews:
                <span className={styles.starsBlock} {...(includeRatingStructuredData ? aggRatingDataAttributes : {})}>
                  {canEdit && !reviewModalOpen ? (
                    <StarRatingInteractive config={rating} handler={handleRatingChange} size="lg" />
                  ) : (
                    <StarRating rating={rating} variant="complex" size="lg" className={styles.starRatingIconClass} />
                  )}
                  {includeRatingStructuredData && <AggRatingStructuredData rating={data.rating} />}
                  {/* eslint-disable-next-line no-nested-ternary */}
                  {canEdit && trackTrail ? (
                    comment ? (
                      <div>
                        <div className={styles.reviewComment}>{comment}</div>
                        {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions */}
                        <div className={styles.editReviewLink} onClick={() => setReviewModalOpen(true)}>
                          {EDIT_REVIEW}
                        </div>
                      </div>
                    ) : (
                      <Button
                        className={styles.addReviewBtn}
                        text={ADD_A_TRAIL_REVIEW}
                        onClick={() => setReviewModalOpen(true)}
                        testId="add-a-trail-review-button"
                        variant="default"
                      />
                    )
                  ) : null}
                </span>
              )}
              {/* End legacy reviews */}
            </div>
          )}
        </div>
        {shouldRenderModal && (
          <Suspense fallback={null}>
            {areReviews2Enabled ? (
              <NewReviewFormModal
                {...data}
                updateReview={updateReview}
                closeReviewModal={() => setReviewModalOpen(false)}
                isActivity
                onReviewAdd={updateReview}
              />
            ) : (
              <ReviewFormModal
                {...data}
                onReviewAdd={onReviewAdd}
                updateReview={updateReview}
                closeReviewModal={() => setReviewModalOpen(false)}
                activityReview
              />
            )}
          </Suspense>
        )}
        {showActivityPrivacyModal && (
          <ModalBackground closeModal={() => setShowActivityPrivacyModal(false)}>
            <DescriptiveRadioModal
              title={ACTIVITY_PRIVACY_TITLE}
              description={ACTIVITY_PRIVACY_DESCRIPTION}
              closeModal={() => setShowActivityPrivacyModal(false)}
              isMounted
              radioOptions={getActivityPrivacyOptions(intl)}
              acceptCallback={handlePrivacyChange}
              selected={data?.contentPrivacy || ''}
              isSave
            />
          </ModalBackground>
        )}
      </div>
    </CustomProvider>
  );
};

ActionHeader.propTypes = {
  context: object.isRequired,
  data: object.isRequired,
  isFavorite: bool,
  handleFavoriteClick: func,
  bannerPhotoId: string,
  handleDirectionsClick: func,
  openDownloadModal: func,
  handleShareClick: func,
  saveMapAsClone: func,
  type: string,
  editingEndpoint: bool,
  handleEditEndpointClick: func,
  handleCancelEditEndpointClick: func,
  handleEditClick: func,
  handleDeleteClick: func,
  excludeRatingStructuredData: bool,
  trackTrail: object,
  canEdit: bool
};

ActionHeader.defaultProps = {
  isFavorite: false,
  handleFavoriteClick: () => {},
  bannerPhotoId: null,
  handleDirectionsClick: () => {},
  openDownloadModal: () => {},
  handleShareClick: () => {},
  saveMapAsClone: () => {},
  type: '',
  editingEndpoint: false,
  handleEditEndpointClick: () => {},
  handleCancelEditEndpointClick: () => {},
  handleEditClick: () => {},
  handleDeleteClick: () => {},
  excludeRatingStructuredData: false,
  // eslint-disable-next-line react/default-props-match-prop-types
  handleRatingChange: () => {},
  // eslint-disable-next-line react/default-props-match-prop-types
  rating: 0,
  // eslint-disable-next-line react/default-props-match-prop-types
  openReportModal: () => {},
  // eslint-disable-next-line react/default-props-match-prop-types
  setReportingSuccessToast: () => {},
  // eslint-disable-next-line react/default-props-match-prop-types
  onActivityUpdate: () => {}
};

export default ActionHeader;
