import { Suspense, lazy, forwardRef } from 'react';
import mapboxgl from 'mapbox-gl';
import classNames from 'classnames';
import isEqual from 'underscore/modules/isEqual';
import { debounce } from 'debounce';
import { useIntl } from '@alltrails/shared/react-intl';
import logMapDetailsElevationGraphScrubbed from '@alltrails/analytics/events/logMapDetailsElevationGraphScrubbed';
import logTrailCardClicked from '@alltrails/analytics/events/logTrailCardClicked';
import { getLines } from '@alltrails/maps/utils/legacyGeoJSONConversions';
import { PageStrings, isSingleMapPage, isAtMapCollectionPage } from '@alltrails/shared/utils/constants/pageStringHelpers';
import FlyoverMapControls from 'components/flyover/FlyoverMapControls';
import FlyoverTransition from 'components/flyover/FlyoverTransition';
import { MapProvider } from 'components/MapProvider';
import { MapConsumerShim } from 'components/MapProvider/MapProvider';
import { FLYOVER_ANIM_DURATION_MS } from 'utils/flyoverHelpers';
import { clearCustomStyle } from 'utils/mapbox/style_helpers';
import fetchAndDecodeCoordinates from 'utils/mapbox/fetchAndDecodeCoordinates';
import MapExpandControl from 'components/MapExpandControl';
import CustomProvider from 'components/CustomProvider';
import CardLocation from '@alltrails/analytics/enums/CardLocation';
import { nearbyTrailsRequest } from 'utils/requests/at_map_requests';
import { wrapUrlSafe } from 'utils/language_support_util';
import ReportLocation from '@alltrails/analytics/enums/ReportLocation';
import hasPermission from 'utils/hasPermission';
import FlyoverUpsellWrapper from 'components/flyover/FlyoverUpsellWrapper';
import PrintButton from '../../../../../components/map_controls/PrintButton';
import ZoomControls from '../../../../../components/map_controls/ZoomControls';
import { addExploreItems } from '../../../../../utils/mapbox/overlays/explore_items';
import { addRemotePinMarker, removeRemotePinMarker } from '../../../../../utils/mapbox/overlays/remote_pin_markers';
import { addAtMap, DEFAULT_AT_MAP_PADDING } from '../../../../../utils/mapbox/overlays/at_map';
import { addLifeline } from '../../../../../utils/mapbox/overlays/lifeline';
import { fitMapToBounds } from '../../../../../utils/mapbox/map_helpers';
import { getAdminCustomizationSettings, setAdminCustomizationSetting } from '../../../../../utils/mapbox/admin_customization_helpers';
import { getLineCenterCoordinates } from '../../../../../utils/at_map_helpers';
import RouteControls from '../../../../../components/MapCreator/Routing/RouteControls';
import { MapMixin } from '../../mixins/map/map_mixin';
import { TrailPlannerMixin } from '../../mixins/map/trail_planner_mixin';
import { shallowCompare } from '../../../../../utils/compare';
import { removeWaypoints } from '../../../../../utils/mapbox/overlays/waypoints';
import { fireLayerEvent } from '../../../../../utils/mapbox/layers/layer_helpers';
import { additionalStyleLoaders, refreshOverlaysOnPan } from '../../../../../utils/mapbox/additionalStyleLoaders';
import Lightbox from '../../../../../components/shared/Lightbox';
import HelpButton from '../../../../../components/MapCreator/HelpButton';
import { addEditEndpointOverlay, removeEditEndpointOverlay } from '../../../../../utils/mapbox/overlays/edit_endpoint';
import { urlReformatPhotos } from '../../../../../utils/lightbox_helpers';
import MapSelection from '../../../../../components/MapSelection';
import * as styles from './styles/search_map.module.scss';

const ExploreMap = lazy(() => import('@alltrails/maps/components/ExploreMap'));
const ElevationChart = lazy(() => import('@alltrails/maps/components/ElevationChart'));

// eslint-disable-next-line @typescript-eslint/no-var-requires
const createReactClass = require('create-react-class');

const BaseFullscreenSearchMap = createReactClass({
  mixins: [MapMixin, TrailPlannerMixin],
  getInitialState() {
    return {
      currentLocation: null,
      currentMapLayer: this.props.initFlyoverOnLoad ? 'alltrailsSatellite' : 'alltrailsOutdoorsV2',
      elevationCollapsed: false,
      enabledOverlays: [],
      expanded: false,
      hoverCardPortal: null,
      isMapReady: false,
      trailPlanner: null,
      routingActive: true,
      routingMode: 'hiking',
      currentlyEditingRoute: null,
      showRoutingTips: { SMART_TIP: true, DRAW_TIP: true, NODE_TIP: true },
      selectedFilterActivity: '',
      terrainEnabled: false,
      currentLayerTerrainEnabled: true,
      adminCustomizationSettings: getAdminCustomizationSettings(),
      compassRotation: 0,
      terrainMapTipClosed: false,
      currentlyHoveredResult: null
    };
  },
  shouldComponentUpdate(nextProps, nextState) {
    return shallowCompare(this, nextProps, nextState, propKey => propKey !== 'resultCardFunctions');
  },
  initializeSearchMap() {
    if (!this.props.isNewMapsPage) {
      this.listeners.push(this.props.messagingChannel.subscribe('waypoint.hover', this.handleWaypointHover));
      this.listeners.push(this.props.messagingChannel.subscribe('route.mouseEnter', this.handleRouteHover));
      this.listeners.push(this.props.messagingChannel.subscribe('route.mouseLeave', this.handleRouteMouseLeave));
      this.listeners.push(this.props.messagingChannel.subscribe('item.mouseenter', debounce(this.handleItemMouseEnter, 300)));
      this.listeners.push(this.props.messagingChannel.subscribe('item.mouseleave', this.handleItemMouseLeave));
      this.listeners.push(this.props.messagingChannel.subscribe('print.navigate', this.handlePrintClick));
      this.editEndpointMessagingSubscriptions();
      this.trailPlannerMessagingSubscriptions();

      this.debouncedLogScrubbed = debounce(trailId => {
        logMapDetailsElevationGraphScrubbed({ trail_id: trailId });
      }, 200);

      this.initLegacyMap(false, this.props.locationData, this.props.boundingLocationBox, !hasPermission({ permission: 'trails:manage' }));
    }
  },
  handlePrintClick() {
    if (this.props.user?.pro) {
      const printUrl = wrapUrlSafe(
        `/explore/map/${this.props.selectedObject.slug}/print?${this.buildPrintMapUrlParams()}`,
        this.props.context.languageRegionCode
      );
      window.location.assign(printUrl);
    } else {
      const returnTo = window.location.pathname + window.location.search;
      const params = new URLSearchParams({ returnTo });
      window.location.assign(wrapUrlSafe(`/plus?${params.toString()}`, this.props.context.languageRegionCode));
    }
  },
  componentDidMount() {
    if (!this.listeners) {
      this.listeners = [];
    }

    this.initializeSearchMap();
  },

  // Waypoint-related
  handleWaypointHover({ id, location }) {
    const layerId = 'atMap-waypoints';
    const sourceId = layerId;

    // fire mouseenter causing map to pan to include waypoint and to show popup
    const lngLat = [location.longitude, location.latitude];
    const features = this.mbMap.querySourceFeatures(sourceId, {
      filter: ['match', ['get', 'id'], id, true, false]
    });
    if (features.length > 0) {
      fireLayerEvent(this.mbMap, layerId, 'mouseenter', { lngLat, features });
    }
  },
  handleRouteHover(routeId) {
    const layerId = 'atMap-polylines';
    const sourceId = layerId;
    const features = this.mbMap.querySourceFeatures(sourceId, {
      filter: ['match', ['get', 'lineId'], routeId, true, false]
    });

    const route = this.props.exploreMap.routes.find(r => r.id === routeId);
    const lngLat = getLineCenterCoordinates(route);

    if (features.length > 0 && lngLat) {
      fireLayerEvent(this.mbMap, layerId, 'mouseenter', { lngLat, features });
    }
  },
  handleRouteMouseLeave() {
    const layerId = 'atMap-polylines';
    fireLayerEvent(this.mbMap, layerId, 'mouseleave');
  },
  // track and trail hover response
  handleItemMouseEnter(item) {
    if (!this.props.isNewMapsPage) {
      addRemotePinMarker(this.mbMap, item, this.renderHoverCard);
    }
  },
  handleItemMouseLeave() {
    if (isSingleMapPage(this.props.currentPage)) {
      removeRemotePinMarker(this.mbMap);
    }
  },
  handleActivityFilterChange(e) {
    this.setState({ selectedFilterActivity: e.target.value });

    refreshOverlaysOnPan(this.mbMap, this.getOverlayConfigParams());
  },
  handleAdminCustomizationSettingsChange(key, value) {
    const adminCustomizationSettings = { ...this.state.adminCustomizationSettings, [key]: value };
    setAdminCustomizationSetting(key, value);
    this.setState({ adminCustomizationSettings });
  },
  // initNewMap is meant to emulate the same loading behavior as we have for the legacy map
  initNewMap() {
    this.mbMap = this.props.newMapInstance;
    this.setState({ isMapReady: true });
    this.props.onMapReady();
  },
  componentDidUpdate(prevProps, prevState) {
    // Handle internal component state for the new map similar to how we do for
    // the legacy map.
    if (!prevProps.newMapInstance && this.props.newMapInstance) {
      this.initNewMap();
      this.props.setShouldClearClickedResult(true);
      return;
    }

    if (prevProps.isNewMapsPage && !this.props.isNewMapsPage) {
      // the user has gone from a new map page to a legacy map page
      // We need to initialize the legacy map and listeners
      this.props.onMapReady(false);
      // eslint-disable-next-line react/no-did-update-set-state
      this.setState({ isMapReady: false });
      this.initializeSearchMap();
      // We are going to an activity detail page, and we need to make sure the styles are loaded
      if (this.props.currentPage === PageStrings.EXPLORE_USERS_TRACKS_MAP_PAGE && this.props.areActivitiesNewMapsEnabled) {
        return;
      }
    }

    if (this.props.isNewMapsPage) {
      // Reset this to avoid wiping a clicked or hovered result:
      this.props.setShouldClearClickedResult(false);

      // None of the logic below really applies for new maps.
      return;
    }

    if (!this.state.isMapReady) {
      return;
    }

    if (this.props.flyoverViewEnabled !== prevProps.flyoverViewEnabled && window) {
      // create a smoother resize animation for flyover
      const intervalTimeMs = 5;
      let resizeDispatchCount = 0;
      const interval = window.setInterval(() => {
        resizeDispatchCount += 1;
        window.dispatchEvent(new Event('resize')); // force change to be recognized by mapbox
        if (resizeDispatchCount > FLYOVER_ANIM_DURATION_MS / intervalTimeMs) {
          window.clearInterval(interval);
        }
      }, intervalTimeMs);
    }

    const pageChanged = this.props.currentPage !== prevProps.currentPage;
    const selectedObjectChanged = !isEqual(this.props.selectedObject, prevProps.selectedObject);
    if (pageChanged || selectedObjectChanged) {
      this.disableAllOverlays();
      clearCustomStyle(this.mbMap);
    }

    const areWaypointsChanged =
      prevProps.exploreMap?.waypoints?.length !== this.props.exploreMap?.waypoints?.length ||
      prevProps.exploreMap?.editWaypointId !== this.props.exploreMap?.editWaypointId;

    // if the waypoints are of unequal lengths or there is a new editing ID we need to remove waypoints layer
    // before re-rendering
    if (this.props.exploreMap && prevProps.exploreMap) {
      if (areWaypointsChanged) {
        removeWaypoints(this.mbMap, 'atMap-waypoints', this.props.messagingChannel);
      }
    }

    const singleMapPage = isSingleMapPage(this.props.currentPage);
    const mapLoadedChanged = this.isMapFirstRender(prevState);
    const resultsChanged = !singleMapPage && !isEqual(this.props.results, prevProps.results);
    if (!singleMapPage && (mapLoadedChanged || pageChanged || resultsChanged)) {
      // Draw results heatmap
      if (this.state.enabledOverlays.includes('heatmap')) {
        additionalStyleLoaders.heatmap.add(this.mbMap, this.getOverlayConfigParams());
      }
      // Draw clustered pins
      const bounds = new mapboxgl.LngLatBounds();
      // Wrap renderHoverCard so that we can make sure the DOM content is rendered
      // within the scope of the current React app. This allows us to use shared
      // contexts then in the hover content.
      const renderHoverCardWithContext = result => {
        // We won't be able to actually render into the portal until the element/ref is allocated.
        const onRefReceived = element => {
          // eslint-disable-next-line no-undef
          const hoverCardPortal = ReactDOM.createPortal(this.renderHoverCard(result), element);

          // Don't need to explicitly clean this up. The hover content will hide as expected when
          // the target ref/DOM element vanishes and the portal has nowhere to render to.
          this.setState({ hoverCardPortal });
        };

        return <div ref={onRefReceived} />;
      };
      if (!this.props.isNewMapsPage) {
        addExploreItems(
          this.mbMap,
          'results',
          this.props.results,
          renderHoverCardWithContext,
          bounds,
          hasPermission({ permission: 'trails:manage' })
        );
      }

      // Fit user recordings/maps pages to bounds,
      // because pins on these pages don't change based on the bounds of the map
      // (no Algolia use with private user maps)
      if (isAtMapCollectionPage(this.props.currentPage) && resultsChanged && (!prevProps.results || prevProps.results.length === 0)) {
        fitMapToBounds(this.mbMap, bounds);
      }
    } else if (
      (singleMapPage && (mapLoadedChanged || pageChanged || !isEqual(this.props.exploreMap, prevProps.exploreMap))) ||
      prevState.currentlyEditingRoute !== this.state.currentlyEditingRoute
    ) {
      // Draw exploreMap
      const bounds = new mapboxgl.LngLatBounds();
      // if user is using MapCreator but not editing show popups
      let polylineOpts;
      if (this.props.page === PageStrings.EXPLORE_USERS_MAPS_MAP_PAGE) {
        polylineOpts = { routes: this.mbMap, renderPopup: this.renderRoutePopup };
      }
      const isMapCreatorPage = this.props.page === PageStrings.EXPLORE_USERS_MAPS_MAP_PAGE && !this.props.exploreMap?.originalAtMapId;
      const withWaypointLabels = this.props.page !== PageStrings.EXPLORE_TRAIL_MAP_PAGE;
      addAtMap({
        map: this.mbMap,
        atMap: this.props.exploreMap,
        renderWaypointPopup: this.renderWaypointPopup,
        handlePhotoClick: this.handlePhotoClick,
        bounds,
        withWaypointLabels,
        messagingChannel: this.props.messagingChannel,
        polylineOpts,
        isMapCreatorPage
      });

      if (this.props.lifeline) {
        addLifeline(this.mbMap, this.props.exploreMap, this.renderWaypointPopup, bounds, this.props.lifeline);
      }

      if (prevProps.exploreMap?.waypoints && areWaypointsChanged) {
        fitMapToBounds(this.mbMap, bounds, DEFAULT_AT_MAP_PADDING, 0 /* duration */, true /* maintainZoomLevel */);
      } else {
        fitMapToBounds(
          this.mbMap,
          bounds,
          DEFAULT_AT_MAP_PADDING,
          0 /* duration */,
          false /* maintainZoomLevel */,
          this.props.page === PageStrings.EXPLORE_USERS_TRACKS_MAP_PAGE /* zoomOutByDefault */
        );
      }
    }
  },
  buildPrintMapUrlParams() {
    const center = this.getMapCenter();
    const zoom = this.getMapZoom();

    const params = new URLSearchParams({
      map_center_lat: center[0],
      map_center_lon: center[1],
      map_zoom: zoom,
      map_type: this.state.currentMapLayer
    });

    this.state.enabledOverlays.forEach(overlay => params.append('enabled_overlays[]', overlay));

    return params.toString();
  },
  // Start - Edit Endpoint Functionality (extracted from EditEndpointMixin)
  editEndpointMessagingSubscriptions() {
    if (this.listeners == null) this.listeners = [];
    this.listeners.push(this.props.messagingChannel.subscribe('map.edit-endpoint-state-changed', this.handleEditEndpointStateChanged));
  },
  handleEditEndpointStateChanged(newState) {
    if (!this.state.editingEndpoint && newState.editingEndpoint) {
      if (!this.props.exploreMap) {
        return;
      }
      const { tracks } = this.props.exploreMap;
      if (!tracks || tracks.length === 0) {
        return;
      }
      addEditEndpointOverlay(this.mbMap, tracks[tracks.length - 1], this.handleEndpointDragEnd);
      this.setState(newState);
    } else if (this.state.editingEndpoint) {
      if (newState.canceled) {
        this.clearEditState();
      } else {
        this.props
          .saveEditEndpointChanges({
            segmentId: this.state.endSegmentId,
            cropIndex: this.state.endSegmentCropIndex,
            newEndPoint: this.state.endSegmentNewPoint
          })
          .then(this.clearEditState);
      }
    }
  },
  handleEndpointDragEnd({ endSegmentId, endSegmentCropIndex, endSegmentNewPoint }) {
    this.setState({ endSegmentId, endSegmentCropIndex, endSegmentNewPoint });
  },
  clearEditState() {
    removeEditEndpointOverlay(this.mbMap);
    this.setState({ editingEndpoint: false });
  },
  handleElevationGainChanged(elevationGain) {
    if (this.props.handleRouteInfoChanged) {
      this.props.handleRouteInfoChanged({ elevationGain });
    }
  },
  handleElevationPaneHover(lngLat) {
    this.handleElevationPaneMouseOver(lngLat);
    if (this.props.currentPage === PageStrings.EXPLORE_TRAIL_MAP_PAGE) {
      this.debouncedLogScrubbed(this.props.exploreMap.trailId);
    }
  },

  onElevationChartToggle(hidden) {
    this.setState({ elevationCollapsed: hidden });
  },

  renderElevationPane() {
    if (this.state.trailPlanner || (isSingleMapPage(this.props.currentPage) && getLines(this.props.exploreMap).length > 0)) {
      return (
        <Suspense fallback={<div>Loading...</div>}>
          <ElevationChart
            map={this.props.plannerMap || this.props.exploreMap}
            displayMetric={this.props.context.displayMetric}
            handleMouseOver={this.handleElevationPaneHover}
            shouldFetchElevationData={this.state.trailPlanner}
            handleElevationGainChanged={this.handleElevationGainChanged}
            routeData={this.props.lifeline}
            isAdmin={hasPermission({ permission: 'trails:manage' })}
            onElevationChartToggle={this.onElevationChartToggle}
          />
        </Suspense>
      );
    }
    return null;
  },

  handlePhotoClick(photoId, photos) {
    const lightboxStartingIndex = photos.findIndex(p => p.id === photoId);
    this.setState({ lightboxOpen: true, lightboxStartingIndex, lightboxPhotos: photos });
  },

  handleLightboxClose() {
    this.setState({ lightboxOpen: false, lightboxStartingIndex: null, lightboxPhotos: [] });
  },

  render() {
    const onMapCreatorPage = this.props.exploreMap && !this.props.exploreMap.id;
    const showControls =
      isSingleMapPage(this.props.currentPage) &&
      this.props.currentPage !== PageStrings.EXPLORE_LIFELINE_PAGE &&
      !onMapCreatorPage &&
      !this.state.trailPlanner;

    const isAuthenticated = !!this.props.user;
    const isAdminOrPro = isAuthenticated && (hasPermission({ permission: 'trails:manage' }) || this.props.user.pro);

    const mapStyle = {
      left: this.props.isMobileWidth || this.props.isMapExpanded || this.props.flyoverViewEnabled ? 0 : this.props.divLeft
    };

    let mapBottomClass = '';
    let zoomControlsVariant = '';
    let mapFullClass = 'full-height';
    if (this.props.currentPage === 'EXPLORE_COMMUNITY_CONTENT') {
      mapFullClass += ' community';
    }
    const elevationPane = this.renderElevationPane();
    if (elevationPane) {
      if (!this.state.elevationCollapsed) {
        mapBottomClass = 'big withNewElevationChart';
        zoomControlsVariant = 'with-elevation-pane';
        mapFullClass += ' with-graph withNewElevationChart';
      }
    }

    if (typeof $ !== 'undefined') {
      if (this.state.elevationCollapsed || this.props.flyoverViewEnabled) {
        // eslint-disable-next-line no-undef
        $('.mapboxgl-ctrl-bottom-right').addClass('elevation-collapsed');
        // eslint-disable-next-line no-undef
        $('.mapboxgl-ctrl-bottom-left').addClass('elevation-collapsed');
      } else {
        // eslint-disable-next-line no-undef
        $('.mapboxgl-ctrl-bottom-right').removeClass('elevation-collapsed');
        // eslint-disable-next-line no-undef
        $('.mapboxgl-ctrl-bottom-left').removeClass('elevation-collapsed');
      }

      if (this.props.flyoverViewEnabled) {
        mapStyle.transition = `left ${FLYOVER_ANIM_DURATION_MS}ms`;
      }
    }

    const permissions = this.getPermissions();

    return (
      <div
        id="fullscreen-search-map"
        className={classNames(mapFullClass, {
          [styles.isShowingMobileMap]: this.props.isMobileWidth && this.props.isDisplayingMobileMap,
          expanded: this.props.isMapExpanded
        })}
        style={mapStyle}
      >
        <MapProvider
          getOverlayConfigParams={this.getOverlayConfigParams}
          isNewMapsPage={this.props.isNewMapsPage}
          isochroneData={this.props.isochroneData}
          mapInstance={this.mbMap}
          atMap={this.props.exploreMap}
          initFlyoverOnLoad={this.props.initFlyoverOnLoad}
          isFlyoverSupported={this.props.page === PageStrings.EXPLORE_TRAIL_MAP_PAGE}
        >
          <MapConsumerShim onMapMove={({ center, bounds, zoom }) => this.props.onMapBoundsChanged(center, bounds, zoom)} />
          <FlyoverTransition showOn={!this.props.flyoverViewEnabled}>
            <div>{this.props.isMobileWidth || this.props.flyoverViewEnabled ? null : <MapExpandControl />}</div>
          </FlyoverTransition>
          {this.props.isNewMapsPage || this.props.flyoverViewEnabled || this.renderTerrainTip()}
          <div className="map-routing-controls">
            {this.state.trailPlanner && (
              <RouteControls
                context={this.props.context}
                routingActive={this.state.routingActive}
                routingMode={this.state.routingMode}
                onSelectRoutingMode={this.setRoutingMode}
                toggleRouting={this.toggleRouting}
                showRoutingTips={this.state.showRoutingTips}
                closeRoutingTip={this.toggleRoutingTip}
                nodeSelected={this.state.selectedRouteNode}
              />
            )}
          </div>
          {this.props.isNewMapsPage ? (
            <Suspense fallback={<div>Loading...</div>}>
              <ExploreMap
                onLoad={
                  this.props.page === PageStrings.EXPLORE_USERS_TRACKS_PAGE && this.props.areActivitiesNewMapsEnabled
                    ? ({ center, bounds, zoom }) => this.props.onMapBoundsChanged(center, bounds, zoom)
                    : undefined
                }
                displayMetric={this.props.context.displayMetric}
                initialBounds={this.props.boundingLocationBox != null ? this.props.boundingLocationBox : this.props.initialBounds}
                initialCenter={
                  this.props.locationData
                    ? [this.props.locationData[0], this.props.locationData[1], this.props.initialCenter[2]]
                    : this.props.initialCenter
                }
                isochroneData={this.props.isochroneData}
                languageRegionCode={this.props.context.languageRegionCode}
                mapboxAccessToken={this.props.mapboxAccessToken}
                permissions={permissions}
                results={this.props.results}
                onMarkerDoubleClick={result => {
                  if (result.type === 'map') {
                    this.props.handleMapClick(result);
                  } else if (result.type === 'track') {
                    this.props.handleTrackClick(result);
                  } else {
                    logTrailCardClicked({ trail_id: result.ID, detailed_card_location: CardLocation.ExploreTabMapView });
                    this.props.handleTrailClick(result);
                  }
                }}
                user={this.props.user}
                renderPopupChild={this.renderHoverCard}
                fetchAndDecodeCoordinates={fetchAndDecodeCoordinates}
                hoveredResult={this.props.currentlyHoveredResult}
                shouldClearClickedResult={this.props.shouldClearClickedResult}
                isExploreTrailsMap={this.props.page === PageStrings.EXPLORE_ALL_PAGE}
                isExploreTracksMap={this.props.page === PageStrings.EXPLORE_USERS_TRACKS_PAGE}
                nearbyTrailsShown={this.props.nearbyTrailsShown}
                nearbyTrailsRequest={nearbyTrailsRequest}
                toggleNearbyTrailsOverlay={this.props.toggleNearbyTrailsOverlay}
                shouldHideMapLayers={this.props.shouldHideMapLayers}
              />
            </Suspense>
          ) : (
            <div id={this.props.mapDivId} className={classNames('full-height', { legacy: !this.props.isNewMapsPage })} />
          )}
          <FlyoverTransition showOn={!this.props.flyoverViewEnabled} unmountOnExit>
            {this.props.page === PageStrings.EXPLORE_USERS_MAPS_MAP_PAGE && this.state.trailPlanner && (
              <div className={classNames('map-creator-help-button-container', mapBottomClass)}>
                <HelpButton />
              </div>
            )}
          </FlyoverTransition>
          {this.props.flyoverViewEnabled && (
            <FlyoverUpsellWrapper
              disable3D={this.disable3D}
              exactExplorePath={this.props.exactExplorePath}
              initFlyoverOnLoad={this.props.initFlyoverOnLoad}
              onFlyoverDisabled={this.props.onFlyoverViewDisabled}
              setLayer={this.setLayer}
            />
          )}
          <FlyoverTransition showOn={this.props.flyoverViewEnabled}>
            {this.props.page === PageStrings.EXPLORE_TRAIL_MAP_PAGE && !!this.mbMap && !!this.props.exploreMap && (
              <FlyoverMapControls
                currentLayer={this.state.currentMapLayer}
                disable3D={this.disable3D}
                enable3D={this.enable3D}
                initFlyoverOnLoad={this.props.initFlyoverOnLoad}
                map={this.mbMap}
                onFlyoverEnabled={this.props.onFlyoverViewEnabled}
                onFlyoverDisabled={this.props.onFlyoverViewDisabled}
                setLayer={this.setLayer}
                shouldInitializeFlyover={this.props.shouldInitializeFlyover}
                terrainActive={this.state.terrainActive}
                trail={this.props.selectedObject}
              />
            )}
          </FlyoverTransition>
          <FlyoverTransition showOn={!this.props.flyoverViewEnabled} unmountOnExit>
            {showControls && (
              <div className={classNames('map-print-control', mapBottomClass)}>
                <PrintButton
                  buildPrintMapUrlParams={this.buildPrintMapUrlParams}
                  currentPage={this.props.currentPage}
                  selectedObject={this.props.selectedObject}
                />
              </div>
            )}
          </FlyoverTransition>
          <FlyoverTransition showOn={!this.props.flyoverViewEnabled} unmountOnExit>
            {this.props.isNewMapsPage || (
              <ZoomControls
                variant={zoomControlsVariant}
                shouldConditionallyRender
                handleUndoClick={this.state.trailPlanner ? this.undoClick : undefined}
                handleRedoClick={this.state.trailPlanner ? this.redoClick : undefined}
                handleZoomInClick={this.zoomInClicked}
                handleZoomOutClick={this.zoomOutClicked}
                handleCurrLocClick={this.compassClick}
              />
            )}
          </FlyoverTransition>
          <FlyoverTransition showOn={!this.props.flyoverViewEnabled} unmountOnExit>
            {this.props.isNewMapsPage ||
              (!this.props.flyoverViewEnabled && (
                <MapSelection
                  enabledOverlays={this.state.enabledOverlays}
                  onActivityFilterChange={this.handleActivityFilterChange}
                  selectedFilterActivity={this.state.selectedFilterActivity}
                  adminCustomizationSettings={this.state.adminCustomizationSettings}
                  handleAdminCustomizationSettingsChange={this.handleAdminCustomizationSettingsChange}
                  currentMapLayer={this.state.currentMapLayer}
                  onCardClick={this.handleCardClick}
                  onHeatmapClick={this.handleHeatmapOverlayClick}
                  permissions={permissions}
                  terrainActive={this.state.terrainActive}
                  handleResetClick={this.disableAllOverlays}
                  adminLayout={hasPermission({ permission: 'trails:manage' })}
                  isAuthenticated={isAuthenticated}
                  isPro={isAdminOrPro}
                  currentLayerTerrainEnabled={this.state.currentLayerTerrainEnabled}
                  handleDisable3D={this.disable3D}
                  handleEnable3D={this.enable3D}
                  compassRotation={this.state.compassRotation}
                  handleCompassClick={this.handleCompassClick}
                  trailId={this.props.selectedObject?.type === 'trail' ? this.props.selectedObject.ID : undefined}
                  isNotExploreMap={this.props.page !== PageStrings.EXPLORE_ALL_PAGE}
                  shouldConditionallyRender
                />
              ))}
          </FlyoverTransition>
          <FlyoverTransition showOn={!this.props.flyoverViewEnabled} unmountOnExit>
            {elevationPane}
          </FlyoverTransition>
          {this.state.lightboxOpen && (
            <Lightbox
              images={urlReformatPhotos(this.state.lightboxPhotos)}
              handleCloseRequest={this.handleLightboxClose}
              startingIndex={this.state.lightboxStartingIndex}
              context={this.props.context}
              analyticsReportLocation={ReportLocation.MapDetails}
            />
          )}
          {/* This is the magic that allows the hover cards to share global app state.
          The content is rendered in the card DOM, but derived from here in the React app tree */}
          {this.state.hoverCardPortal}
        </MapProvider>
      </div>
    );
  }
});

const FullscreenSearchMapIntlProvider = forwardRef((props, ref) => {
  const intl = useIntl();
  return <BaseFullscreenSearchMap {...props} intl={intl} ref={ref} />;
});

const FullscreenSearchMap = forwardRef((props, ref) => (
  <CustomProvider>
    <FullscreenSearchMapIntlProvider {...props} ref={ref} />
  </CustomProvider>
));

// eslint-disable-next-line import/prefer-default-export
export { FullscreenSearchMap };
