import { useFlyoverSettings } from 'components/MapProvider';
import useUser from 'hooks/useUser';
import { useEffect } from 'react';
import useToggle from '@alltrails/shared/hooks/useToggle';
import useExposureEvent from 'hooks/useExposureEvent';
import useExperiments from 'hooks/useExperiments';
import FlyoverUpsell from './FlyoverUpsell/FlyoverUpsell';
import FlyoverPreviewUpsellModal from './FlyoverUpsell/FlyoverPreviewUpsellModal';

type Props = {
  disable3D: () => void;
  initFlyoverOnLoad?: boolean;
  exactExplorePath: string;
  onFlyoverDisabled: () => void;
  setLayer: (layer: string) => void;
};

export default function FlyoverUpsellWrapper({ disable3D, exactExplorePath, initFlyoverOnLoad, onFlyoverDisabled, setLayer }: Props) {
  const { flyoverLoadingState, togglePauseFlyover } = useFlyoverSettings();
  const user = useUser();
  const [showUpsell, toggleUpsell] = useToggle();
  const sendExposure = useExposureEvent('web-growth-rm-new-upsell-preview');
  const experiments = useExperiments();

  useEffect(() => {
    if (flyoverLoadingState === 'done' && !user?.pro) {
      setTimeout(
        () => {
          toggleUpsell();
        },
        window.localStorage.getItem('flyoverUpsell') ? 800 : 3000
      );

      window.localStorage.setItem('flyoverUpsell', 'true');
    }
  }, [flyoverLoadingState, togglePauseFlyover, toggleUpsell, user?.pro]);

  useEffect(() => {
    if (showUpsell) {
      sendExposure();
    }
  }, [sendExposure, showUpsell]);

  if (showUpsell) {
    return experiments['web-growth-rm-new-upsell-preview']?.value === 'treatment' ? (
      <FlyoverPreviewUpsellModal
        disable3D={disable3D}
        exactExplorePath={exactExplorePath}
        initFlyoverOnLoad={initFlyoverOnLoad}
        onFlyoverDisabled={onFlyoverDisabled}
        setLayer={setLayer}
      />
    ) : (
      <FlyoverUpsell
        disable3D={disable3D}
        exactExplorePath={exactExplorePath}
        initFlyoverOnLoad={initFlyoverOnLoad}
        onFlyoverDisabled={onFlyoverDisabled}
        setLayer={setLayer}
        onSuccess={() => {
          toggleUpsell();
          togglePauseFlyover();
        }}
      />
    );
  }

  return null;
}
