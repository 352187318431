import SearchOrigin from '@alltrails/analytics/enums/SearchOrigin';
import { AlgoliaSearchBox } from '@alltrails/modules/AlgoliaSearch';
import '@alltrails/modules/AlgoliaSearch/index.css';
import useExperiment from 'hooks/useExperiment';
import useFeatures from 'hooks/useFeatures';
import { LanguageSupportUtil } from '../../../../../utils/language_support_util';

const FullscreenBreadcrumbBar = props => {
  const globalNavEnabled = useFeatures().includes('global_nav');
  const useNewSearch = useExperiment('web-disco-rm-global-nav')?.value === 'treatment_b';
  const bullet = <span>&nbsp; &#8226; &nbsp;</span>;
  const breadcrumbItems = props.items.map((item, i) => {
    if (item.link && item.link != null) {
      const link = LanguageSupportUtil.wrapUrlSafe(item.link, props.context.languageRegionCode);
      return (
        <li className={item.class} key={`breadcrumb-${item.label}`}>
          {i > 0 && bullet}
          <a href={link}>{item.label}</a>
        </li>
      );
    }
    return (
      <li className={item.class} key={`breadcrumb-${item.label}`}>
        {i > 0 && bullet}
        {item.label}
      </li>
    );
  });

  return (
    <div>
      <div id="breadcrumb-bar">
        {!globalNavEnabled && (
          <AlgoliaSearchBox
            className="algolia-search-box"
            configs={global.__AT_DATA__.algoliaConfig}
            isMobileBrowser={props.context.mobileBrowser}
            languageRegionCode={props.context.languageRegionCode}
            navigateOnSelect
            numResults={props.isMobileWidth ? 8 : 10}
            placeholder={props.intl.formatMessage({ defaultMessage: 'Enter a city, park or trail name' })}
            searchOrigin={SearchOrigin.ExploreMapView}
            searchTypes={props.typeSubsets}
            size="sm"
            testId="breadcrumbs-search-box"
            useNewSearch={useNewSearch}
            user={props.context.currentUser}
          />
        )}
        <ul id="breadcrumb-list">{breadcrumbItems}</ul>
      </div>
    </div>
  );
};

// eslint-disable-next-line import/prefer-default-export
export { FullscreenBreadcrumbBar };
