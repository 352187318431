// these CSS requires need to come first so component css can override shared css without needing !important
require('@alltrails/maps/sharedCss.css');
require('@alltrails/modules/PhotoUpload/sharedCss.css');
require('@alltrails/modules/ActivityUpload/sharedCss.css');
require('@alltrails/shared/sharedCss.css');
/* eslint-disable import/first */
import ContributeTrailApp from 'react_components/contribute/ContributeTrailApp';
import { Converter } from 'react_components/converter/converter';
import { ConverterSuccess } from 'react_components/converter/converter_success';
import { lazy } from 'react';
import ProfileEditForm from 'components/forms/ProfileEditForm';
import AccountConnectButtons from 'components/AccountConnectButtons';
import AdaChatbot from '@alltrails/shared/components/AdaChatbot';
import AutoRenewal from 'components/AutoRenewal';
import AccountLinks from 'components/profile/AccountLinks';
import AvatarStackContainer from 'components/profile/AvatarStackContainer';
import FacebookSettings from 'components/FacebookSettings';
import FiveHundred from 'pages/errors/FiveHundred';
import FooterWrapper from 'components/FooterWrapper';
import FourOFour from 'pages/errors/FourOFour';
import GeneralPrivacyPolicy from 'components/privacyPolicy/GeneralPrivacyPolicy';
import GlobalActions from 'components/GlobalActions';
import NavBar from 'components/NavBar';
import NotificationsSettings from 'components/NotificationsSettings';
import ResetPassword from 'pages/ResetPassword';
import PrintPage from 'components/PrintPage';
import ProfileSidebar from 'components/profile/ProfileSidebar';
import ProfileSlidingTabs from 'components/profile/ProfileSlidingTabs';
import ProStatus from 'components/ProStatus';
import QuoteRotator from 'components/QuoteRotator';
import ReferralRouter from 'components/ReferralRouter';
import Refer from 'components/Refer';
import SearchApp from 'components/SearchApp';
import SidebarTrailResultListWrapper from 'components/SidebarTrailResultListWrapper';
import UserAvatar from 'components/profile/UserAvatar';
import withSuspense from '../hocs/withSuspense';

const Terms = lazy(() => import('pages/Terms/TermsCSR'));
// eslint-disable-next-line @typescript-eslint/no-var-requires
const ReactRailsUJS = require('react_ujs');

const context = {
  AccountConnectButtons,
  AccountLinks,
  AdaChatbot,
  AvatarStackContainer,
  AutoRenewal,
  ContributeTrailApp,
  Converter,
  ConverterSuccess,
  FacebookSettings,
  FiveHundred,
  FooterWrapper,
  FourOFour,
  GeneralPrivacyPolicy,
  GlobalActions,
  NavBar,
  NotificationsSettings,
  ResetPassword,
  PrintPage,
  ProfileEditForm,
  ProfileSidebar,
  ProfileSlidingTabs,
  QuoteRotator,
  ReferralRouter,
  Refer,
  ProStatus,
  SearchApp,
  SidebarTrailResultListWrapper,
  Terms: withSuspense(Terms, 'Terms'),
  UserAvatar
};

ReactRailsUJS.getConstructor = className => context[className];
