import mapboxgl from 'mapbox-gl';
import postal from 'postal';
import turfDistance from '@turf/distance';
import Tooltip from '@alltrails/maps/components/Tooltip';
import { additionalStyleCardConfigs, baseStyleCardConfigs } from '@alltrails/maps/utils/cardConfigs';
import { lineOrSegSort } from '@alltrails/maps/utils/legacyGeoJSONConversions';
import { PageStrings } from '@alltrails/shared/utils/constants/pageStringHelpers';
import useLanguageCode from '@alltrails/shared/hooks/useLanguageCode';
import { getMapboxInstance, loadMapExtras } from 'utils/mapbox/map';
import ActivityHoverCard from 'components/cards/ActivityHoverCard';
import { getPermissionsFromContext, ableToProceed, combineACLs } from '@alltrails/maps/utils/aclHelper';
import { SearchResultsUtil } from 'utils/search_results_util';
import CustomProvider from 'components/CustomProvider';
import baseStyleLoaders from 'utils/mapbox/baseStyleLoaders';
import CardLocation from '@alltrails/analytics/enums/CardLocation';
import logTrailCardClicked from '@alltrails/analytics/events/logTrailCardClicked';
import hasPermission from 'utils/hasPermission';
import { modalRoadblock } from '@alltrails/shared/utils/modalFunnelUtils';
import TrailHoverCard from '../../../../../components/cards/TrailHoverCard';
import { lngLatBoundsToAtBounds } from '../../../../../utils/at_map_helpers';
import { swapBaseStyle, setTerrain } from '../../../../../utils/mapbox/style_helpers';
import { additionalStyleLoaders } from '../../../../../utils/mapbox/additionalStyleLoaders';
import { removePreviewLines } from '../../../../../utils/mapbox/overlays/preview_lines';
import { addWaypointToTrail, saveWaypointChanges, updateAtMapMetadata, removeAtMapFromTrail } from '../../../../../utils/requests/at_map_requests';
import WaypointPopup from '../../../../../components/shared/MapPopups/WaypointPopup';
import MapPopup from '../../../../../components/shared/MapPopups/MapPopup';
import { updateCurrLocMarker } from '../../../../../utils/mapbox/overlays/current_location';
import { mergeTrails } from '../../../../../utils/requests/trail_requests';

const terrainEnabledLayers = ['alltrailsOutdoorsV2', 'alltrailsSatellite'];
const terrainEnabledOverlays = ['distanceMarkers', 'nearbyTrails'];
const defaultTerrainPitch = 65;

const MapMixin = {
  componentDidMount() {
    if (!this.listeners) {
      this.listeners = [];
    }
    this.listeners.push(this.props.messagingChannel.subscribe('edit.change_bounds', this.handleBoundsChange));
    this.listeners.push(this.props.messagingChannel.subscribe('edit.change_lat_lng', this.handleLatLngChange));
  },
  componentWillUnmount() {
    this.listeners.forEach(sub => {
      postal.unsubscribe(sub);
    });
  },
  // eslint-disable-next-line default-param-last
  initLegacyMap(isPrint = false, locationData, boundingLocationBox) {
    if (!mapboxgl.supported()) {
      alert(
        this.props.intl.formatMessage({
          defaultMessage:
            'It appears that your browser does not support MapboxGL, a library that is required to render maps on AllTrails.com. Please upgrade or change browsers before reloading the page. See https://docs.mapbox.com/help/troubleshooting/mapbox-browser-support/ for more details. Contact customer support with details about your device and browser if issue persists.'
        })
      );
      return;
    }

    const map = getMapboxInstance({
      initialBounds: boundingLocationBox != null ? boundingLocationBox : this.props.initialBounds,
      // eslint-disable-next-line eqeqeq
      initialCenter: locationData != undefined ? [locationData[0], locationData[1], this.props.initialCenter[2]] : this.props.initialCenter,
      isPrint,
      mapboxAccessToken: this.props.mapboxAccessToken,
      mapDivId: this.props.mapDivId,
      mapZoom: this.props.mapZoom,
      setPrintFlags: this.props.setPrintFlags
    });

    // Wait until the map 'load' event then load all custom styles and behaviors.
    loadMapExtras({
      context: this.props.context,
      currentMapLayer: this.state.currentMapLayer,
      // eslint-disable-next-line react-hooks/rules-of-hooks
      locale: useLanguageCode(),
      map,
      intl: this.props.intl
    }).then(() => {
      // Map is fully loaded.
      this.addShiftDragTracking();
      this.setState({ isMapReady: true });
      this.props.onMapReady?.();
    });

    // Make mbMap available to other mixins/class components that want to access this immediately.
    this.mbMap = map;

    this.enableMapEventHandlers(isPrint);
  },
  isMapFirstRender({ isMapReady }) {
    return this.state.isMapReady !== isMapReady;
  },
  enableMapEventHandlers(isPrint) {
    const map = this.mbMap;

    map.on('click', e => {
      map.dragLayerEnd = undefined; // Reset drag layer status
      // Prevent click from being fired on "mouseup" at end of shift drag event
      if (map.shiftDragging) {
        return;
      }
      // defocus any text fields when the map is clicked/touched
      // eslint-disable-next-line no-undef
      $('input').blur();
      this.props.messagingChannel.publish('map.click', e);
    });

    map.on('contextmenu', e => {
      this.props.messagingChannel.publish('map.map_right_click', e);
    });

    map.on('dblclick', e => {
      // When a layer is dragged quickly, it may register a double click
      // Prevent double click from map zooming.
      if (map.dragLayerEnd) {
        map.dragLayerEnd = false;
        e.preventDefault();
      }
    });

    if (isPrint) {
      // Users should not be allowed to rotate maps
      // When they are trying to print out the map
      map.dragRotate.disable();
      map.touchZoomRotate.disableRotation();
    } else {
      map.on('rotate', () => {
        const compassRotation = this.mbMap.getBearing() * -1;
        this.setState({ compassRotation });
      });
    }

    if (!this.keysDown) {
      this.keysDown = {}; // hash of key codes that are pressed-down, used to prevent firing keydown events multiple times on PCs
    }

    document.addEventListener('keyup', this.keyUpDown);
    document.addEventListener('keydown', this.keyUpDown);
  },
  addShiftDragTracking(shiftDragThresh = 5) {
    const map = this.mbMap;
    // threshold in meters
    // Do nothing if mapbox box zoom is enabled
    if (map.boxZoom.isEnabled()) {
      return;
    }
    // Implement shift-drag event tracking
    map.shiftDragging = false;
    map.shiftDraggingLastLngLat = null;

    // Functionality should continue in shift-drag mode if shift key is released,
    // but exit mode ASAP on mouseup
    map.on('mousedown', e => {
      map.shiftDragging = map.shiftKeyDown;
      if (!map.shiftDragging) {
        return;
      }
      e.preventDefault(); // prevent map pane dragging
      map.shiftDraggingLastLngLat = e.lngLat;
      this.props.messagingChannel.publish('map.shift_drag.start', e);
    });
    map.on('mouseup', e => {
      if (!map.shiftDragging) {
        return;
      }
      this.props.messagingChannel.publish('map.shift_drag.end', e);
      map.shiftDragging = false;
    });
    map.on('mousemove', e => {
      if (!map.shiftDragging) {
        return;
      }
      const { lngLat } = e;
      if (map.shiftDraggingLastLngLat) {
        const d = turfDistance(map.shiftDraggingLastLngLat.toArray(), lngLat.toArray()) * 1000;
        if (d < shiftDragThresh) {
          return;
        }
      }
      map.shiftDraggingLastLngLat = lngLat;
      this.props.messagingChannel.publish('map.shift_drag.move', e);
    });
  },
  keyUpDown(e) {
    // e.keyCode: Backspace=8, Shift=16, y|Y=89, z|Z=90

    const keyDown = e.type === 'keydown';
    const cmdKey = e.metaKey || e.ctrlKey;
    const { shiftKey } = e;

    // Update shiftKeyDown modifier status
    this.mbMap.shiftKeyDown = shiftKey;

    // Ignore multiple firings of keydown events on modifier keys (happens when holding key down on PC)
    // by keeping track of what keys are down using keysDown hash.
    // Note: Normal letter keys SHOULD fire keydown events when held down, regardless of platform.
    // Only track for shift, ctrl, and opt/alt keys
    if (e.keyCode >= 16 && e.keyCode <= 18) {
      if (keyDown) {
        if (this.keysDown[e.keyCode]) {
          return; // ignore keydown event
        }
        this.keysDown[e.keyCode] = true;
      } else if (this.keysDown[e.keyCode]) {
        delete this.keysDown[e.keyCode];
      }
    }

    if (this.state.activeKeyModifier !== undefined) {
      if (keyDown) {
        if (e.keyCode < 91 && e.keyCode > 64 && this.state.activeKeyModifier !== e.key) {
          this.setState({ activeKeyModifier: e.key });
        }
      } else {
        this.setState({ activeKeyModifier: '' });
      }
    }

    // Check if user is currently focused on an input element.
    // If so, perform only default behavior on some of the following
    const inputFocused = ['TEXTAREA', 'INPUT'].includes(document.activeElement?.nodeName);

    // eslint-disable-next-line eqeqeq
    if (keyDown && cmdKey && e.keyCode == 90 && !shiftKey && !inputFocused) {
      // cmd-z
      this.props.messagingChannel.publish('button.click.undo');
      // eslint-disable-next-line eqeqeq
    } else if (keyDown && cmdKey && (e.keyCode == 89 || (e.keyCode == 90 && shiftKey)) && !inputFocused) {
      // cmd-y or cmd-shift-z
      this.props.messagingChannel.publish('button.click.redo');
      // eslint-disable-next-line eqeqeq
    } else if (e.keyCode == 16) {
      // shift
      this.props.messagingChannel.publish('map.shift_key', keyDown);
      // eslint-disable-next-line eqeqeq
    } else if (e.keyCode == 18) {
      // alt/opt
      this.props.messagingChannel.publish('map.opt_key', keyDown);
      // eslint-disable-next-line eqeqeq
    } else if (e.keyCode == 17) {
      // ctrl
      this.props.messagingChannel.publish('map.ctrl_key', keyDown);
      return; // don't prevent default
      // eslint-disable-next-line eqeqeq
    } else if ((e.keyCode == 8 || e.keyCode == 46 || e.keyCode == 51) && !inputFocused) {
      // backspace or delete keys
      this.props.messagingChannel.publish('map.del_key', keyDown);
      return; // don't prevent default
      // eslint-disable-next-line eqeqeq
    } else if (e.keyCode == 27) {
      this.props.messagingChannel.publish('map.esc_key', keyDown);
      return; // don't prevent default
      // eslint-disable-next-line eqeqeq
    } else if (e.keyCode == 13 && !inputFocused) {
      // enter or return keys
      this.props.messagingChannel.publish('map.enter_return_key', keyDown);
      return; // don't prevent default
    } else {
      return; // don't prevent default
    }

    // Prevent default key functionality
    e.preventDefault();
  },
  handleCompassClick() {
    const pitch = this.state.terrainActive ? defaultTerrainPitch : 0;
    this.mbMap.easeTo({ bearing: 0, pitch });
  },
  compassClick() {
    if (this.mbMap.getBearing() !== 0 || this.mbMap.getPitch() !== 0) {
      this.reorientUser();
    } else {
      this.locateUser();
    }
  },
  reorientUser() {
    this.mbMap.easeTo({ bearing: 0, pitch: 0 });
  },
  locateUser() {
    this.mbMap.geolocateControl.trigger();
  },
  getMapCenter() {
    return this.mbMap.getCenter() ? this.mbMap.getCenter().toArray().reverse() : null;
  },
  getMapBounds() {
    // Return bounds in format [N, S, W, E]
    const bounds = this.mbMap.getBounds();
    return bounds ? [bounds.getNorth(), bounds.getSouth(), bounds.getWest(), bounds.getEast()] : null;
  },
  getMapAtBounds() {
    const bounds = this.mbMap.getBounds();
    return lngLatBoundsToAtBounds(bounds);
  },
  getMapZoom() {
    return this.mbMap.getZoom();
  },
  getMaxZoom(locationType) {
    switch (locationType) {
      case 'area':
      case 'cityo':
        return 12;
      case 'state':
        return 11;
      case 'country':
        return 10; // Ideal size for small countries like Malta and Lichtenstein
      default:
        return 12; // Using original default zoom
    }
  },
  enable3D(disablePitchAdjustment = false) {
    this.disableNonTerrainEnabledOverlays();

    this.setState({ terrainActive: true });

    setTerrain(this.mbMap, true);

    // set fog
    this.mbMap.setFog({
      range: [2, 12],
      color: 'white',
      'horizon-blend': 0.1
    });

    this.mbMap.setMaxPitch(85);
    // eslint-disable-next-line no-unused-expressions
    !disablePitchAdjustment && this.mbMap.easeTo({ pitch: defaultTerrainPitch });
  },
  disable3D(resetNorthPitch = true) {
    this.setState({
      terrainActive: false,
      compassRotation: resetNorthPitch ? 0 : this.state.compassRotation
    });

    if (resetNorthPitch) {
      this.mbMap.resetNorthPitch();
    }
    setTerrain(this.mbMap, false);
  },
  zoomInClicked() {
    this.mbMap.zoomIn();
  },
  zoomOutClicked() {
    this.mbMap.zoomOut();
  },
  setMapZoom(zoom) {
    this.mbMap.setZoom(zoom);
  },
  setMapBounds(bounds) {
    // [w, s, e, n]
    this.mbMap.fitBounds([bounds.longitudeTopLeft, bounds.latitudeBottomRight, bounds.longitudeBottomRight, bounds.latitudeTopLeft]);
  },
  setMapCenterAndZoom(latitude, longitude, zoom) {
    this.mbMap.jumpTo({ center: [longitude, latitude], zoom });
  },
  handleBoundsChange(data) {
    this.setMapBounds(data.bounds, data.options);
  },
  handleLatLngChange(coordinates) {
    // eslint-disable-next-line eqeqeq
    const zoom = coordinates.length == 3 ? coordinates[2] : 15;
    this.setMapCenterAndZoom(coordinates[0], coordinates[1], zoom, { suppressBoundsCallback: true });
  },
  handleTerrainMapTipCloseClicked() {
    this.setState({ terrainMapTipClosed: true });
  },
  toggleElevationCollapsed() {
    this.setState({ elevationCollapsed: !this.state.elevationCollapsed });
  },
  handleElevationPaneMouseOver(lngLat) {
    updateCurrLocMarker(this.mbMap, lngLat);
  },
  handleCardClick(card) {
    const { context, user } = this.props;

    if (card.layerType === 'basemap') {
      if (!user && !context.currentUser) {
        modalRoadblock('signup', card.key, window.location.pathname + window.location.search, this.props.context.languageRegionCode);
        return;
      }

      this.setLayer(card.key);
    } else {
      this.toggleOverlay(card.key);
    }
  },
  handleHeatmapOverlayClick(overlay) {
    this.toggleOverlay(overlay);
  },
  setLayer(layer) {
    const { context } = this.props;
    // Do nothing if layer has already been selected
    if (layer === this.state.currentMapLayer) {
      return;
    }
    const styleConfig = baseStyleCardConfigs[layer];
    const mapLayerRef = `map-layer-${layer}`;
    const returnToUrl = window.location.pathname + window.location.search;
    removePreviewLines(this.mbMap);

    // Do nothing if doesn't have proper permissions to view/enable
    const combinedACL = combineACLs([styleConfig.aclEnabled, styleConfig.aclVisible]);
    if (
      !ableToProceed({
        acl: combinedACL,
        permissions: this.getPermissions(),
        ref: mapLayerRef,
        returnToUrl,
        languageRegionCode: this.props.context.languageRegionCode
      })
    ) {
      return;
    }

    const styleLoader = baseStyleLoaders[layer];
    // Swap base style of map
    styleLoader(context.locale, context.displayMetric, this.props.intl, this.state.adminCustomizationSettings)
      .then(style => {
        swapBaseStyle(this.mbMap, style, this.state.terrainActive);
      })
      .catch(() => {
        console.error(`Layer ${layer} is not available`);
      });

    const currentLayerTerrainEnabled = terrainEnabledLayers.includes(layer);

    if (this.state.terrainActive && !currentLayerTerrainEnabled) {
      this.disable3D();
    }

    this.setState({
      currentMapLayer: layer,
      currentLayerTerrainEnabled
    });
  },
  getOverlaysChangedCb() {
    return this.printMap ? this.printMap.updatePermalink : null;
  },
  toggleOverlay(overlay) {
    const additionalStyleConfig = additionalStyleCardConfigs[overlay];
    const styleLoader = additionalStyleLoaders[overlay];
    // Do nothing if doesn't have proper permissions to view/enable
    const combinedACL = combineACLs([additionalStyleConfig.aclEnabled, additionalStyleConfig.aclVisible]);
    const mapOverlayRef = `map-overlay-${overlay}`;
    const returnToUrl = window.location.pathname + window.location.search;
    if (
      !ableToProceed({
        acl: combinedACL,
        permissions: this.getPermissions(),
        ref: mapOverlayRef,
        returnToUrl,
        languageRegionCode: this.props.context.languageRegionCode
      })
    ) {
      return;
    }
    // Toggle the display of an overlay
    let { enabledOverlays } = this.state;
    const configParams = this.getOverlayConfigParams();
    const isEnabled = enabledOverlays.includes(overlay);
    if (isEnabled) {
      // disable
      enabledOverlays = enabledOverlays.filter(o => o !== overlay);
      styleLoader.remove(this.mbMap, { ...configParams, enabledOverlays });
      // disable corresponding heatmap overlay too
      if (additionalStyleConfig.hasHeatmap) {
        enabledOverlays = enabledOverlays.filter(o => o !== `${overlay}Heatmap`);
        additionalStyleLoaders[`${overlay}Heatmap`].remove(this.mbMap, { ...configParams, enabledOverlays });
      }
    } else {
      // enable
      enabledOverlays = [...enabledOverlays, overlay];
      styleLoader.add(this.mbMap, { ...configParams, enabledOverlays });
    }
    this.setState({ enabledOverlays }, this.getOverlaysChangedCb());
    // Notify parent of overlay change (currently only used for switching on/off the nearby trails toggle)
    if (this.props.handleOverlayToggled) {
      this.props.handleOverlayToggled(overlay);
    }
  },
  getOverlayConfigParams() {
    const { exploreMap, profilePhoto, plannerMap, results } = this.props;
    const { enabledOverlays, selectedFilterActivity, adminCustomizationSettings } = this.state;
    return {
      enabledOverlays,
      exploreMap,
      profilePhoto,
      handlePhotoClick: this.handlePhotoClick,
      plannerMap,
      renderHoverCard: this.renderHoverCard,
      renderWaypointPopup: this.renderWaypointPopup,
      results,
      trailId: exploreMap ? exploreMap.trailId : null,
      selectedFilterActivity,
      adminCustomizationSettings,
      adminUser: hasPermission({ permission: 'trails:manage' })
    };
  },
  getPermissions() {
    const permissions = getPermissionsFromContext(this.props.context);
    if (this.props.exploreMap || this.props.plannerMap) {
      permissions.push('exploreMap');
      if (this.props.exploreMap && this.props.exploreMap.trailId) {
        permissions.push('attachedToTrail');
      }
    }
    if (this.props.currentPage === PageStrings.CONTRIBUTE_TRAIL) {
      permissions.push('contributeTrail');
    }
    if (this.props.currentPage !== PageStrings.EXPLORE_ALL_PAGE) {
      permissions.push('nearbyTrails');
    }
    this.state.enabledOverlays.forEach(overlay => {
      permissions.push(`${overlay}Enabled`);
    });
    return permissions;
  },
  disableNonTerrainEnabledOverlays() {
    const enabledOverlays = [];
    this.state.enabledOverlays.forEach(overlay => {
      if (terrainEnabledOverlays.includes(overlay)) {
        enabledOverlays.push(overlay);
      } else {
        const styleLoader = additionalStyleLoaders[overlay];
        styleLoader.remove(this.mbMap);
      }
    });
    this.setState({ enabledOverlays }, this.getOverlaysChangedCb());
  },
  disableAllOverlays() {
    this.state.enabledOverlays.forEach(overlay => {
      const styleLoader = additionalStyleLoaders[overlay];
      styleLoader.remove(this.mbMap);
    });
    this.setLayer('alltrailsOutdoorsV2');
    this.setState({ enabledOverlays: [] }, this.getOverlaysChangedCb());
  },
  renderHoverCard(result) {
    if (result.type === 'trail') {
      return this.renderTrailHoverCard(result);
    }
    if (result.type === 'track' || result.type === 'map') {
      return this.renderTrackHoverCard(result);
    }
    return null;
  },
  renderTrailHoverCard(result) {
    const { listMethods, resultCardFunctions, belongsToCurrentUser } = this.props;
    // TODO: https://alltrails.atlassian.net/browse/XPLOR-2378
    const detailedCardLocation = this.props.isNewMapsPage ? CardLocation.ExploreTabMapView : undefined;

    return (
      <TrailHoverCard
        allowClickingCompletedBadge={
          resultCardFunctions && resultCardFunctions.allowClickingCompletedBadge
            ? resultCardFunctions.allowClickingCompletedBadge(result.type, result.ID)
            : undefined
        }
        bottomButtonsConfig={this.createAdminTrailLinks(result)}
        context={this.props.context}
        handleCardClick={() => {
          if (detailedCardLocation) {
            logTrailCardClicked({ detailed_card_location: detailedCardLocation, trail_id: result.ID });
          }
          resultCardFunctions?.handleTrailClick?.(result);
        }}
        handleCompletedBadgeClick={() => resultCardFunctions?.handleCompletedBadgeClick?.(result.type, result.ID)}
        handleFavoriteClick={() =>
          listMethods?.handleFavoriteClick?.({
            type: result.type,
            id: result.ID,
            objectID: result.objectID,
            detailedCardLocation,
            listId: SearchResultsUtil.getListData(this.props.currentPage, this.props.customList, this.props.intl)?.id,
            listTitle: SearchResultsUtil.getListData(this.props.currentPage, this.props.customList, this.props.intl)?.title,
            belongsToCurrentUser
          })
        }
        isCompleted={listMethods && listMethods.isComplete ? listMethods.isComplete(result.type, result.ID) : undefined}
        isFavorite={listMethods && listMethods.isFavorite ? listMethods.isFavorite(result.type, result.ID) : undefined}
        isMobileWidth={this.props.isMobileWidth}
        isVerified={listMethods && listMethods.isVerified ? listMethods.isVerified(result.type, result.ID) : undefined}
        key={`trail-${result.ID}`}
        trail={result}
        trailUrl={resultCardFunctions && resultCardFunctions.formatTrailUrl ? resultCardFunctions.formatTrailUrl(result) : undefined}
      />
    );
  },
  renderTrackHoverCard(result) {
    result = { ...result };
    // need to fully deserialize some props (which are partially serialized when converted to a mapbox feature property)
    if (typeof result.user === 'string') {
      result.user = JSON.parse(result.user);
    }
    if (typeof result.activity === 'string') {
      result.activity = JSON.parse(result.activity);
    }
    if (typeof result.activities === 'string') {
      result.activities = JSON.parse(result.activities);
    }
    result.timezone = result.timezone === 'null' ? null : result.timezone;

    const { resultCardFunctions, listMethods, belongsToCurrentUser } = this.props;
    let handleCardClick;
    if (resultCardFunctions) {
      if (result.type === 'track' && resultCardFunctions.handleTrackClick) {
        handleCardClick = () => resultCardFunctions.handleTrackClick(result);
      } else if (resultCardFunctions.handleMapClick) {
        handleCardClick = () => resultCardFunctions.handleMapClick(result);
      }
    }

    return (
      <ActivityHoverCard
        bottomButtonsConfig={this.createAdminTrackLinks(result)}
        context={this.props.context}
        handleCardClick={handleCardClick}
        handleFavoriteClick={
          listMethods && listMethods.handleFavoriteClick
            ? () =>
                listMethods.handleFavoriteClick({
                  type: result.type,
                  id: result.ID,
                  contentPrivacy: result.contentPrivacy,
                  listId: SearchResultsUtil.getListData(this.props.currentPage, this.props.customList, this.props.intl)?.id,
                  listTitle: SearchResultsUtil.getListData(this.props.currentPage, this.props.customList, this.props.intl)?.title,
                  belongsToCurrentUser
                })
            : undefined
        }
        isFavorite={listMethods && listMethods.isFavorite ? listMethods.isFavorite(result.type, result.ID) : undefined}
        isMobileWidth={this.props.isMobileWidth}
        key={`track-${result.ID}`}
        track={result}
      />
    );
  },
  renderTerrainTip() {
    const { terrainActive, terrainMapTipClosed, currentlyEditingRoute } = this.state;

    if (terrainMapTipClosed || !terrainActive || currentlyEditingRoute) {
      // The Smart Routing map tip obstructs the 3D map tip, so just don't show it in this case
      return null;
    }

    return <Tooltip is3DActive languageRegionCode={this.props.context.languageRegionCode} />;
  },
  createAdminTrailLinks(trail) {
    const { selectedObject } = this.props;
    if (!hasPermission({ permission: 'trails:manage' })) {
      return null;
    }
    const linksConfig = [];
    if (selectedObject && selectedObject.type === 'trail' && trail.ID !== selectedObject.ID) {
      linksConfig.push({
        label: 'Merge',
        id: 'merge',
        labelInProgress: 'Merging...',
        labelSuccess: 'Merged',
        labelFailed: 'Failed',
        handleClick: () => this.trailMerge(selectedObject, trail)
      });
    }
    linksConfig.push({
      label: 'Edit',
      id: 'edit',
      handleClick: () => {
        window.open(`/contribute/route?trail_id=${trail.ID}`, '_blank');
      }
    });
    return linksConfig;
  },

  trailMerge(mergeIntoTrail, mergeTrail) {
    let handleMergeSuccess;
    if (!window.confirm(`Merge ${mergeTrail.name} into ${mergeIntoTrail.name}?`)) {
      // Check if the admin wants to merge the trails in the reverse direction
      [mergeTrail, mergeIntoTrail] = [mergeIntoTrail, mergeTrail];
      if (!window.confirm(`Merge ${mergeTrail.name} into ${mergeIntoTrail.name}?`)) {
        return Promise.reject();
      }
      handleMergeSuccess = () => {
        // redirect to merge-into-trail's contribute page
        window.location.href = `/contribute/route?trail_id=${mergeIntoTrail.ID}`;
      };
    }
    const mergeTrailsPromise = mergeTrails(mergeIntoTrail, mergeTrail);
    if (handleMergeSuccess) {
      mergeTrailsPromise.then(handleMergeSuccess);
    }
    return mergeTrailsPromise;
  },

  createAdminTrackLinks(track) {
    const { exploreMap } = this.props;
    if (!hasPermission({ permission: 'trails:manage' })) {
      return null;
    }

    const refreshOverlay = (overlay, resultsDataOverrides = {}) => {
      if (!this.state.enabledOverlays.includes(overlay)) {
        return;
      }
      const overlayConfigParams = { ...this.getOverlayConfigParams(), resultsDataOverrides };
      additionalStyleLoaders[overlay].add(this.mbMap, overlayConfigParams);
    };

    const linksConfig = [];
    if (exploreMap && exploreMap.trailId) {
      const trackTrailId = track.trailId || track.trail_id;
      if (trackTrailId === exploreMap.trailId) {
        linksConfig.push({
          label: 'Remove',
          id: 'remove',
          labelInProgress: 'Removing...',
          labelSuccess: 'Removed',
          labelFailed: 'Failed',
          handleClick: () => {
            // Return promise before chaining .then
            const promise = removeAtMapFromTrail(track.ID, exploreMap.trailId);
            promise.then(() => refreshOverlay('heatmap'));
            return promise;
          }
        });
      } else if (!track.private) {
        linksConfig.push({
          label: 'Attach to Trail',
          id: 'attach-to-trail',
          labelInProgress: 'Attaching...',
          labelSuccess: 'Attached',
          labelFailed: 'Failed',
          handleClick: () => {
            // Return promise before chaining .then
            const promise = updateAtMapMetadata(track.ID, { trail_id: exploreMap.trailId });
            // Update refreshed data with this change, since Algolia might not yet reflect it
            const resultsDataOverrides = {
              [track.ID]: { trail_id: exploreMap.trailId }
            };
            promise.then(() => {
              ['heatmap', 'recordingsAll', 'recordingsPop'].forEach(overlay => refreshOverlay(overlay, resultsDataOverrides));
            });
            return promise;
          }
        });
      }
    }
    if (!track.hidden) {
      linksConfig.push({
        label: 'Hide',
        id: 'hide',
        labelInProgress: 'Hiding...',
        labelSuccess: 'Hidden',
        labelFailed: 'Failed',
        handleClick: () => updateAtMapMetadata(track.ID, { hidden: true })
      });
    }
    if (!track.private) {
      linksConfig.push({
        label: 'Add as new trail',
        id: 'add-as-new-trail',
        handleClick() {
          window.open(`/trail/new?recording=${track.ID}`, '_blank');
        }
      });
    }
    return linksConfig;
  },
  getRouteName(id) {
    const { routes } = this.props.exploreMap;

    if (!routes || routes.length <= 1) {
      return this.props.intl.formatMessage({
        defaultMessage: 'Route'
      });
    }

    const orderNum = routes.sort(lineOrSegSort).findIndex(route => route.id === id);

    return this.props.intl.formatMessage(
      {
        defaultMessage: 'Route {index}'
      },
      { index: orderNum + 1 }
    );
  },
  renderRoutePopup(data) {
    const name = this.getRouteName(data.lineId);
    return <MapPopup name={name} />;
  },
  existingVRWaypoint(waypoint) {
    return this.props.exploreMap.waypoints.find(
      wp => waypoint.location.latitude === wp.location.latitude && waypoint.location.longitude === wp.location.longitude
    );
  },
  renderWaypointPopup(waypoint, isCommunityContent) {
    waypoint = { ...waypoint };
    let toggleShowTitle;
    let addToVRoute;
    let messagingChannel;
    // need to fully deserialize waypoint (which is partially serialized when converted to a mapbox property)
    if (typeof waypoint.location === 'string') {
      waypoint.location = JSON.parse(waypoint.location);
    }
    if (typeof waypoint.waypointDisplayProperty === 'string') {
      waypoint.waypointDisplayProperty = JSON.parse(waypoint.waypointDisplayProperty);
    }

    const {
      context: { currentUser },
      exploreMap,
      selectedObject
    } = this.props;

    if (this.props.messagingChannel && exploreMap.waypointEditIndex !== null && waypoint.id === exploreMap.editWaypointId) {
      messagingChannel = this.props.messagingChannel;
    }

    if (
      !isCommunityContent && // don't allow toggle of titles on community waypoints overlay
      currentUser &&
      exploreMap &&
      (hasPermission({ permission: 'trails:manage' }) || (exploreMap.user && exploreMap.user.id === currentUser.id)) &&
      selectedObject &&
      selectedObject.type !== 'trail' // don't allow waypoint titles to be shown/hidden on trail pages
    ) {
      toggleShowTitle = () => {
        const showTitle = waypoint.waypointDisplayProperty ? waypoint.waypointDisplayProperty.showTitle : false;
        saveWaypointChanges(exploreMap.id, waypoint.id, { showTitle: !showTitle }).then(updatedWaypoint => {
          this.props.messagingChannel.publish('waypoint.updated', updatedWaypoint);
        });
      };
    }

    // In an ideal world, we could simply compare the waypoint's at_map_id against exploreMap.id to see if the waypoint
    // belongs to the trail/map, but we can't do that for most of our older trails which use a separate verified
    // markers map to keep track of markers/waypoints. This isCommunityContent flag simplifies deciding whether or
    // not to show this button. Also should only show if looking at a trail's verified route.
    if (hasPermission({ permission: 'trails:manage' }) && selectedObject && selectedObject.type === 'trail') {
      if (isCommunityContent && !this.existingVRWaypoint(waypoint)) {
        addToVRoute = () =>
          addWaypointToTrail(exploreMap.trailId, waypoint)
            .then(newWaypoint => {
              if (this.props.handleWaypointAdded) {
                this.props.handleWaypointAdded(newWaypoint);
              }

              return 'Added';
            })
            .catch(() => 'Failed');
      } else {
        // TODO: Remove from VRoute - need new API endpoint to do this
      }
    }

    return (
      <CustomProvider>
        <WaypointPopup waypoint={waypoint} toggleShowTitle={toggleShowTitle} addToVRoute={addToVRoute} messagingChannel={messagingChannel} />
      </CustomProvider>
    );
  }
};

// eslint-disable-next-line import/prefer-default-export
export { MapMixin };
