import NewTrailCard from '@alltrails/shared/components/TrailCard';
import CustomProvider from 'components/CustomProvider';
import type { Context } from 'types/Context';
import { useIntl } from '@alltrails/shared/react-intl';
import type Trail from 'types/Trails/Trail';
import useAdminCTAs, { ButtonConfig } from 'hooks/useAdminCTAs';
import useLanguageRegionCode from '@alltrails/shared/hooks/useLanguageRegionCode';
import useActivityDuration from 'hooks/useActivityDuration';
import useFeatures from 'hooks/useFeatures';
import { getTrailForTrailCard } from 'utils/cardInfo';

type TrailHoverCardProps = {
  allowClickingCompletedBadge?: boolean;
  bottomButtonsConfig?: ButtonConfig[];
  context: Context;
  handleCardClick: () => void;
  handleCompletedBadgeClick?: () => void;
  handleFavoriteClick: () => void;
  handleMouseEnter?: () => void;
  handleMouseLeave?: () => void;
  isCompleted?: boolean;
  isFavorite?: boolean;
  isVerified?: boolean;
  trail: Trail;
  trailUrl?: string;
};

const BaseTrailHoverCard = ({
  allowClickingCompletedBadge,
  bottomButtonsConfig,
  context,
  handleCardClick,
  handleCompletedBadgeClick,
  handleFavoriteClick,
  handleMouseEnter,
  handleMouseLeave,
  isCompleted,
  isFavorite,
  isVerified,
  trail,
  trailUrl
}: TrailHoverCardProps): JSX.Element => {
  const intl = useIntl();
  const languageRegionCode = useLanguageRegionCode();
  const minutes = useActivityDuration(trail);
  const adminCTAs = useAdminCTAs(bottomButtonsConfig, intl);
  const includeStrenuous = useFeatures().includes('show_strenuous_difficulty_level');

  return (
    <NewTrailCard
      languageRegionCode={languageRegionCode}
      onCardClick={handleCardClick}
      onCardMouseEnter={handleMouseEnter}
      onCardMouseLeave={handleMouseLeave}
      onFavoriteClick={handleFavoriteClick}
      isFavorite={isFavorite}
      variant="small"
      trail={getTrailForTrailCard(trail, minutes)}
      useMetric={context?.displayMetric}
      adminCTAs={adminCTAs && adminCTAs.length ? adminCTAs : undefined}
      isCompleted={isCompleted}
      isVerified={isVerified}
      onCompletionBadgeClick={allowClickingCompletedBadge ? handleCompletedBadgeClick : undefined}
      url={trailUrl}
      blockNavigation={!!handleCardClick} // Navigation is handled by the click handler
      openInNewTab
      includeStrenuous={includeStrenuous}
    />
  );
};

export default function TrailHoverCard({
  allowClickingCompletedBadge,
  bottomButtonsConfig,
  context,
  handleCardClick,
  handleCompletedBadgeClick,
  handleFavoriteClick,
  handleMouseEnter,
  handleMouseLeave,
  isCompleted,
  isFavorite,
  isVerified,
  trail,
  trailUrl
}: TrailHoverCardProps) {
  return (
    <CustomProvider>
      <BaseTrailHoverCard
        allowClickingCompletedBadge={allowClickingCompletedBadge}
        bottomButtonsConfig={bottomButtonsConfig}
        context={context}
        handleCardClick={handleCardClick}
        handleCompletedBadgeClick={handleCompletedBadgeClick}
        handleFavoriteClick={handleFavoriteClick}
        handleMouseEnter={handleMouseEnter}
        handleMouseLeave={handleMouseLeave}
        isCompleted={isCompleted}
        isFavorite={isFavorite}
        isVerified={isVerified}
        trail={trail}
        trailUrl={trailUrl}
      />
    </CustomProvider>
  );
}
