import { FormattedMessage, FormattedNumber, useIntl } from '@alltrails/shared/react-intl';
import Typography from '@alltrails/shared/denali/components/Typography';
import Button from '@alltrails/shared/denali/components/Button';
import PlusBadge from '@alltrails/shared/denali/components/PlusBadge';
import useUser from 'hooks/useUser';
import SignupModal from 'components/SignupModal';
import CarouselDisplayTrigger from '@alltrails/analytics/enums/CarouselDisplayTrigger';
import { wrapUrlSafe } from '@alltrails/shared/utils/languageSupport';
import useLanguageRegionCode from '@alltrails/shared/hooks/useLanguageRegionCode';
import Close from '@alltrails/shared/icons/Close';
import IconButton from '@alltrails/shared/denali/components/IconButton';
import { useFlyoverSettings } from 'components/MapProvider';
import { useEffect, useState } from 'react';
import logWebProModalShown from '@alltrails/analytics/events/logWebProModalShown';
import PurchaseScreenTypePlan from '@alltrails/analytics/enums/PurchaseScreenTypePlan';
import Link from '@alltrails/shared/denali/components/Link';
import logWebProModalPurchaseButtonTapped from '@alltrails/analytics/events/logWebProModalPurchaseButtonTapped';
import WebProModalPurchaseButtonCopyText from '@alltrails/analytics/enums/WebProModalPurchaseButtonCopyText';
import * as styles from './FlyoverUpsell.module.scss';

type Props = {
  disable3D: () => void;
  initFlyoverOnLoad?: boolean;
  exactExplorePath: string;
  onFlyoverDisabled: () => void;
  setLayer: (layer: string) => void;
  onSuccess: () => void;
};

// https://www.figma.com/design/8Vz0I2E3m5cBiRc0JmLSHJ/Growth-Optimizations-%E2%80%A2-H1?node-id=193-5108&m=dev
export default function FlyoverUpsell({ disable3D, exactExplorePath, initFlyoverOnLoad, onFlyoverDisabled, setLayer, onSuccess }: Props) {
  const intl = useIntl();
  const [signupModalState, setSignupModalState] = useState<{ showLogIn?: boolean; isOpen: boolean }>({ showLogIn: false, isOpen: false });

  const languageRegionCode = useLanguageRegionCode();
  const user = useUser();

  const { exitFlyover, isPaused, togglePauseFlyover } = useFlyoverSettings();

  const { currencyCode, plan } = __AT_DATA__;

  const isPromoEligible = !user || user.isPromoEligible;

  useEffect(() => {
    logWebProModalShown({
      purchase_screen_type_plan: isPromoEligible ? PurchaseScreenTypePlan.FreeTrial : PurchaseScreenTypePlan.NonTrial,
      trigger: CarouselDisplayTrigger.Flyover
    });
  }, [isPromoEligible]);

  useEffect(() => {
    if (!isPaused) {
      togglePauseFlyover();
    }
  }, [isPaused, togglePauseFlyover]);

  const flyoverParam = 'initFlyover=true';
  const flyoverHref = exactExplorePath.indexOf('?') === -1 ? `${exactExplorePath}?${flyoverParam}` : `${exactExplorePath}&${flyoverParam}`;

  const params = new URLSearchParams();
  params.append('returnTo', flyoverHref);
  const search = params.toString();

  return (
    <>
      <div className={styles.upsell}>
        <IconButton
          className={styles.close}
          icon={{ Component: Close }}
          onClick={() => {
            if (initFlyoverOnLoad) {
              global.history.back();
            } else {
              onFlyoverDisabled();
              exitFlyover(disable3D, setLayer);
            }
          }}
          testId="close"
          title={intl.formatMessage({ defaultMessage: 'Close' })}
          variant="elevated"
        />
        <div className={styles.content}>
          <div className={styles.text}>
            <span className={styles.badge}>
              <PlusBadge />
              <Typography variant="text200">
                <FormattedMessage defaultMessage="Trail Previews" />
              </Typography>
            </span>
            <Typography variant="heading600">
              <FormattedMessage defaultMessage="Unlock unlimited previews and more" />
            </Typography>
            <Typography variant="paragraph200">
              {isPromoEligible ? (
                <FormattedMessage
                  defaultMessage="7 days free, then only {price} a year."
                  values={{
                    price: (
                      <FormattedNumber
                        currency={currencyCode}
                        // eslint-disable-next-line react/style-prop-object
                        style="currency"
                        value={plan.priceTotal}
                      />
                    )
                  }}
                />
              ) : (
                <FormattedMessage
                  defaultMessage="Only {annualCost} a year (that’s {monthlyCost}/month). Cancel anytime."
                  values={{
                    annualCost: (
                      <FormattedNumber
                        currency={currencyCode}
                        // eslint-disable-next-line react/style-prop-object
                        style="currency"
                        value={plan.priceTotal}
                      />
                    ),
                    monthlyCost: (
                      <FormattedNumber
                        currency={currencyCode}
                        // eslint-disable-next-line react/style-prop-object
                        style="currency"
                        value={plan.priceMonth}
                      />
                    )
                  }}
                />
              )}
            </Typography>
          </div>
          <Button
            allowWrap
            className={styles.button}
            linkInfo={user ? { href: wrapUrlSafe(`/plus/checkout?${search}`, languageRegionCode) } : null}
            onClick={() => {
              logWebProModalPurchaseButtonTapped({
                copy_text: isPromoEligible
                  ? WebProModalPurchaseButtonCopyText.StartYourFreeTrial
                  : WebProModalPurchaseButtonCopyText.UpgradeToAllTrailsPlus,
                purchase_screen_type_plan: isPromoEligible ? PurchaseScreenTypePlan.FreeTrial : PurchaseScreenTypePlan.NonTrial,
                trigger: CarouselDisplayTrigger.Flyover
              });

              if (!user) {
                setSignupModalState({ isOpen: true });
              }
            }}
            size="md"
            testId="try-alltrails-plus-free"
            text={
              isPromoEligible ? (
                <FormattedMessage defaultMessage="Try AllTrails+ free" />
              ) : (
                <FormattedMessage defaultMessage="Upgrade to AllTrails+" />
              )
            }
            variant="elevated"
            width="full"
          />
          <Button
            linkInfo={{ href: wrapUrlSafe(`/plus?${search}`, languageRegionCode) }}
            testId="explore-membership"
            text={<FormattedMessage defaultMessage="Explore membership" />}
            variant="inverse"
            width="full"
          />
          {!user && (
            <div className={styles.already}>
              <FormattedMessage defaultMessage="Already have an account?" />{' '}
              <Link
                onClick={() => {
                  setSignupModalState({ isOpen: true, showLogIn: true });
                }}
              >
                <FormattedMessage defaultMessage="Log in" />
              </Link>
            </div>
          )}
        </div>
      </div>
      <SignupModal
        trigger={CarouselDisplayTrigger.Flyover}
        heading={
          signupModalState.showLogIn ? (
            <>
              <FormattedMessage defaultMessage="Welcome back." />
              <br />
              <FormattedMessage defaultMessage="Log in and start exploring." />
            </>
          ) : (
            <FormattedMessage defaultMessage="Create your free account" />
          )
        }
        isOpen={signupModalState.isOpen}
        onRequestClose={() => setSignupModalState({ isOpen: false })}
        onSuccess={user => {
          setSignupModalState({ isOpen: false });

          if (user.pro) {
            onSuccess();
            return;
          }

          window.location.assign(wrapUrlSafe(`/plus/checkout?${params.toString()}`, languageRegionCode));
        }}
        showLogIn={signupModalState.showLogIn}
      />
    </>
  );
}
