import { Suspense } from 'react';

// This is temporary until we upgrade to React 18 which supports concurrent mode
// and server side suspense
const withSuspense = (Component, reactClass) => props => {
  const fallback = document.querySelector(`[data-react-class="${reactClass}"]`);

  function Fallback() {
    // eslint-disable-next-line react/no-danger
    return <div dangerouslySetInnerHTML={{ __html: fallback.innerHTML }} />;
  }

  return (
    <Suspense fallback={<Fallback />}>
      <Component {...props} />
    </Suspense>
  );
};

export default withSuspense;
