import Avatar from '@alltrails/shared/denali/components/Avatar';
import type { User } from 'types/User';
import { fullName } from '@alltrails/shared/utils/formatters/textFormatters';
import useLanguageRegionCode from '@alltrails/shared/hooks/useLanguageRegionCode';
import { ServerCommunicationUtil } from '../../../utils/server_communication_util';
import { LanguageSupportUtil } from '../../../utils/language_support_util';

type UserImageLinkProps = {
  user: User & { firstName?: string; lastName?: string };
  className?: string;
};

const UserImageLink = ({ user, className }: UserImageLinkProps) => {
  const languageRegionCode = useLanguageRegionCode();
  if (!user) {
    return null;
  }

  return (
    <Avatar
      className={className}
      userName={fullName(user)}
      userId={user?.id}
      testId={`user-link-${user?.firstName}-image`}
      hasPlus={user?.pro}
      linkInfo={{ href: LanguageSupportUtil.wrapUrlSafe(ServerCommunicationUtil.usersPath(user), languageRegionCode) }}
    />
  );
};

export default UserImageLink;
