import { useCallback, useContext, useEffect, useState } from 'react';
import { useIntl } from '@alltrails/shared/react-intl';
import logExploreFilterSheetViewed from '@alltrails/analytics/events/logExploreFilterSheetViewed';
import logExploreSearchBoxClicked from '@alltrails/analytics/events/logExploreSearchBoxClicked';
import SearchOrigin from '@alltrails/analytics/enums/SearchOrigin';
import SearchInput from '@alltrails/shared/denali/components/SearchInput';
import { AlgoliaSearchBox, AlgoliaSearchResult, TrailSearchResult, getUrl } from '@alltrails/modules/AlgoliaSearch';
import '@alltrails/modules/AlgoliaSearch/index.css';
import FilterBar from 'components/explore/FilterBar';
import MobileExploreMapSwitch from 'components/MobileExploreMapSwitch';
import { SearchResultsCountArgs } from 'hooks/useHypotheticalSearchResultsCount';
import useUser from 'hooks/useUser';
import type { Context } from 'types/Context';
import useExperiment from 'hooks/useExperiment';
import { FilterRangeUpdates, Filters, FilterSliderUpdates } from 'types/Search';
import { AlgoliaResults } from 'types/Search/Algolia';
import { newFiltersReusingSearchState } from 'utils/filters';
import { legacyAlgoliaSearchAppQuery } from 'utils/legacyAlgoliaSearchQuery';
import ListMethods from 'types/ListMethods';
import hasPermission from 'utils/hasPermission';
import NavBarSearchStateSetterContext from 'components/NavigationHeader/NavBarSearchStateSetterContext';
import useFeatures from 'hooks/useFeatures';
import NestedPanel from '../filters/NestedPanel';
import MobileFiltersModalButton from './MobileFiltersModalButton';
import getSearchBarFiltersConfigurations from './searchFiltersConfiguration';
import FiltersProvider from '../FiltersProvider';
import * as styles from './styles/styles.module.scss';

export type Props = {
  algoliaIndex: any;
  atMaps: any;
  clearAllFilters: () => void;
  clearRadioFilter?: (filterKey: string, pageName: string) => void;
  context: Context;
  enabledFilters: string[];
  enableSavedFilters?: boolean;
  filters: Filters;
  handleFilterRadioToggle?: (filterKey: string, filterConfigKey: string) => void;
  handleFilterSet?: (filterKey: string, value: string) => void;
  handleFilterRange?: (filterKey: string, value: FilterRangeUpdates | FilterSliderUpdates) => void;
  handleFilterToggle?: (filterKey: string, filterConfigKey: string) => void;
  handleObjectSelected: (result: AlgoliaSearchResult) => void;
  handleQueryTermSet: (queryString: string) => void;
  handleTrailSelected: (result: TrailSearchResult) => void;
  initialUserSlug: string;
  isMobileWidth: boolean;
  listMethods: ListMethods;
  liveStringSearch: boolean;
  page: string;
  placeholderText: string;
  results: unknown[];
  resultsCount: number;
  typeSubsets: any;
};

const SearchFilterBar = ({
  algoliaIndex,
  atMaps,
  clearAllFilters,
  clearRadioFilter,
  context,
  enabledFilters,
  enableSavedFilters,
  filters,
  handleFilterRadioToggle,
  handleFilterSet,
  handleFilterRange,
  handleFilterToggle,
  handleObjectSelected,
  handleQueryTermSet,
  handleTrailSelected,
  initialUserSlug,
  isMobileWidth,
  listMethods,
  liveStringSearch,
  page,
  placeholderText,
  results,
  resultsCount,
  typeSubsets
}: Props) => {
  const user = useUser();
  const intl = useIntl();
  const globalNavEnabled = useFeatures().includes('global_nav');
  const useNewSearch = useExperiment('web-disco-rm-global-nav')?.value === 'treatment_b';
  const navBarSearchStateSetterContext = useContext(NavBarSearchStateSetterContext);

  const [query, setQuery] = useState('');

  const trackMobileFiltersClick = () => {
    const searchOrigin = filters.mobileMap ? SearchOrigin.ExploreMapView : SearchOrigin.ExploreListView;

    logExploreFilterSheetViewed({ search_origin: searchOrigin });
  };

  const sortedFilters = getSearchBarFiltersConfigurations(filters, intl);

  // We have some overlapping work with the FilterBar here. The FilterBar expects
  // the entire sortedFilters array and has logic to reduce the filters and
  // display them where they belong. But the mobile web filters UI expects every
  // filter regardless of "nested" or "admin" status, so we have to filter out
  // inaccessible data here.
  // https://alltrails.atlassian.net/browse/DISCO-159
  const allFiltersForMobile = sortedFilters.filter(nextFilter => {
    if (!enabledFilters.includes(nextFilter.enabledKey)) {
      return false;
    }

    if (nextFilter.admin && (!hasPermission({ permission: 'trails:manage' }) || !Object.keys(nextFilter.config).length)) {
      return false;
    }

    return true;
  });

  const searchResultsCountFunction = useCallback(
    ({ changes, insidePolygon, isochroneBoundingBox }: SearchResultsCountArgs): Promise<number> => {
      const filtersWithDraftChanges = {
        ...filters,
        ...changes
      };

      return new Promise(resolve => {
        legacyAlgoliaSearchAppQuery({
          algoliaIndex,
          atMaps,
          context,
          filters: filtersWithDraftChanges,
          // For /explore pages and typical trail searches this handler is called.
          handleSearchResults: (searchResults: AlgoliaResults) => resolve(searchResults?.hits?.length ?? 0),
          // If viewing the current user's custom maps this handler is called.
          // Note this isn't actually a search. This is a filter on atMaps.
          handleUserResults: (userResults: unknown[]) => resolve(userResults?.length ?? 0),
          initialUserSlug,
          isMobileWidth,
          insidePolygon,
          isochroneBoundingBox,
          listMethods,
          page
        });
      });
    },
    [algoliaIndex, atMaps, context, filters, initialUserSlug, isMobileWidth, listMethods, page]
  );

  const onResultSelect = useCallback(
    (result: AlgoliaSearchResult) => {
      if (result.type === 'trail') {
        handleTrailSelected(result);
      } else if (result.type === 'area' || result.type === 'filter' || result.type === 'place' || result.type === 'poi') {
        handleObjectSelected(result);
      } else {
        window.open(getUrl(result, context.languageRegionCode, useNewSearch), '_blank');
      }
    },
    [context.languageRegionCode, handleObjectSelected, handleTrailSelected, useNewSearch]
  );

  useEffect(() => {
    navBarSearchStateSetterContext?.setNavBarOnResultSelect?.(() => onResultSelect);

    return () => {
      navBarSearchStateSetterContext?.setNavBarOnResultSelect?.(undefined);
    };
  }, [onResultSelect, navBarSearchStateSetterContext]);

  return (
    <FiltersProvider
      blankFiltersFactory={() => {
        const { filters: blankFilters } = newFiltersReusingSearchState(atMaps, filters, results, {
          context,
          initialUserSlug,
          page,
          intl
        });

        return getSearchBarFiltersConfigurations(blankFilters, intl);
      }}
      clearAllFilters={clearAllFilters}
      clearRadioFilter={clearRadioFilter}
      handleFilterRadioToggle={handleFilterRadioToggle}
      handleFilterRange={handleFilterRange}
      handleFilterSet={handleFilterSet}
      handleFilterToggle={handleFilterToggle}
      page={page}
      resultsCount={resultsCount}
      searchResultsCountFunction={searchResultsCountFunction}
      sortedFilters={sortedFilters}
    >
      <div className={styles.container}>
        <div className={styles.searchBoxAndButtons}>
          {liveStringSearch ? (
            <div className={styles.search}>
              <SearchInput
                onChange={value => {
                  setQuery(value);
                  handleQueryTermSet(value.trim());
                }}
                onFocus={() => {
                  const { href, pathname } = window.location;
                  logExploreSearchBoxClicked({ search_origin: SearchOrigin.ExploreMapView, href, path: pathname });
                }}
                placeholder={placeholderText || intl.formatMessage({ defaultMessage: 'Enter a city, park or trail name' })}
                size="sm"
                testId="explore-search-box"
                value={query}
              />
            </div>
          ) : (
            !globalNavEnabled && (
              <AlgoliaSearchBox
                className={styles.search}
                clearOnSelect
                configs={global.__AT_DATA__.algoliaConfig}
                isMobileBrowser={context.mobileBrowser}
                languageRegionCode={context.languageRegionCode}
                numResults={isMobileWidth ? 8 : 10}
                onResultSelect={onResultSelect}
                placeholder={placeholderText || intl.formatMessage({ defaultMessage: 'Enter a city, park or trail name' })}
                searchOrigin={SearchOrigin.ExploreMapView}
                searchTypes={typeSubsets}
                size="sm"
                testId="explore-search-box"
                useNewSearch={useNewSearch}
                user={user}
              />
            )
          )}
          {!globalNavEnabled && (
            <div className={styles.buttons}>
              <NestedPanel
                sortedFilters={allFiltersForMobile}
                title={intl.formatMessage({ defaultMessage: 'Filters' })}
                triggerElement={<MobileFiltersModalButton onClick={trackMobileFiltersClick} />}
                user={user}
              />
              <MobileExploreMapSwitch showMapMobile={filters.mobileMap} />
            </div>
          )}
        </div>

        <FilterBar
          context={context}
          enabledFilters={enabledFilters}
          enableSavedFilters={enableSavedFilters}
          globalNavEnabled={globalNavEnabled}
          allFiltersForMobile={allFiltersForMobile}
        />
      </div>
    </FiltersProvider>
  );
};

export default SearchFilterBar;
