import { defineMessages } from '@alltrails/shared/react-intl';
import { userCanEdit } from 'utils/UserUtils';
import hasPermission from './hasPermission';

const messages = defineMessages({
  downloadRoute: { defaultMessage: 'Download route' },
  downloadRoutes: { defaultMessage: 'Download routes' },
  saveEndpoint: { defaultMessage: 'Save endpoint' },
  editEndpoint: { defaultMessage: 'Edit endpoint' },
  cancelEdit: { defaultMessage: 'Cancel edit' },
  edit: { defaultMessage: 'Edit' },
  refresh: { defaultMessage: 'Refresh' },
  refreshed: { defaultMessage: 'Refreshed' },
  hide: { defaultMessage: 'Hide' },
  unhide: { defaultMessage: 'Unhide' },
  deleteMap: { defaultMessage: 'Delete map' },
  customizeMap: { defaultMessage: 'Customize map' },
  downloadMap: { defaultMessage: 'Download map' },
  addTrail: { defaultMessage: 'Add as new trail' },
  suggestTrail: { defaultMessage: 'Suggest as new trail' },
  delete: { defaultMessage: 'Delete' }
});

const buildMapMenuItems = (
  data,
  openDownloadModal,
  reportIssueText,
  reportIssueCallback,
  blockUnblockUser,
  isRefreshed,
  refreshMap,
  isHidden,
  updateHidden,
  handleDeleteClick,
  addAsNewMap,
  isMapOwner,
  saveMapAsClone,
  intl,
  user,
  garminButtonText,
  garminCallback
) => {
  const canEdit = userCanEdit(user, data?.user);
  const menuItems = [];

  if (garminButtonText) {
    menuItems.push({
      label: garminButtonText,
      callback: garminCallback
    });
  }

  menuItems.push({ label: intl.formatMessage(messages.downloadMap), callback: openDownloadModal });

  if (reportIssueText) {
    menuItems.push({ label: reportIssueText, callback: reportIssueCallback });
  }

  if (blockUnblockUser) {
    menuItems.push(blockUnblockUser);
  }

  if (hasPermission({ permission: 'trails:manage' })) {
    menuItems.push(
      {
        label: isRefreshed ? intl.formatMessage(messages.refreshed) : intl.formatMessage(messages.refresh),
        callback: refreshMap
      },
      {
        label: isHidden ? intl.formatMessage(messages.unhide) : intl.formatMessage(messages.hide),
        callback: updateHidden
      }
    );
  }

  if (canEdit && data.id) {
    menuItems.push({
      label: intl.formatMessage(messages.deleteMap),
      callback: handleDeleteClick
    });
  }

  // eslint-disable-next-line no-undef
  if (user && !data.private && !__AT_DATA__.mobileBrowser) {
    menuItems.push({
      label: hasPermission({ permission: 'trails:manage' }) ? intl.formatMessage(messages.addTrail) : intl.formatMessage(messages.suggestTrail),
      callback: addAsNewMap
    });
  }

  if (!isMapOwner && data.name && data.id) {
    menuItems.push({
      label: intl.formatMessage(messages.customizeMap),
      callback: saveMapAsClone
    });
  }

  return menuItems;
};

const buildTrackMenuItems = (
  data,
  openDownloadModal,
  reportIssueText,
  reportIssueCallback,
  blockUnblockUser,
  isEditingEndpoint,
  handleEditEndpointClick,
  handleCancelEditEndpointClick,
  handleEditClick,
  isRefreshed,
  refreshMap,
  handleDeleteClick,
  saveMapAsClone,
  intl,
  user,
  garminButtonText,
  garminCallback
) => {
  const menuItems = [];
  const canEdit = userCanEdit(user, data?.user, true);
  const gpxLinkText =
    data?.routes && data.routes.length > 1 ? intl.formatMessage(messages.downloadRoutes) : intl.formatMessage(messages.downloadRoute);

  menuItems.push({
    label: gpxLinkText,
    callback: openDownloadModal
  });

  if (reportIssueText) {
    menuItems.push({ label: reportIssueText, callback: reportIssueCallback });
  }

  if (blockUnblockUser) {
    menuItems.push(blockUnblockUser);
  }

  if (canEdit) {
    menuItems.push({
      label: isEditingEndpoint ? intl.formatMessage(messages.saveEndpoint) : intl.formatMessage(messages.editEndpoint),
      callback: handleEditEndpointClick
    });

    if (isEditingEndpoint) {
      menuItems.push({
        label: intl.formatMessage(messages.cancelEdit),
        callback: handleCancelEditEndpointClick
      });
    }
  }

  if (canEdit && data.id) {
    menuItems.push({
      label: intl.formatMessage(messages.edit),
      callback: handleEditClick
    });
  }

  if (hasPermission({ permission: 'trails:manage' })) {
    menuItems.push({
      label: isRefreshed ? intl.formatMessage(messages.refreshed) : intl.formatMessage(messages.refresh),
      callback: refreshMap
    });
  }

  if (user && data.id) {
    menuItems.push({
      label: intl.formatMessage(messages.customizeMap),
      callback: saveMapAsClone
    });
  }

  if (garminButtonText) {
    menuItems.push({ label: garminButtonText, callback: garminCallback });
  }

  if (canEdit && data.id) {
    menuItems.push({
      label: intl.formatMessage(messages.delete),
      callback: handleDeleteClick
    });
  }
  return menuItems;
};

export { buildMapMenuItems, buildTrackMenuItems };
