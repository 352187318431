import { ReactNode, useEffect, useState } from 'react';
import smallAvif from 'components/ReferralWelcome/landing-page-1-w750.avif';
import smallWebp from 'components/ReferralWelcome/landing-page-1-w750.webp';
import smallJpg from 'components/ReferralWelcome/landing-page-1-w750.jpg';
import largeAvif from 'components/ReferralWelcome/landing-page-1-w2800.avif';
import largeWebp from 'components/ReferralWelcome/landing-page-1-w2800.webp';
import largeJpg from 'components/ReferralWelcome/landing-page-1-w2800.jpg';
import { FormattedMessage, defineMessages, useIntl } from '@alltrails/shared/react-intl';
import Typography from '@alltrails/shared/denali/components/Typography';
import ApplicationLayout from 'components/ApplicationLayout';
import CustomProvider from 'components/CustomProvider';
import useUser from 'hooks/useUser';
import Link from '@alltrails/shared/denali/components/Link';
import logInviteFlowScreenViewed from '@alltrails/analytics/events/logInviteFlowScreenViewed';
import logInviteFlowCopyLinkClicked from '@alltrails/analytics/events/logInviteFlowCopyLinkClicked';
import HeroLayout from 'components/HeroLayout';
import Button from '@alltrails/shared/denali/components/Button';
import OutlinedTextField from '@alltrails/shared/components/OutlinedTextField';
import LoginForm from 'components/LoginForm';
import LoginSignupLayout from 'components/LoginSignupLayout';
import { useDispatch } from 'react-redux';
import useToggle from '@alltrails/shared/hooks/useToggle';
import HowItWorksModal from 'components/HowItWorksModal/HowItWorksModal';
import YourInvitesModal from 'components/YourInvitesModal';
import Modal from 'components/Modal';
import { Context } from 'types/Context';
import { setUser } from '../../userSlice';
import * as styles from './Refer.module.scss';

const messages = defineMessages({
  heroAlt: {
    defaultMessage:
      "A close-up of two people's hands planting a redwood tree together. One is holding a tree in a black pot, while the other makes room for the tree to be planted in the ground among green redwood needles."
  },
  copy: {
    defaultMessage: 'Copy'
  },
  copied: {
    defaultMessage: 'Copied!'
  }
});

type Props = {
  context: Context;
  currentPage: string;
  locationSlug: string;
};

// Desktop: https://www.figma.com/file/gZaqAej8HzoFkyrjlQ4cNs/Integrating-Brand-Changes?node-id=7834%3A91524
// Mobile: https://www.figma.com/file/gZaqAej8HzoFkyrjlQ4cNs/Integrating-Brand-Changes?node-id=7834%3A90390
function ReferContent() {
  const [isCopied, setIsCopied] = useState(false);
  const intl = useIntl();
  const user = useUser();
  const [isLearnMoreModalOpen, toggleLearnMoreModal] = useToggle(false);
  const [isYourInvitesModalOpen, toggleYourInvitesModal] = useToggle(false);

  const button = (chunks: ReactNode) => (
    <Link onClick={toggleLearnMoreModal} size="md">
      {chunks}
    </Link>
  );

  useEffect(() => {
    logInviteFlowScreenViewed();
  }, []);

  return (
    <HeroLayout
      images={{
        mobile: { avif: smallAvif, webp: smallWebp, jpg: smallJpg },
        desktop: { avif: largeAvif, webp: largeWebp, jpg: largeJpg }
      }}
      heroAlt={intl.formatMessage(messages.heroAlt)}
    >
      <Modal isOpen={isLearnMoreModalOpen} onRequestClose={toggleLearnMoreModal} title={<FormattedMessage defaultMessage="How it works" />}>
        <HowItWorksModal />
      </Modal>
      <Modal isOpen={isYourInvitesModalOpen} onRequestClose={toggleYourInvitesModal} title={<FormattedMessage defaultMessage="Your invites" />}>
        <YourInvitesModal />
      </Modal>
      <h1 className={styles.heading}>
        <FormattedMessage defaultMessage="Share the love and help the planet" />
      </h1>
      <Typography className={styles.invite} component="div" variant="paragraph200">
        <FormattedMessage
          defaultMessage="Invite friends to AllTrails! Once they sign up and get the app, we’ll plant a tree for each of you. <button>Learn more</button>"
          values={{ button }}
        />
      </Typography>
      <div className={styles.title}>
        <Typography component="div" variant="heading300">
          <FormattedMessage defaultMessage="Invite your friends" />
        </Typography>
      </div>
      <div className={styles.content}>
        <div className={styles.fields}>
          <OutlinedTextField className={styles.link} readOnly type="text" value={user.referral_link} />
          <Button
            text={isCopied ? intl.formatMessage(messages.copied) : intl.formatMessage(messages.copy)}
            onClick={() => {
              const { referral_link } = user;
              logInviteFlowCopyLinkClicked({ referral_link });
              navigator.clipboard.writeText(referral_link);
              setIsCopied(true);
            }}
            testId="referal-link-copy"
            variant="primary"
          />
        </div>
        <Link onClick={toggleYourInvitesModal} size="md">
          <FormattedMessage defaultMessage="Your invites" />
        </Link>
      </div>
      <Link className={styles.terms} variant="secondary" href="/terms" size="sm">
        <FormattedMessage defaultMessage="Terms and conditions" />
      </Link>
    </HeroLayout>
  );
}

function ReferRouter() {
  const dispatch = useDispatch();
  const user = useUser();

  if (user) {
    return <ReferContent />;
  }

  return (
    <LoginSignupLayout>
      <LoginForm
        handleLoginSuccess={({ user }) => {
          dispatch(setUser(user));
        }}
      />
    </LoginSignupLayout>
  );
}

function Refer({ context, currentPage, locationSlug }: Props) {
  return (
    <ApplicationLayout context={context} currentPage={currentPage} locationSlug={locationSlug}>
      <CustomProvider>
        <ReferRouter />
      </CustomProvider>
    </ApplicationLayout>
  );
}

export default Refer;
