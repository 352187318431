import { defineMessages, useIntl } from '@alltrails/shared/react-intl';
import useFiltersContext from 'hooks/useFiltersContext';
import classNames from 'classnames';
import * as styles from './MobileFiltersModalButton.module.scss';

const { modalToggleIcon } = defineMessages({
  modalToggleIcon: {
    defaultMessage: 'view all search filters'
  }
});

type Props = {
  onClick: () => void;
};

export default function MobileFiltersModalButton({ onClick }: Props) {
  const intl = useIntl();

  const { appliedFilterLabelsByKey } = useFiltersContext();

  const hasAppliedFilters = Object.values(appliedFilterLabelsByKey).reduce((memo, next) => [...memo, ...next], []).length > 0;

  return (
    <span>
      <button
        aria-label={intl.formatMessage(modalToggleIcon)}
        type="button"
        className={classNames(styles.filtersModalButton, hasAppliedFilters ? styles.activeIcon : styles.icon)}
        onClick={onClick}
        title={intl.formatMessage(modalToggleIcon)}
      />
    </span>
  );
}
