import { useContext, useState } from 'react';
import classNames from 'classnames';
import { useIntl } from '@alltrails/shared/react-intl';
import isEmpty from 'underscore/modules/isEmpty';
import logPrintFailed from '@alltrails/analytics/events/logPrintFailed';
import logPrintMapClicked from '@alltrails/analytics/events/logPrintMapClicked';
import logPrintStarted from '@alltrails/analytics/events/logPrintStarted';
import logPrintSucceeded from '@alltrails/analytics/events/logPrintSucceeded';
import BrowserPlatform from '@alltrails/analytics/enums/BrowserPlatform';
import Button from '@alltrails/shared/denali/components/Button';
import PageTitle from '@alltrails/shared/types/PageTitle';
import getPageTitle from '@alltrails/shared/utils/getPageTitle';
import useDisplayMetric from 'hooks/useDisplayMetric';
import useLanguageRegionCode from '@alltrails/shared/hooks/useLanguageRegionCode';
import { modalRoadblock } from '@alltrails/shared/utils/modalFunnelUtils';
import CustomProvider from 'components/CustomProvider';
import PortalModal from 'components/shared/PortalModal';
import MapExpandControl, { MapExpandContext, MapExpandProvider } from 'components/MapExpandControl';
import { DIMENSIONS, Grids, Orientations, PaperSizes } from './constants';
import { PrintProvider } from './printContext';
import { FullscreenBreadcrumbBar } from '../../application/javascripts/react_components/search/fullscreen/breadcrumb_bar';
import { PrintMap } from '../../application/javascripts/react_components/print/PrintMap';
import { GridUtils } from '../../application/javascripts/react_components/print/GridUtils';
import { retrievePdfUrlFromGeppetto } from '../../utils/requests/print_map_requests';
import ProgressDialog from '../shared/ProgressDialog';
import useMobileWidth from '../../hooks/useMobileWidth';
import PrintSettings from './components/settings/PrintSettings';
import GoBack from './components/GoBack';
import logError from '../../utils/logError';
import { wrapEventHandler } from '../../utils/handlers';
import { copyVerifiedRoute, copyRoute } from '../../utils/requests/at_map_requests';
import { LanguageSupportUtil } from '../../utils/language_support_util';
import useQueryString from '../../hooks/useQueryString';
import * as styles from './PrintPage.module.scss';

const PrintPage = props => {
  const { isMapExpanded } = useContext(MapExpandContext);
  const metric = useDisplayMetric();
  const languageRegionCode = useLanguageRegionCode();
  const initialAccordionStates = {
    paperSize: false,
    orientation: false,
    grid: false,
    scale: false
  };
  const [accordionsOpened, updateAccordionState] = useState(initialAccordionStates);
  const toggleAccordionState = accordionName => {
    updateAccordionState(prevState => ({ ...prevState, [accordionName]: !prevState[accordionName] }));
  };
  const intl = useIntl();

  const toUrlEncoded = obj =>
    Object.keys(obj)
      .map(k => `${encodeURIComponent(k)}=${encodeURIComponent(obj[k])}`)
      .join('&');

  const buildLambdaBody = (
    paperSize,
    orientation,
    gridFormat,
    scale,
    paperDimensionWidth,
    paperDimensionHeight,
    mapCenterLat,
    mapCenterLng,
    overlays,
    mapLayer
  ) => {
    const { exploreMap, jwt } = props;

    let url = '';
    if (typeof window !== 'undefined') {
      const host = window.location.host === 'localhost:3000' ? 'host.docker.internal:3000' : window.location.host;
      url = `${window.location.protocol}//${host}${window.location.pathname}`;
    }

    const obj = {
      utf8: true,
      title: exploreMap?.name,
      at_map_id: exploreMap?.id,
      map_center_lat: mapCenterLat,
      map_center_lon: mapCenterLng,
      map_type: mapLayer,
      paper_size: paperSize,
      custom_paper_width: paperSize !== 'custom' ? '16.0' : paperDimensionWidth,
      custom_paper_height: paperSize !== 'custom' ? '16.0' : paperDimensionHeight,
      paper_orientation: orientation,
      grid_format: gridFormat,
      map_zoom: GridUtils.getScaledZoom(scale, { lng: mapCenterLng, lat: mapCenterLat }).fractionalZoom,
      map_datum: 'wgs84'
    };

    // add trail_id to the payload if exists
    if (exploreMap?.trailId) Object.assign(obj, { trail_id: exploreMap?.trailId });

    if (!isEmpty(overlays)) {
      let appendUrl = '';
      const encodedUrl = encodeURIComponent(`enabled_overlays[]`);
      overlays.forEach(overlay => {
        appendUrl += `&${encodedUrl}=${overlay}`;
      });
      url = `${url}?${toUrlEncoded(obj)}${appendUrl}`;
    } else {
      url = `${url}?${toUrlEncoded(obj)}`;
    }

    return { url, jwt, noCache: 1 };
  };

  const initialPaperSize = metric ? PaperSizes.A4 : PaperSizes.LETTER;
  const [paperSize, setPaperSize] = useQueryString('paper_size', initialPaperSize);
  const [paperDimensionsValid, setPaperDimensionsValid] = useState(true);
  const [paperDimensionWidth, setPaperDimensionsWidth] = useQueryString('custom_paper_width', DIMENSIONS[paperSize][0], true);
  const [paperDimensionHeight, setPaperDimensionsHeight] = useQueryString('custom_paper_height', DIMENSIONS[paperSize][1], true);
  const [orientation, setOrientation] = useQueryString('paper_orientation', Orientations.PORTRAIT);
  const [grid, setGrid] = useQueryString('grid_format', Grids.DECIMAL);
  const [scale, setScale] = useQueryString('map_zoom', props?.initialCenter[2], true);
  const [progressDialogState, setProgressDialog] = useState('');
  const [downloadLink, setDownloadLink] = useState('');
  const isMobileWidth = useMobileWidth(480);
  const [mapCenterLat, setMapCenterLat] = useState(props?.initialCenter[0]);
  const [mapCenterLng, setMapCenterLng] = useState(props?.initialCenter[1]);
  const [overlays, setOverlays] = useState(props.enabledOverlays);
  const [mapLayer, setMapLayer] = useQueryString('map_type', props.mapLayer);

  const printContextValue = {
    metric,
    paperSize,
    paperDimensionWidth,
    paperDimensionHeight,
    setPaperSize,
    setPaperDimensionsWidth,
    setPaperDimensionsHeight,
    setPaperDimensionsValid,
    orientation,
    setOrientation,
    grid,
    setGrid,
    scale,
    setScale
  };

  const updateProgressDialog = progressDialog => {
    setProgressDialog(progressDialog);
  };

  const openPdf = () => {
    window.open(downloadLink, '_blank');
  };

  const resetToInitialSettings = () => {
    setPaperSize(initialPaperSize);
    setOrientation(Orientations.PORTRAIT);
    setGrid(Grids.DECIMAL);
    setScale(14);
  };

  const renderProgressDialog = () => {
    if (!progressDialogState) {
      return null;
    }

    if (typeof window !== 'undefined') {
      return (
        <PortalModal isOpen={progressDialogState && typeof window !== 'undefined'}>
          <ProgressDialog
            variant={progressDialogState}
            updateProgressDialog={updateProgressDialog}
            downloadLink={downloadLink}
            openPdf={openPdf}
            isMobileWidth={isMobileWidth}
          />
        </PortalModal>
      );
    }
    return null;
  };

  const printPopUp = () => {
    window.print();
  };

  const onSubmit = isPrint => {
    const printStartTime = Date.now();
    const amplitudeAttributes = {
      grid,
      orientation,
      paper_size: paperSize,
      platform: isMobileWidth ? BrowserPlatform.Mobile : BrowserPlatform.Web,
      scale
    };

    if (isPrint && !isMobileWidth) {
      logPrintMapClicked(amplitudeAttributes);
      printPopUp();
      return;
    }

    updateProgressDialog('downloading');
    setDownloadLink('');

    const requestBody = buildLambdaBody(
      paperSize,
      orientation,
      grid,
      scale,
      paperDimensionWidth,
      paperDimensionHeight,
      mapCenterLat,
      mapCenterLng,
      overlays,
      mapLayer
    );

    logPrintStarted(amplitudeAttributes);
    retrievePdfUrlFromGeppetto(requestBody)
      .then(response => {
        logPrintSucceeded({ duration: new Date() - printStartTime, ...amplitudeAttributes });

        setDownloadLink(response.message);
        updateProgressDialog('downloaded');
      })
      .catch(e => {
        if (e.status === 401) {
          updateProgressDialog('error-print-expired');
        } else {
          logPrintFailed({ duration: new Date() - printStartTime, ...amplitudeAttributes });
          updateProgressDialog('error');
        }
      });
  };

  const handleMapSaveError = err => {
    updateProgressDialog('error');
    logError(err);
    return err;
  };

  const redirectToMapPage = map => {
    if (!map) {
      const params = new URLSearchParams(window.location.search);
      const validMapParams = ['map_center_lat', 'map_center_lon', 'map_zoom'];
      params.forEach((_, param) => {
        if (!validMapParams.includes(param)) {
          params.delete(param);
        }
      });
      window.location.href = LanguageSupportUtil.wrapUrlSafe(
        `/explore/map/new${[...new Set(params.keys())].length === validMapParams.length ? `?${params.toString()}` : ''}`
      );
    } else {
      window.location.href = LanguageSupportUtil.wrapUrlSafe(`/explore/map/${map.slug}?save`, languageRegionCode);
    }
  };

  const blockUserNewMapCreation = () => {
    const returnToUrl = window.location.pathname + window.location.search;
    modalRoadblock('signup', 'explore-map-new', returnToUrl, languageRegionCode);
  };

  const getCustomMapLink = () => {
    // using custom map link if map already exist
    // ex: pathname = 'https://alltrails.com/explore/map/trailName-uuid/print'
    // result: 'https://alltrails.com/explore/map/trailName-uuid'
    const splitPathname = window.location.pathname.split('/');
    // Other locales add an additional pathname. Simply remove the last pathname
    // which is print and join them back together.
    splitPathname.pop();
    return splitPathname.join('/');
  };

  const getMapId = type => {
    const { exploreMap } = props;
    switch (type) {
      case 'track':
        return exploreMap?.id;
      case 'trail':
        return exploreMap?.trail_id;
      case 'map':
        return exploreMap?.id;
      default:
        return null;
    }
  };

  const createCustomMap = () => {
    setDownloadLink('');
    const pageTitle = getPageTitle(window.location.pathname);
    if (pageTitle === PageTitle.ExploreMapDetails) {
      window.location = LanguageSupportUtil.wrapUrlSafe(getCustomMapLink(), languageRegionCode);
    } else {
      // Block user with signup if not signed-in
      if (!props.context.currentUser) {
        blockUserNewMapCreation();
        return;
      }

      if (!props.exploreMap) {
        redirectToMapPage();
        return;
      }

      const { type, name } = props.exploreMap;
      const id = getMapId(type);
      updateProgressDialog('saving');
      if (type !== 'trail') {
        copyRoute(id, name).then(redirectToMapPage).catch(handleMapSaveError);
      } else {
        copyVerifiedRoute(id, name).then(redirectToMapPage).catch(handleMapSaveError);
      }
    }
  };

  const generateBreadcrumbsItems = isMap => {
    const breadcrumbItems = [];
    breadcrumbItems.push({ link: null, label: intl.formatMessage({ defaultMessage: 'Members' }) });
    breadcrumbItems.push({
      link: `/members/${props.context.currentUser.slug}`,
      label: `${props.context.currentUser.firstName} ${props.context.currentUser.lastName}`
    });
    if (isMap) {
      breadcrumbItems.push(
        { link: `/members/${props.context.currentUser.slug}/maps`, label: intl.formatMessage({ defaultMessage: 'Maps' }) },
        { link: null, label: props.exploreMap.name }
      );
    } else {
      breadcrumbItems.push(
        { link: `/explore/members/${props.context.currentUser.slug}/recordings`, label: intl.formatMessage({ defaultMessage: 'Activities' }) },
        { link: null, label: props.exploreMap.name }
      );
    }
    return breadcrumbItems;
  };

  const renderMapBreadcrumbs = () => {
    const pageTitle = getPageTitle(window.location.pathname);
    if (pageTitle === PageTitle.ExploreMapDetails || pageTitle === PageTitle.ExploreRecordingDetails) {
      return (
        <FullscreenBreadcrumbBar
          items={pageTitle === PageTitle.ExploreMapDetails ? generateBreadcrumbsItems(true) : generateBreadcrumbsItems()}
          onMapPage
          context={props.context}
          intl={intl}
        />
      );
    }
    return null;
  };

  return (
    <div className="printPage">
      <PrintProvider value={printContextValue}>
        {renderMapBreadcrumbs()}
        <div className="pageContainer">
          {!isMobileWidth ? (
            <div className={classNames('panelContainer', { expanded: isMapExpanded })}>
              <GoBack title={intl.formatMessage({ defaultMessage: 'Back' })} className={isMobileWidth ? 'isMobile' : ''} />
              <div className="titleContainer">
                <h1 className="title">{intl.formatMessage({ defaultMessage: 'Print map' })}</h1>
                <h2 className="subtitle">{intl.formatMessage({ defaultMessage: 'Print your own customizable maps for backup.' })}</h2>
              </div>
              <div className="settingsActions">
                <PrintSettings
                  toggleAccordionState={toggleAccordionState}
                  accordionsOpened={accordionsOpened}
                  title={intl.formatMessage({ defaultMessage: 'Settings' })}
                  isMobileWidth={isMobileWidth}
                />
                <Button
                  text={intl.formatMessage({ defaultMessage: 'Create custom map' })}
                  className="createCustomMap"
                  onClick={wrapEventHandler(createCustomMap)}
                  size="lg"
                  testId="print-page-create-custom-map"
                  variant="flat"
                  width="full"
                />
              </div>
              <div className={styles.actionsContainer}>
                <Button
                  text={intl.formatMessage({ defaultMessage: 'Download PDF' })}
                  disabled={!paperDimensionsValid}
                  onClick={() => onSubmit(false)}
                  testId="print-page-download"
                />
                <Button
                  text={intl.formatMessage({ defaultMessage: 'Print Map' })}
                  disabled={!paperDimensionsValid}
                  onClick={() => onSubmit(true)}
                  testId="print-page-print-map"
                  variant="primary"
                />
              </div>
            </div>
          ) : (
            <div className="mobileSidebarContainer">
              <div className="mobileTitleContainer">
                <GoBack title={intl.formatMessage({ defaultMessage: 'Back' })} className="isMobile" />
                <div className="mobilePrintMap">
                  <h1 className="title isMobile">{intl.formatMessage({ defaultMessage: 'Print map' })}</h1>
                  <PrintSettings
                    toggleAccordionState={toggleAccordionState}
                    accordionsOpened={accordionsOpened}
                    title={intl.formatMessage({ defaultMessage: 'Settings' })}
                    isMobileWidth={isMobileWidth}
                    resetToInitialSettings={resetToInitialSettings}
                  />
                </div>
              </div>
            </div>
          )}
          <div id="pdf-map-container" className={isMobileWidth ? 'mobileContainer' : `previewContainer ${isMapExpanded ? 'expanded' : ''}`}>
            <div className="mapExpandHandle">
              <MapExpandControl />
            </div>

            <div id="pdf-map-page" className={classNames('preview', { expanded: isMapExpanded })}>
              <PrintMap
                {...props}
                grid={grid}
                paperSize={paperSize}
                orientation={orientation}
                paperDimensionWidth={paperDimensionWidth}
                paperDimensionHeight={paperDimensionHeight}
                scale={scale}
                isMobileWidth={isMobileWidth}
                setScale={setScale}
                setMapCenterLat={setMapCenterLat}
                setMapCenterLng={setMapCenterLng}
                setOverlays={setOverlays}
                mapLayer={mapLayer}
                setMapLayer={setMapLayer}
                mapCenterLat={mapCenterLat}
                mapCenterLng={mapCenterLng}
                intl={intl}
              />
            </div>
          </div>
          <div id="modalPortal" />
          {renderProgressDialog()}
          {isMobileWidth && (
            <div className="actionsMobileContainer">
              <Button
                text={intl.formatMessage({ defaultMessage: 'Print Map' })}
                variant="primary"
                disabled={!paperDimensionsValid}
                onClick={() => onSubmit(false)}
              />
            </div>
          )}
        </div>
      </PrintProvider>
    </div>
  );
};

const PrintPageWithProvider = props => (
  <CustomProvider>
    <MapExpandProvider>
      <PrintPage {...props} />
    </MapExpandProvider>
  </CustomProvider>
);

export default PrintPageWithProvider;
