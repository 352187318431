import { forwardRef } from 'react';
import turfDistance from '@turf/distance';
import classNames from 'classnames';
import { pointItemToLngLat } from '@alltrails/maps/utils/legacyGeoJSONConversions';
import { FormattedMessage, useIntl, defineMessages } from '@alltrails/shared/react-intl';
import { AlgoliaSearchBox } from '@alltrails/modules/AlgoliaSearch';
import TextInput from '@alltrails/shared/denali/components/TextInput';
import TextArea from '@alltrails/shared/denali/components/TextArea';
import IconButton from '@alltrails/shared/denali/components/IconButton';
import Button from '@alltrails/shared/denali/components/Button';
import Select from '@alltrails/shared/denali/components/Select';
import Expandable from '@alltrails/shared/components/Expandable';
import Edit from '@alltrails/shared/icons/Edit';
import Link from '@alltrails/shared/denali/components/Link';
import Remove from '@alltrails/shared/icons/Remove';
import Add from '@alltrails/shared/icons/Add';
import Polygon from '@alltrails/shared/icons/Polygon';
import ActivityUploadModal from '@alltrails/modules/ActivityUpload/ActivityUploadModal';
import { post } from '@alltrails/shared/api';
import { PARK_SELECTION, TRAIL_DETAILS } from 'utils/constants/SearchAnalyticsConstants';
import { DEFAULT_RADIUS_METERS } from 'utils/legacyAlgoliaSearchQuery';
import PortalModal from 'components/shared/PortalModal';
import CustomProvider from 'components/CustomProvider';
import hasPermission from 'utils/hasPermission';
import WaypointsWrapper from 'components/Waypoints/WaypointsWrapper';
import CollectionsAlertDialog from './CollectionsAlertDialog';
import FeaturesAlertDialog from './FeaturesAlertDialog';
import logError from '../../../../utils/logError';
import { MapStatsUtil } from '../../../../utils/map_stats_util';
import { getPrimaryIndex } from '../../../../utils/search';
import RegionAndCountrySelect from './region_and_country_select';
import { VerifiedRouteContributeItem } from './verified_route_contribute_item';
import TagSection from './TagSection';
import AttributesTagSection from './AttributesTagSection';
import TrailRedrawStats from './TrailRedrawStats';
import * as styles from './styles/styles.module.scss';

// eslint-disable-next-line @typescript-eslint/no-var-requires
const createReactClass = require('create-react-class');

const METERS_TO_MILES = 0.000621371;

// Same as server-side constant:
const CENTER_LOWER_48 = [39.8282102, -98.581832];

const monthMessages = defineMessages({
  January: { defaultMessage: 'January' },
  February: { defaultMessage: 'February' },
  March: { defaultMessage: 'March' },
  April: { defaultMessage: 'April' },
  May: { defaultMessage: 'May' },
  June: { defaultMessage: 'June' },
  July: { defaultMessage: 'July' },
  August: { defaultMessage: 'August' },
  September: { defaultMessage: 'September' },
  October: { defaultMessage: 'October' },
  November: { defaultMessage: 'November' },
  December: { defaultMessage: 'December' }
});

const difficultyMessages = defineMessages({
  easy: { defaultMessage: 'Easy' },
  moderate: { id: 'difficulty.Moderate', defaultMessage: 'Moderate' },
  hard: { defaultMessage: 'Hard' },
  strenuous: { defaultMessage: 'Strenuous' }
});

const difficultyToMessage = {
  1: difficultyMessages.easy,
  3: difficultyMessages.moderate,
  5: difficultyMessages.hard,
  7: difficultyMessages.strenuous
};

const usageMessages = defineMessages({
  light: { defaultMessage: 'Light' },
  moderate: { id: 'usage.Moderate', defaultMessage: 'Moderate' },
  heavy: { defaultMessage: 'Heavy' },
  extra_heavy: { defaultMessage: 'Extra heavy' }
});

const usageToMessage = {
  1: usageMessages.light,
  2: usageMessages.moderate,
  3: usageMessages.heavy,
  4: usageMessages.extra_heavy
};

const routeTypeMessages = defineMessages({
  loop: { defaultMessage: 'Loop' },
  out_and_back: { defaultMessage: 'Out & back' },
  point_to_point: { defaultMessage: 'Point to point' }
});

const routeTypeToMessage = {
  L: routeTypeMessages.loop,
  O: routeTypeMessages.out_and_back,
  P: routeTypeMessages.point_to_point
};

const BaseTrailDetailsBox = createReactClass({
  getInitialState() {
    const { trail, defaultMap, currentCity } = this.props;
    const { name, area, location, routeType, defaultActivityStats } = trail;

    return {
      routeType: routeType?.uid ?? null,
      difficultyRating: this.convertDifficultyRating(defaultActivityStats.difficulty),
      visitorUsage: defaultActivityStats.visitorUsage,
      location: { latitude: 0, longitude: 0 },
      areaId: area?.id,
      cityId: location?.city_id,
      nearbyCities: [],
      nearbyParks: [],
      description: this.getDescription(),
      keyPoints: this.getAdminNotes(),
      name: name || defaultMap?.name || '',
      lastRemovedCollection: null,
      lastRemovedFeature: null,
      lastAddedFeatureKey: null,
      expandState: {
        suggestABetterRoute: true,
        editTrailInfo: true,
        routeDetails: true,
        findStartingLocation: true,
        waypoints: false
      },
      seasonStart: defaultActivityStats.seasonStart,
      seasonEnd: defaultActivityStats.seasonEnd,
      freeFormCity: '',
      selectedRegion: currentCity ? currentCity.state : null,
      selectedCountry: currentCity ? currentCity.country : null,
      freeFormArea: '',
      hideResults: false,
      showAllSecondaryParks: false
    };
  },
  componentDidMount() {
    const { languageRegionCode, trail, currentCity } = this.props;
    const { area, location } = trail;

    let areaText;
    let cityText;
    let cityLocation;
    const secondaryAreas = [];

    const trailLngLat = pointItemToLngLat(trail);
    if (trailLngLat) {
      // Set city-relevant info
      const cityDistanceTo = currentCity ? turfDistance(trailLngLat, pointItemToLngLat(currentCity)) * 1000 : 0;
      cityText = `${location.city} (${this.metersToFormattedUserUnits(cityDistanceTo)})`;
      cityLocation = (location.regionName ? `${location.regionName}, ` : '') + location.country_name;
      // Set area-relevant info
      if (area) {
        const areaDistanceTo = turfDistance(trailLngLat, pointItemToLngLat(area)) * 1000;
        areaText = `${area.name} (${this.metersToFormattedUserUnits(areaDistanceTo)})`;
      }
      if (hasPermission({ permission: 'trails:manage' }) && trail?.associatedAreas?.length > 0) {
        trail?.associatedAreas.forEach(associatedArea => {
          const areaDistanceTo = turfDistance(trailLngLat, pointItemToLngLat(associatedArea)) * 1000;
          secondaryAreas.push(`${associatedArea.name} (${this.metersToFormattedUserUnits(areaDistanceTo)})`);
        });
      }
    }

    const latitude = Math.round(parseFloat(trailLngLat[1]) * 10000000) / 10000000;
    const longitude = Math.round(parseFloat(trailLngLat[0]) * 10000000) / 10000000;

    if (!this.listeners) {
      this.listeners = [];
    }
    this.algoliaIndex = getPrimaryIndex(languageRegionCode);

    this.setState(
      {
        location: { latitude, longitude },
        areaText,
        cityText,
        cityLocation,
        secondaryAreas
      },
      this.algoliaParksAndCityQuery
    );
  },
  componentDidUpdate(prevProps) {
    const { routeInfo } = this.props;
    if (prevProps.routeInfo && routeInfo && routeInfo.routeStartPt && routeInfo !== prevProps.routeInfo) {
      this.handleTrailHeadMoved(routeInfo.routeStartPt);
    }
  },
  componentWillUnmount() {
    for (let i = 0; i < this.listeners.length; i++) {
      this.listeners[i].unsubscribe();
    }
  },
  toggleExpandState(key, value) {
    this.setState(prevState => ({
      expandState: {
        ...prevState.expandState,
        [key]: value
      }
    }));
  },
  algoliaParksAndCityQuery() {
    const { location } = this.state;
    this.algoliaParksAndCityQueryLatLng(location.latitude, location.longitude);
  },
  algoliaParksAndCityQueryLatLng(lat, lng) {
    this.algoliaIndex
      .search('', {
        aroundLatLng: `${lat},${lng}`,
        aroundRadius: 1000000,
        getRankingInfo: 1,
        facets: ['type'],
        facetFilters: ['type:area'],
        hitsPerPage: 1000
      })
      .then(this.handleParkResults)
      .catch(err => logError(err));
    this.algoliaIndex
      .search('', {
        aroundLatLng: `${lat},${lng}`,
        aroundRadius: 200000,
        getRankingInfo: 1,
        facets: ['type'],
        facetFilters: ['type:place'],
        hitsPerPage: 100
      })
      .then(this.handleCityResults)
      .catch(err => logError(err));
  },
  handleTrailHeadMoved(lngLatLike) {
    const { cityId } = this.state;

    const latitude = Math.round(parseFloat(lngLatLike[1]) * 10000000) / 10000000;
    const longitude = Math.round(parseFloat(lngLatLike[0]) * 10000000) / 10000000;
    this.setState({
      location: { latitude, longitude }
    });
    this.algoliaParksAndCityQueryLatLng(latitude, longitude);
    // eslint-disable-next-line eqeqeq
    if (cityId == -2) {
      this.updateTrailHeadGeocode();
    }
  },
  updateTrailHeadGeocode() {
    const {
      intl: { formatMessage }
    } = this.props;
    const { location } = this.state;

    this.setState({ geocodedCityText: formatMessage({ defaultMessage: 'loading...' }) });
    if (this.revGeocodeRequest) {
      this.revGeocodeRequest.abort();
      this.revGeocodeRequest = null;
    }

    this.revGeocodeRequest = post('/api/alltrails/locations/reverse_geocode_city', {
      latitude: location.latitude,
      longitude: location.longitude
    })
      .then(data => {
        if (data && data.name) {
          this.setState({ geocodedCityText: data.name });
        }
      })
      .catch(() => {
        this.setState({ geocodedCityText: formatMessage({ defaultMessage: 'an error occurred' }) });
      });
  },
  handleCityChange(selectedValue) {
    const { nearbyCities } = this.state;

    const cityId = parseInt(selectedValue);
    if (cityId === -2) {
      this.updateTrailHeadGeocode();
    }
    // eslint-disable-next-line eqeqeq
    const city = nearbyCities.filter(c => c[0] == cityId);
    let cityText = null;
    let cityLocation = null;
    if (city && city[0]) {
      // Do not keep distance-to as will likely be different when this is re-displayed
      cityText = city[0][1].replace(/ \(.*mi\)$/, '');
      // eslint-disable-next-line prefer-destructuring
      cityLocation = city[0][2];
    }
    this.setState({ cityId, cityText, cityLocation });
  },
  handleCityFreeFormChange(value) {
    this.setState({ freeFormCity: value });
  },
  handleAreaFreeFormChange(value) {
    this.setState({ freeFormArea: value });
  },
  handleSeasonStartDateChange(value) {
    this.setState({ seasonStart: value });
  },
  handleSeasonEndDateChange(value) {
    this.setState({ seasonEnd: value });
  },
  getNameEdit() {
    return { type: 'name', data: { name: this.state.name } };
  },
  // eslint-disable-next-line react/no-unused-class-component-methods
  getDifficultyEdit() {
    return this.state.difficultyRating;
  },
  getAttributesEdits(selectedAttributes, originalAttributes) {
    const edits = [];
    // eslint-disable-next-line eqeqeq
    if (Object.keys(selectedAttributes) != Object.keys(originalAttributes)) {
      Object.keys(selectedAttributes).forEach(key => {
        if (Object.keys(originalAttributes).indexOf(key) < 0) {
          edits.push({
            type: 'attribute',
            data: { allowed: 'Y', status_update_code: 'user_added' },
            relation_id: selectedAttributes[key].uid
          });
        }
      });
      Object.keys(originalAttributes).forEach(key => {
        if (Object.keys(selectedAttributes).indexOf(key) < 0) {
          edits.push({
            type: 'attribute',
            data: { allowed: 'N', status_update_code: 'user_removed' },
            relation_id: originalAttributes[key].uid
          });
        }
      });
    }

    return edits;
  },

  getCollectionMappingsEdits(selectedCollectionMappings, originalCollectionMappings) {
    const edits = [];

    originalCollectionMappings?.forEach(originalMapping => {
      const existsInSelected = selectedCollectionMappings.some(selectedMapping => selectedMapping.id === originalMapping.id);

      if (!existsInSelected) {
        edits.push({
          type: 'collection_mapping',
          data: { allowed: 'N', status_update_code: 'admin_override' },
          relation_id: originalMapping.id
        });
      }
    });

    return edits;
  },

  // eslint-disable-next-line react/no-unused-class-component-methods
  getAllTrailEdits() {
    const {
      trail,
      defaultMap,
      isPendingTrail,
      originalActivities,
      originalFeatures,
      originalObstacles,
      selectedActivities,
      selectedFeatures,
      selectedObstacles,
      collectionMappings,
      originalCollectionMappings
    } = this.props;
    const {
      name,
      routeType,
      difficultyRating,
      visitorUsage,
      areaId,
      nearbyParks,
      cityId,
      nearbyCities,
      location,
      seasonStart,
      seasonEnd,
      description,
      keyPoints
    } = this.state;
    const { regionAndCountry: regionAndCountryRef, verifiedRouteContribute: verifiedRouteContributeRef } = this.refs;

    const allEdits = [];
    // eslint-disable-next-line eqeqeq
    if (name != trail.name) {
      allEdits.push(this.getNameEdit());
    }
    let seasonStartUpdate;
    let seasonEndUpdate;
    seasonStartUpdate = parseInt(seasonStart);
    if (isNaN(seasonStartUpdate)) {
      seasonStartUpdate = -1;
    }
    seasonEndUpdate = parseInt(seasonEnd);
    if (isNaN(seasonEndUpdate)) {
      seasonEndUpdate = -1;
    }

    // eslint-disable-next-line eqeqeq
    if (seasonStartUpdate != trail.defaultActivityStats.seasonStart) {
      allEdits.push({ type: 'season_start', data: { month: seasonStartUpdate } });
    }

    // eslint-disable-next-line eqeqeq
    if (seasonEndUpdate != trail.defaultActivityStats.seasonEnd) {
      allEdits.push({ type: 'season_end', data: { month: seasonEndUpdate } });
    }

    // eslint-disable-next-line eqeqeq
    if (keyPoints != this.getAdminNotes()) {
      allEdits.push({ type: 'trail_detail', data: { name: 'key_points', value: keyPoints } });
    }

    // eslint-disable-next-line eqeqeq
    if (description != this.getDescription()) {
      allEdits.push({ type: 'trail_detail', data: { name: 'description', value: description } });
    }

    // eslint-disable-next-line eqeqeq
    if (difficultyRating != this.convertDifficultyRating(trail.defaultActivityStats.difficulty)) {
      allEdits.push({ type: 'difficulty_rating', data: { difficultyRating } });
    }

    // eslint-disable-next-line eqeqeq
    if (visitorUsage != trail.defaultActivityStats.visitorUsage) {
      allEdits.push({ type: 'visitor_usage', data: { visitorUsage } });
    }

    if (routeType && trail.routeType == null) {
      allEdits.push({ type: 'route_type', data: { routeType } });
      // eslint-disable-next-line eqeqeq
    } else if (routeType != null && routeType != trail.routeType.uid) {
      allEdits.push({ type: 'route_type', data: { routeType } });
    }

    const activitiesEdits = this.getAttributesEdits(selectedActivities, originalActivities);
    const featuresEdits = this.getAttributesEdits(selectedFeatures, originalFeatures);

    const obstaclesEdits = this.getAttributesEdits(selectedObstacles, originalObstacles);
    const collectionMappingEdits = this.getCollectionMappingsEdits(collectionMappings, originalCollectionMappings);
    allEdits.push(...activitiesEdits, ...featuresEdits, ...obstaclesEdits, ...collectionMappingEdits);

    // Three cases: park chosen (areaId > 0), free-form chosen (areaId == -1), no park (else)
    const areaIdWas = trail.area ? trail.area.id : null;
    if (areaId > 0) {
      // eslint-disable-next-line eqeqeq
      if (areaId != areaIdWas) {
        allEdits.push({ type: 'area', relation_id: areaId, data: { comment: '' } });
      }
      // eslint-disable-next-line eqeqeq
    } else if (areaId == -1 || nearbyParks.length == 0) {
      if (this.state.freeFormArea) {
        // eslint-disable-next-line eqeqeq
        if (this.state.freeFormArea != '') {
          const data = {
            name: this.state.freeFormArea,
            latitude: location.latitude,
            longitude: location.longitude
          };
          // Use defaultMap location in preference to a default Kansas location
          // eslint-disable-next-line eqeqeq
          if (data.latitude == CENTER_LOWER_48[0] && data.longitude == CENTER_LOWER_48[1] && defaultMap?.location) {
            data.latitude = +defaultMap.location.latitude;
            data.longitude = +defaultMap.location.longitude;
          }
          allEdits.push({
            type: 'new_area',
            data
          });
        }
      }
    } else if (areaIdWas) {
      allEdits.push({ type: 'area', relation_id: null, data: { comment: 'area_id null' } });
    }

    if (!isPendingTrail || trail.canEdit) {
      // Four cases: City chosen (cityId > 0); Free-form chosen (cityId == -1 or no nearby cities found);
      // Admin-only "clear and regeocode" (cityId == -2); Else--should not occur
      const cityIdWas = trail.location && trail.location.city_id ? trail.location.city_id : null;
      if (cityId > 0) {
        // eslint-disable-next-line eqeqeq
        if (cityId != cityIdWas) {
          allEdits.push({ type: 'city_id', relation_id: cityId, data: { comment: '' } });
        }
        // eslint-disable-next-line eqeqeq
      } else if (cityId == -2) {
        allEdits.push({ type: 'city_id', relation_id: null, data: { comment: '' } });
        // eslint-disable-next-line eqeqeq
      } else if (cityId == -1 || nearbyCities.length == 0) {
        if (this.state.freeFormCity) {
          const regionAndCountry = regionAndCountryRef.getSelectedValues();
          const regionId = regionAndCountry.regionId === null ? 0 : regionAndCountry.regionId;
          // eslint-disable-next-line eqeqeq
          if (this.state.freeFormCity != '') {
            allEdits.push({
              type: 'new_city',
              data: {
                name: this.state.freeFormCity,
                latitude: location.latitude,
                longitude: location.longitude,
                region_id: regionId,
                country_id: regionAndCountry.countryId
              }
            });
          }
        }
      }
    }

    // Verified Route (if drawn)
    const editData = verifiedRouteContributeRef.getNewEditData();
    if (editData) {
      allEdits.push(editData);
    }

    return allEdits;
  },
  // eslint-disable-next-line react/no-unused-class-component-methods
  getNameValue() {
    return this.state.name;
  },
  // eslint-disable-next-line react/no-unused-class-component-methods
  getRouteTypeValue() {
    return this.state.routeType;
  },
  // eslint-disable-next-line react/no-unused-class-component-methods, no-unused-vars
  metersToMiles(length, inverse) {
    return Math.round(length * METERS_TO_MILES * 100) / 100;
  },
  metersToFormattedUserUnits(length) {
    const { displayMetric, languageRegionCode } = this.props;
    return MapStatsUtil.metersToFormattedUserUnits(length, 2, displayMetric, languageRegionCode);
  },
  handleParkResults(content) {
    const { areaId, areaText } = this.state;

    const parks = [];
    let areaInResults = false;
    content.hits.forEach(park => {
      parks.push([park.ID, `${park.name} (${this.metersToFormattedUserUnits(park._rankingInfo.geoDistance)})`]);
      // eslint-disable-next-line eqeqeq
      if (areaId && park.ID == areaId) {
        areaInResults = true;
      }
    });

    // If our current park is not in list returned by Algolia, append it now:
    // eslint-disable-next-line eqeqeq
    if (areaId && areaId != -1 && !areaInResults) {
      parks.push([areaId, areaText]);
    }

    this.setState({ nearbyParks: parks });
  },
  // eslint-disable-next-line react/no-unused-class-component-methods, react/no-unstable-nested-components
  createAreaOption(park) {
    return <option value={park[0]}>{park[1]}</option>;
  },
  handleCityResults(content) {
    const { cityId, cityText, cityLocation } = this.state;

    const cities = [];
    let cityInResults = false;
    content.hits.forEach(city => {
      // eslint-disable-next-line eqeqeq
      if (city.objectID.substring(0, 6) != 'cityo-') {
        return;
      }

      cities.push([city.ID, `${city.name} (${this.metersToFormattedUserUnits(city._rankingInfo.geoDistance)})`, city.state_name]);
      // eslint-disable-next-line eqeqeq
      if (cityId && city.ID == cityId) {
        cityInResults = true;
      }
    });

    // If our current city is not in list returned by Algolia, append it now:
    if (cityId && cityId > 0 && !cityInResults) {
      cities.push([cityId, cityText, cityLocation]);
    }

    this.setState({ nearbyCities: cities });
  },
  // eslint-disable-next-line react/no-unstable-nested-components
  createCityOption(city) {
    return {
      label: city[1],
      value: city[0]
    };
  },
  convertDifficultyRating(dr) {
    if (!dr) return null;
    if (dr < 3) return 1;
    if (dr < 5) return 3;
    if (dr < 7) return 5;
    if (dr < 9) return 7;
    return null;
  },
  handleCoordinatesSelected(coordResult) {
    const { messagingChannel } = this.props;
    const { latitude, longitude } = coordResult;
    messagingChannel.publish('edit.change_lat_lng', [latitude, longitude, 12]);
    this.algoliaParksAndCityQueryLatLng(latitude, longitude);
  },
  handleObjectSelected(obj) {
    const { messagingChannel, mapboxAccessToken } = this.props;

    let objName;
    let zoom = 12;
    let areaId = null;
    if (obj.objectID.indexOf('city') >= 0) {
      // eslint-disable-next-line prefer-destructuring
      objName = obj.name[1];
    } else if (obj.objectID.indexOf('area') >= 0) {
      objName = obj.name;
      areaId = obj.ID;
    } else {
      objName = obj.name;
    }
    if (obj.objectID.indexOf('state') >= 0) {
      zoom = 8;
    }

    // If we have a geolocation for the object use it
    // Otherwise use the calculated name to geocode the location
    if (obj._geoloc != null) {
      messagingChannel.publish('edit.change_lat_lng', [obj._geoloc.lat, obj._geoloc.lng, zoom]);
      if (areaId) {
        this.setState({ areaId, areaText: objName });
      } else {
        // clear any selected park if we are being taken to a new Place
        this.setState({ areaId: null, areaText: null });
      }
      this.algoliaParksAndCityQueryLatLng(obj._geoloc.lat, obj._geoloc.lng);
    } else {
      if (!areaId) {
        this.setState({ areaId: null, areaText: null });
      }
      // eslint-disable-next-line no-undef
      $.ajax({
        url: `https://api.mapbox.com/v4/geocode/mapbox.places/${objName}.json?access_token=${mapboxAccessToken}`,
        success: data => {
          const [lng, lat] = data.features[0].center;
          messagingChannel.publish('edit.change_lat_lng', [lat, lng, zoom]);
          this.algoliaParksAndCityQueryLatLng(lat, lng);
        }
      });
    }
  },
  handleTrailNameBlur() {
    const { languageRegionCode } = this.props;
    const { name } = this.state;

    if ((languageRegionCode === 'en-US' || languageRegionCode === 'en-GB') && name.indexOf('Trial') > -1) {
      // Don't translate this! It doesn't make sense for non-English languages
      alert('This trail name contains the word "Trial" instead of the word "Trail". Please ignore this message if this was intentional.');
    }
    return true;
  },
  handleTrailNameChange(value) {
    this.setState({ name: value });
  },
  renderNameInput() {
    const {
      intl,
      allowNameEditing,
      trail: { id }
    } = this.props;
    const { formatMessage } = intl;
    const { name } = this.state;

    if (allowNameEditing) {
      return (
        <div className={classNames(styles.columnSection, styles.topMargin)}>
          <TextInput
            labelText={`${formatMessage({ defaultMessage: 'Name' })} (${this.getTrailNameLength()})`}
            testId="nameContribute"
            value={name}
            size="sm"
            characterLimit={150}
            onChange={value => this.handleTrailNameChange(value)}
            onBlur={this.handleTrailNameBlur}
          />
        </div>
      );
    }
    return (
      <div className={styles.spaceBetween}>
        <div className={styles.columnSection}>
          <label className={styles.labelText}> {formatMessage({ defaultMessage: 'Name' })} </label>
          <div className={styles.text} data-test-id="nameContribute">
            {name}
          </div>
        </div>
        <IconButton
          icon={{ Component: Edit }}
          title={formatMessage({ defaultMessage: 'Edit name' })}
          linkInfo={{ href: `/edit-translations?table=trails&column_name=name&id=${id}`, target: '_blank' }}
          testId="edit-name"
          variant="flat"
          size="sm"
          className={styles.editIcon}
        />
      </div>
    );
  },
  handleUploadToggle() {
    this.setState({ uploadFormVisible: !this.state.uploadFormVisible });
  },
  handleUploadCancelClick() {
    this.setState({ uploadFormVisible: false });
  },
  getTrailNameLength() {
    const { name } = this.state;

    if (!name) {
      return 0;
    }

    return name.replace(/\s*\[CLOSED\]\s?|\s*\[PRIVATE PROPERTY\]\s?|\s*\[CLOSED PRIVATE PROPERTY\]\s?|/g, '').length;
  },
  handleUploadSuccess(newMap) {
    this.setState({ uploadFormVisible: false }, () => {
      this.props.handleMapChange(newMap);
    });
  },
  handleAreaSelect(area) {
    const areaDistanceTo = turfDistance(pointItemToLngLat(this.state), pointItemToLngLat(area)) * 1000;
    const areaText = `${area.name} (${this.metersToFormattedUserUnits(areaDistanceTo)})`;
    this.setState({ areaId: area.ID, areaText });
  },
  enableNewParkClick() {
    this.setState({ hideResults: true, areaId: -1, areaText: undefined });
  },
  clearSelectedPark() {
    this.setState({ areaId: undefined, areaText: undefined });
  },
  renderTextAreaInput(title, dataTestId, defaultValue, handleChange = null) {
    const adminNotesPresent = this.props.trail?.trailDetail?.key_points;
    return (
      <div className={classNames(styles.columnSection, { [styles.topMargin]: !adminNotesPresent || dataTestId === 'Description' })}>
        <TextArea labelText={title} onChange={handleChange} value={defaultValue} testId={`${dataTestId}-value`} rows={3} size="sm" resizable />
      </div>
    );
  },
  getAdminNotes() {
    const { trail } = this.props;

    let keyPoints = '';
    if (trail?.trailDetail && trail?.trailDetail.key_points !== null) {
      keyPoints = trail.trailDetail.key_points;
    }
    return keyPoints;
  },
  renderAdminNotesSection() {
    const { keyPoints } = this.state;
    if (!hasPermission({ permission: 'trails:manage' })) {
      return null;
    }
    const handleChange = value => {
      this.setState({ keyPoints: value });
    };
    return this.renderTextAreaInput('Admin Notes', 'AdminNotes', keyPoints, handleChange);
  },
  getDescription() {
    const { trail } = this.props;

    let description = '';
    if (trail?.trailDetail && trail?.trailDetail.description !== null) {
      const { trailDetail } = trail;
      // Note that we intentionally filter out undefined but not null
      // null indicates the field exists on the trail but is currently empty
      // and should be considered present for the purposes of chosing which field to display
      description = [
        trailDetail.description_source_without_alerts,
        trailDetail.description_without_alerts,
        trailDetail.description_source,
        trailDetail.description,
        description
      ].find(value => ![undefined].includes(value));
    }
    return description;
  },
  renderDescriptionSection() {
    const {
      intl: { formatMessage },
      trail: { canEdit },
      hasObjectPermissions
    } = this.props;
    const { description } = this.state;
    const canEditDescription =
      hasPermission({ permission: 'trails:manage' }) || hasPermission({ permission: 'public_lands:manage' }) || hasObjectPermissions;

    if (!canEditDescription && !canEdit) {
      return null;
    }
    const length = description?.length || 0;
    const title = `${formatMessage({ defaultMessage: 'Description' })} (${length})`;
    const handleChange = value => {
      this.setState({ description: value });
    };

    return this.renderTextAreaInput(title, 'Description', description, handleChange);
  },
  renderMonthOptions(placeholderLabel) {
    const {
      intl: { formatMessage }
    } = this.props;

    const emptyOption = {
      label: `-- ${placeholderLabel} --`,
      value: ''
    };

    const options = Object.keys(monthMessages).map((messageKey, index) => ({
      label: formatMessage(monthMessages[messageKey]),
      value: index + 1
    }));

    options.unshift(emptyOption);
    return options;
  },
  toggleShowAllSecondaryParks() {
    this.setState(prevState => ({
      showAllSecondaryParks: !prevState.showAllSecondaryParks
    }));
  },
  renderParkEditField() {
    const { intl, languageRegionCode, mapCenter } = this.props;
    const { formatMessage } = intl;
    const { areaId, areaText, nearbyParks, location, secondaryAreas } = this.state;

    // null input
    let nlInput;
    // eslint-disable-next-line eqeqeq
    if (areaId == -1 || nearbyParks.length == 0) {
      nlInput = (
        <TextInput
          labelText=""
          placeholder={formatMessage({ defaultMessage: 'Enter a new park name' })}
          testId="area-free-form-input"
          value={this.state.freeFormArea}
          size="sm"
          onChange={value => this.handleAreaFreeFormChange(value)}
        />
      );
    }
    // clear button
    let clearBtn;
    if (areaId) {
      clearBtn = (
        <IconButton
          icon={{ Component: Remove }}
          title={formatMessage({ defaultMessage: 'Clear' })}
          onClick={this.clearSelectedPark}
          testId="remove-park"
          variant="flat"
          size="sm"
        />
      );
    }
    // lat/lng
    let defaultLatLng = [location.latitude, location.longitude];
    let weightResults = false; // if a trail head has been selected, order parks by proximity (not popularity)
    // use center of map if the defaultLatLng is set to center coordinates of U.S. (aka hasn't been set to any value yet)
    // eslint-disable-next-line eqeqeq
    if (defaultLatLng && defaultLatLng[0] == CENTER_LOWER_48[0] && defaultLatLng[1] == CENTER_LOWER_48[1] && mapCenter) {
      defaultLatLng = mapCenter;
      weightResults = true;
    }
    let primaryParkSection;
    let editBtn;
    if (areaId && hasPermission({ permission: 'areas:manage' })) {
      const parkLink = `/merge-locations?area_id=${areaId}`;
      editBtn = (
        <IconButton
          icon={{ Component: Edit }}
          title={formatMessage({ defaultMessage: 'Edit' })}
          linkInfo={{ href: parkLink, target: '_blank' }}
          testId="edit-park"
          variant="flat"
          size="sm"
        />
      );
    }
    if (areaText || clearBtn) {
      primaryParkSection = (
        <div>
          <div className={classNames(styles.boldTextSmall, styles.labelMargin)}>
            <FormattedMessage defaultMessage="Manual" />
          </div>
          <div className={classNames(styles.spaceBetween, styles.smallMarginTop)}>
            <span className={styles.text}>
              {areaText}
              {hasPermission({ permission: 'trails:manage' }) && this.props.trail?.area?.hasBoundary && areaText && (
                <span className={styles.polygonIcon}>
                  <Polygon size="sm" />
                </span>
              )}
            </span>
            {areaText && (
              <div className={styles.parkButtons}>
                {clearBtn}
                {editBtn}
              </div>
            )}
          </div>
        </div>
      );
    }

    const renderSecondaryAreas = () => {
      const { showAllSecondaryParks } = this.state;
      const areasToShow = showAllSecondaryParks ? secondaryAreas : secondaryAreas?.slice(0, 2);
      return areasToShow?.map(secondaryArea => (
        <div key={`${secondaryArea}}`} className={styles.columnSection}>
          <span className={classNames(styles.text, styles.smallSpacing)}> {secondaryArea} </span>
        </div>
      ));
    };

    const renderShowMoreLink = () => (
      <Link className={styles.showMore} onClick={this.toggleShowAllSecondaryParks} size="sm" testId="show-more">
        {this.state.showAllSecondaryParks ? <FormattedMessage defaultMessage="Show less" /> : <FormattedMessage defaultMessage="Show more" />}
      </Link>
    );

    const areaDistance = area => {
      const areaDistanceTo = turfDistance(pointItemToLngLat(this.props.trail), pointItemToLngLat(area)) * 1000;
      return this.metersToFormattedUserUnits(areaDistanceTo);
    };
    const addNewParkLink = (
      <Link onClick={this.enableNewParkClick} size="md" icon={{ Component: Add }}>
        <FormattedMessage defaultMessage="Add new park" />
      </Link>
    );
    // park algolia search box selection
    const parkSelection = (
      <div className={classNames(styles.columnSection, styles.topMargin)}>
        <AlgoliaSearchBox
          configs={global.__AT_DATA__.algoliaConfig}
          languageRegionCode={languageRegionCode}
          placeholder={formatMessage({ defaultMessage: 'Select a park' })}
          searchTypes={['area']}
          size="sm"
          testId="park-search-box"
          user={this.props.context.currentUser}
          onResultSelect={this.handleAreaSelect}
          dropdownVariant="inline"
          searchOnEmptyQuery
          additionalButton={hasPermission({ permission: 'areas:manage' }) ? addNewParkLink : null}
          hideResults={this.state.hideResults}
          getAreaDistance={result => areaDistance(result)}
          searchLatLng={defaultLatLng}
          weightResults={weightResults}
          defaultRadiusMeters={DEFAULT_RADIUS_METERS}
          searchOrigin={PARK_SELECTION}
          className={styles.parkSearchBox}
        />
        <div className={styles.columnSection}>
          {primaryParkSection}
          {nlInput}

          {hasPermission({ permission: 'trails:manage' }) && this.props.trail?.associatedAreas?.length > 0 && (
            <div>
              <div className={classNames(styles.boldTextSmall, styles.labelMargin)}>
                <FormattedMessage defaultMessage="Programmatic" />
              </div>
              {renderSecondaryAreas()}
              {secondaryAreas?.length > 2 && renderShowMoreLink()}
            </div>
          )}
        </div>
      </div>
    );
    return (
      <div className={classNames(styles.columnSection, styles.topMargin)}>
        <div className={styles.spaceBetween}>
          <div className={styles.title}>
            <FormattedMessage defaultMessage="Park" />
          </div>
        </div>
        {parkSelection}
      </div>
    );
  },
  renderUploadModal() {
    const {
      context: { currentUser }
    } = this.props;

    return (
      <ActivityUploadModal
        closeModal={this.handleUploadCancelClick}
        handleRouteUpload={this.handleUploadSuccess}
        user={currentUser}
        routeMode
        createAsMap
        systemOwned
      />
    );
  },

  render() {
    const {
      intl,
      languageRegionCode,
      displayMetric,
      hasAdminFeatures,
      trail,
      routeInfo,
      insertRouteInfo,
      defaultMap,
      isPendingTrail,
      rapidRoute,
      messagingChannel,
      allActivities,
      allFeatures,
      allObstacles,
      allDogs,
      allAccessibility,
      allSurface,
      allKids,
      selectedActivities,
      selectedFeatures,
      selectedObstacles,
      setSelectedActivities,
      setSelectedFeatures,
      setSelectedObstacles,
      collectionMappings,
      originalCollectionMappings,
      setCollectionMappings,
      geocontributeLink
    } = this.props;
    const { formatMessage } = intl;
    const { id, trailDetail, trailGeoStats, canEdit, pctLowTrafficCells10, heatmapFlag, overlappingSegmentsFlag, sharpAnglesFlag } = trail;
    const mapCollectionsToChips = collections => collections?.map(({ collection }) => ({ id: collection.uid, label: collection.name })) ?? [];
    const collectionChips = mapCollectionsToChips(originalCollectionMappings);
    const selectedCollectionChips = mapCollectionsToChips(collectionMappings);

    const { cityId, cityLocation, geocodedCityText, nearbyCities, routeType, difficultyRating, visitorUsage, uploadFormVisible } = this.state;

    // looking for plannerMap to avoid race condition where elevation is returned in route info when not editing
    const isEditing = routeInfo?.plannerMap || insertRouteInfo?.plannerMap;
    const shouldRenderUploadButton = !(hasAdminFeatures && isEditing);
    const shouldShowTrailRedrawStats = heatmapFlag && overlappingSegmentsFlag && sharpAnglesFlag && pctLowTrafficCells10;

    let cityAndRegionDiv = null;
    let lengthDiv = null;
    let elevationGainDiv = null;
    let routeInstructions = null;

    if (!isPendingTrail || canEdit) {
      const cityOptions = nearbyCities.map(this.createCityOption);
      if (nearbyCities.length > 0) cityOptions.unshift({ label: formatMessage({ defaultMessage: 'Select a city:' }), value: '' });
      cityOptions.push({ label: formatMessage({ defaultMessage: 'Add new city' }), value: '-1' });
      if (canEdit) cityOptions.push({ label: 'Clear and regeocode city', value: '-2' });
      let regionCountryDiv;
      // eslint-disable-next-line eqeqeq
      if (cityId == -2) {
        regionCountryDiv = (
          <p className={classNames(styles.boldTextSmall, 'xlate-none')}>
            <FormattedMessage defaultMessage="Trailhead's location:" /> {geocodedCityText}
          </p>
        );
        // eslint-disable-next-line eqeqeq
      } else if (cityId == -1 || nearbyCities.length == 0) {
        regionCountryDiv = (
          <div>
            <TextInput
              labelText={formatMessage({ defaultMessage: 'City' })}
              testId="city-free-form-input"
              value={this.state.freeFormCity}
              size="sm"
              onChange={value => this.handleCityFreeFormChange(value)}
            />
            <div className={classNames(styles.columnSection, styles.topMargin)}>
              <RegionAndCountrySelect
                ref="regionAndCountry"
                selectedRegion={this.state.selectedRegion}
                selectedCountry={this.state.selectedCountry}
                setSelectedRegion={region => this.setState({ selectedRegion: region })}
                setSelectedCountry={country => this.setState({ selectedCountry: country })}
                intl={intl}
              />
            </div>
          </div>
        );
      } else {
        regionCountryDiv = (
          <div className={classNames(styles.columnSection, styles.topMargin)}>
            <div className={styles.labelText}>{formatMessage({ defaultMessage: 'Region' })}</div>
            <div className={classNames(styles.spaceBetween, styles.gap)}>
              <div className={styles.text}>{cityLocation}</div>
              {hasPermission({ permission: 'locations:manage' }) && cityId && (
                <IconButton
                  icon={{ Component: Edit }}
                  title={formatMessage({ defaultMessage: 'Edit region' })}
                  linkInfo={{ href: `/merge-locations?state_of_city_id=${cityId}`, target: '_blank' }}
                  testId="edit-region"
                  variant="flat"
                  size="sm"
                />
              )}
            </div>
          </div>
        );
      }
      cityAndRegionDiv = (
        <div>
          <div className={classNames(styles.columnSection, styles.topMargin)}>
            <div className={classNames(styles.spaceBetween, styles.gap)}>
              <Select
                labelText={formatMessage({ defaultMessage: 'City' })}
                onChange={selectedValue => this.handleCityChange(selectedValue)}
                options={cityOptions}
                testId="city-select"
                value={cityId || ''}
                className={canEdit && cityId ? styles.widthInput : styles.fullWidth}
                size="sm"
              />
              {hasPermission({ permission: 'locations:manage' }) && cityId && (
                <IconButton
                  icon={{ Component: Edit }}
                  title={formatMessage({ defaultMessage: 'Edit city' })}
                  linkInfo={{ href: `/merge-locations?city_id=${cityId}`, target: '_blank' }}
                  testId="edit-city"
                  variant="flat"
                  size="sm"
                  className={styles.editIcon}
                />
              )}
            </div>
            {regionCountryDiv}
          </div>
        </div>
      );

      lengthDiv = (
        <div className={classNames(styles.columnSection, styles.topMargin)}>
          <label className={styles.labelText}> {formatMessage({ defaultMessage: 'Distance' })} </label>
          <div className={styles.text}>
            {displayMetric ? `${(trailGeoStats.length / 1000.0).toFixed(2) || 0} km` : `${MapStatsUtil.metersToMiles(trailGeoStats.length)} mi`}
          </div>
        </div>
      );

      elevationGainDiv = (
        <div className={classNames(styles.columnSection, styles.topMargin)}>
          <label className={styles.labelText}> {formatMessage({ defaultMessage: 'Elevation gain' })} </label>
          <div className={styles.text}>
            {displayMetric
              ? `${trailGeoStats.elevationGain.toFixed(2)} m`
              : `${MapStatsUtil.metersToFeet(trailGeoStats.elevationGain).toFixed(2) || 0} ft`}
          </div>
        </div>
      );
    } else {
      routeInstructions = (
        <div className={classNames(styles.text, styles.marginBottom)}>
          <FormattedMessage defaultMessage="When adding new trails, we are specifically looking for trails that start and end at a parking area and are on public property. Our strong preference is for out-and-back or loop trails that begin and end in the same location, but of course some trails are through trails and they are welcome too. Please note that any suggested trail additions must first be evaluated by our moderators. This process may take a while depending on our ability to verify the information. If you have a recorded GPX file for the trail you can 'upload' that instead." />
        </div>
      );
    }

    let step_titles;
    let searchDividerDiv = null;

    const uploadDiv = (
      <div>
        <div className={classNames(styles.columnSection, styles.topMargin)}>
          <span className={styles.buttonLabelText}>
            <FormattedMessage defaultMessage="Upload route" />
          </span>
          <div className={styles.buttons}>
            <Button
              text={formatMessage({ defaultMessage: 'Upload route' })}
              onClick={this.handleUploadToggle}
              testId="uploadButtonContribute"
              type="button"
              variant="default"
              size="sm"
            />
          </div>
          <PortalModal isOpen={uploadFormVisible}>{this.renderUploadModal()}</PortalModal>
        </div>
      </div>
    );

    if (id) {
      step_titles = [
        null,
        `${formatMessage({
          defaultMessage: 'Suggest a better route'
        })}`,
        `${formatMessage({
          defaultMessage: 'Edit trail information'
        })}`,
        `${formatMessage({
          defaultMessage: 'Route details'
        })}`,
        `${formatMessage({
          defaultMessage: 'Waypoints'
        })}`
      ];
    } else {
      step_titles = [
        `${formatMessage({
          defaultMessage: 'Find the starting location'
        })}`,
        `${formatMessage({
          defaultMessage: 'Draw/Upload the route'
        })}`,
        `${formatMessage({
          defaultMessage: 'Edit trail information'
        })}`,
        `${formatMessage({
          defaultMessage: 'Route details'
        })}`
      ];
      searchDividerDiv = (
        <Expandable
          title={step_titles[0]}
          className={styles.section}
          externalControls={{
            toggleIsOpen: () => this.toggleExpandState('findStartingLocation', !this.state.expandState.findStartingLocation),
            isOpen: this.state.expandState.findStartingLocation
          }}
          titleSize="md"
        >
          <div className={classNames(styles.columnSection, styles.topMargin)}>
            <AlgoliaSearchBox
              configs={global.__AT_DATA__.algoliaConfig}
              languageRegionCode={languageRegionCode}
              onResultSelect={this.handleObjectSelected}
              onCoordinatesSelect={this.handleCoordinatesSelected}
              searchTypes={['area', 'place']}
              searchOnEmptyQuery
              placeholder={formatMessage({ defaultMessage: 'Enter a city, park, or lat,long...' })}
              dropdownVariant="inline"
              user={this.props.context.currentUser}
              size="sm"
              defaultRadiusMeters={DEFAULT_RADIUS_METERS}
              testId="city-park-search-box"
              searchOrigin={TRAIL_DETAILS}
            />
          </div>
          {geocontributeLink && <div className={styles.topMargin}>{geocontributeLink}</div>}
        </Expandable>
      );
    }

    const petzoldtDifficultyDiv = trail.petzoldtDifficultyRating ? (
      <div className={classNames(styles.columnSection, styles.topMargin)}>
        <div className={styles.labelText}>
          <FormattedMessage defaultMessage="Petzoldt difficulty" />
        </div>
        <div className={styles.boldTextSmall}>{formatMessage(difficultyMessages[trail.petzoldtDifficultyRating])}</div>
      </div>
    ) : null;

    const adminNotesPresent = trailDetail?.key_points;

    const updateCollectionMappings = selectedCollection => {
      let removedCollection = null;
      const newCollectionMappings = collectionMappings.reduce((acc, current) => {
        if (current.collection.uid === selectedCollection.id) {
          removedCollection = current.collection;
          return acc;
        }
        return [...acc, current];
      }, []);

      if (!removedCollection) {
        const newCollectionMapping = originalCollectionMappings.find(({ collection }) => collection.uid === selectedCollection.id);
        setCollectionMappings([...newCollectionMappings, newCollectionMapping]);
      } else {
        this.setState({ lastRemovedCollection: { ...selectedCollection, tagAssociation: removedCollection.tagAssociation } });
        setCollectionMappings(newCollectionMappings);
      }
    };

    const updateSelectedFeatures = features => {
      const selectedFeatureKeys = Object.keys(selectedFeatures);
      const featureKeys = Object.keys(features);

      if (featureKeys.length > selectedFeatureKeys.length) {
        setSelectedFeatures(features);
        this.setState({ lastRemovedFeature: null, lastAddedFeatureKey: null });
      } else {
        const lastRemovedFeatureKey = selectedFeatureKeys.find(key => !featureKeys.includes(key));
        const lastAddedFeatureKey = featureKeys.find(key => !selectedFeatureKeys.includes(key));
        const lastRemovedFeature = { [lastRemovedFeatureKey]: selectedFeatures[lastRemovedFeatureKey] };

        this.setState({ lastRemovedFeature, lastAddedFeatureKey });
        setSelectedFeatures(features);
      }
    };

    const revertSelectedFeatures = () => {
      const selectedFeaturesCopy = { ...selectedFeatures };
      if (this.state.lastAddedFeatureKey) {
        delete selectedFeaturesCopy[this.state.lastAddedFeatureKey];
      }

      setSelectedFeatures({ ...selectedFeaturesCopy, ...this.state.lastRemovedFeature });
      this.setState({ lastRemovedFeature: null, lastAddedFeatureKey: null });
    };

    return (
      <div id="trail-details-box" className="trail-details">
        {collectionMappings && collectionMappings.length > 0 && (
          <>
            <FeaturesAlertDialog
              selectedCollectionMappings={collectionMappings.map(c => c.collection)}
              lastRemovedFeature={this.state.lastRemovedFeature}
              updateCollectionMappings={updateCollectionMappings}
              setSelectedFeatures={revertSelectedFeatures}
            />
            <CollectionsAlertDialog
              selectedFeatures={selectedFeatures}
              lastRemovedCollection={this.state.lastRemovedCollection}
              updateCollectionMappings={() => updateCollectionMappings(this.state.lastRemovedCollection)}
              setSelectedFeatures={setSelectedFeatures}
            />
          </>
        )}
        <div className={styles.container}>
          {searchDividerDiv}
          <div className={styles.divider}>
            <Expandable
              title={step_titles[1]}
              className={styles.section}
              externalControls={{
                toggleIsOpen: () => this.toggleExpandState('suggestABetterRoute', !this.state.expandState.suggestABetterRoute),
                isOpen: this.state.expandState.suggestABetterRoute
              }}
              titleSize="md"
            >
              <div className={styles.columnSection}>
                {routeInstructions}
                <span className={styles.buttonLabelText}>
                  <FormattedMessage defaultMessage="Draw route" />
                </span>
                <VerifiedRouteContributeItem
                  displayMetric={displayMetric}
                  hasAdminFeatures={hasAdminFeatures}
                  forTrailDetails
                  mapModel={defaultMap}
                  messagingChannel={messagingChannel}
                  rapidRoute={rapidRoute}
                  ref="verifiedRouteContribute"
                  routeInfo={routeInfo}
                  insertRouteInfo={insertRouteInfo}
                  trail={trail}
                  intl={intl}
                />
              </div>
              {shouldRenderUploadButton && uploadDiv}
              {shouldShowTrailRedrawStats && (
                <TrailRedrawStats
                  pctLowTrafficCells10={pctLowTrafficCells10}
                  heatmapFlag={heatmapFlag === 'true'}
                  overlappingSegmentsFlag={overlappingSegmentsFlag === 'true'}
                  sharpAnglesFlag={sharpAnglesFlag === 'true'}
                />
              )}
            </Expandable>
          </div>
          <div className={styles.divider}>
            <Expandable
              title={step_titles[2]}
              className={styles.section}
              externalControls={{
                toggleIsOpen: () => this.toggleExpandState('editTrailInfo', !this.state.expandState.editTrailInfo),
                isOpen: this.state.expandState.editTrailInfo
              }}
              titleSize="md"
            >
              {adminNotesPresent && <div className={styles.marginBottom}> {this.renderAdminNotesSection()}</div>}
              <div>{this.renderNameInput()}</div>
              {this.renderParkEditField()}
              {cityAndRegionDiv}
              {lengthDiv}
              {elevationGainDiv}
            </Expandable>
          </div>
          <div className={styles.divider}>
            <Expandable
              title={step_titles[3]}
              className={styles.section}
              externalControls={{
                toggleIsOpen: () => this.toggleExpandState('routeDetails', !this.state.expandState.routeDetails),
                isOpen: this.state.expandState.routeDetails
              }}
              titleSize="md"
            >
              <TagSection
                uniqueId="route-type-tags"
                title={formatMessage({ defaultMessage: 'Route type' })}
                chips={Object.entries(routeTypeToMessage).map(([routeType, message]) => ({ id: routeType, label: formatMessage(message) }))}
                selectedChips={routeType ? [{ id: routeType, label: formatMessage(routeTypeToMessage[routeType]) }] : []}
                onChange={selectedChip => this.setState({ routeType: selectedChip.id })}
              />
              <div className={styles.topMargin}>
                <TagSection
                  uniqueId="difficulty-tags"
                  title={formatMessage({ defaultMessage: 'Difficulty' })}
                  chips={Object.entries(difficultyToMessage).map(([difficulty, message]) => ({
                    id: parseInt(difficulty),
                    label: formatMessage(message)
                  }))}
                  selectedChips={difficultyRating ? [{ id: difficultyRating, label: formatMessage(difficultyToMessage[difficultyRating]) }] : []}
                  onChange={selectedChip => this.setState({ difficultyRating: selectedChip.id })}
                />
              </div>
              {petzoldtDifficultyDiv}
              <div className={styles.topMargin}>
                <TagSection
                  uniqueId="usage-tags"
                  title={formatMessage({ defaultMessage: 'Usage' })}
                  chips={Object.entries(usageToMessage).map(([usage, message]) => ({ id: parseInt(usage), label: formatMessage(message) }))}
                  selectedChips={visitorUsage ? [{ id: visitorUsage, label: formatMessage(usageToMessage[visitorUsage]) }] : []}
                  onChange={selectedChip => this.setState({ visitorUsage: selectedChip.id })}
                />
              </div>
              <div className={classNames(styles.columnSection, styles.topMargin)}>
                <div className={styles.labelText}>
                  <FormattedMessage defaultMessage="Season" />
                </div>
                <div className={styles.selectContainer}>
                  <Select
                    labelText=""
                    onChange={value => this.handleSeasonStartDateChange(value)}
                    options={this.renderMonthOptions(formatMessage({ defaultMessage: 'Start' }))}
                    testId="start-season-select"
                    size="sm"
                    value={this.state.seasonStart || `-- ${formatMessage({ defaultMessage: 'Start' })} --`}
                    className={styles.selectInput}
                  />
                  <Select
                    labelText=""
                    onChange={value => this.handleSeasonEndDateChange(value)}
                    options={this.renderMonthOptions(formatMessage({ defaultMessage: 'End' }))}
                    testId="start-season-select"
                    size="sm"
                    value={this.state.seasonEnd || `-- ${formatMessage({ defaultMessage: 'End' })} --`}
                    className={styles.selectInput}
                  />
                </div>
              </div>{' '}
              <div className={styles.topMargin}>
                <AttributesTagSection
                  uniqueId="activity-tags"
                  title={formatMessage({ defaultMessage: 'Activities' })}
                  tags={allActivities}
                  selectedTags={selectedActivities}
                  trailTags={trail.trailTags}
                  setSelectedTags={setSelectedActivities}
                  isMultiSelect
                />
              </div>
              <div className={styles.topMargin}>
                <AttributesTagSection
                  uniqueId="feature-tags"
                  title={formatMessage({ defaultMessage: 'Features' })}
                  tags={allFeatures}
                  selectedTags={selectedFeatures}
                  trailTags={trail.trailTags}
                  setSelectedTags={updateSelectedFeatures}
                  isMultiSelect
                />
              </div>
              <div className={styles.topMargin}>
                <AttributesTagSection
                  uniqueId="obstacle-tags"
                  title={formatMessage({ defaultMessage: 'Obstacles' })}
                  tags={allObstacles}
                  selectedTags={selectedObstacles}
                  setSelectedTags={setSelectedObstacles}
                  trailTags={trail.trailTags}
                  isMultiSelect
                />
              </div>
              <div className={styles.topMargin}>
                <AttributesTagSection
                  uniqueId="dogs-tags"
                  title={formatMessage({ defaultMessage: 'Dogs' })}
                  tags={allDogs}
                  selectedTags={selectedFeatures}
                  setSelectedTags={updateSelectedFeatures}
                  trailTags={trail.trailTags}
                  includeNone
                />
              </div>
              <div className={styles.topMargin}>
                <AttributesTagSection
                  uniqueId="accessibility-tags"
                  title={formatMessage({ defaultMessage: 'Accessible' })}
                  tags={allAccessibility}
                  selectedTags={selectedFeatures}
                  setSelectedTags={setSelectedFeatures}
                  trailTags={trail.trailTags}
                  isMultiSelect
                />
              </div>
              <div className={styles.topMargin}>
                <AttributesTagSection
                  uniqueId="surface-tags"
                  title={formatMessage({ defaultMessage: 'Surface' })}
                  tags={allSurface}
                  selectedTags={selectedFeatures}
                  setSelectedTags={setSelectedFeatures}
                  trailTags={trail.trailTags}
                  includeNone
                />
              </div>
              <div className={styles.topMargin}>
                <AttributesTagSection
                  uniqueId="kids-tags"
                  title={formatMessage({ defaultMessage: 'Kids' })}
                  tags={allKids}
                  selectedTags={selectedFeatures}
                  setSelectedTags={updateSelectedFeatures}
                  trailTags={trail.trailTags}
                  isMultiSelect
                />
              </div>
              {hasPermission({ permission: 'trails:manage' }) && (
                <div className={styles.topMargin}>
                  <TagSection
                    uniqueId="collection-tags"
                    title={formatMessage({ defaultMessage: 'Collections' })}
                    chips={collectionChips}
                    selectedChips={selectedCollectionChips}
                    onChange={updateCollectionMappings}
                    isMultiSelect
                  />
                </div>
              )}
              {this.renderDescriptionSection()}
              {!adminNotesPresent && this.renderAdminNotesSection()}
            </Expandable>
          </div>
          {this.props.waypoints && (
            <div className={styles.divider}>
              <Expandable
                title={step_titles[4]}
                className={styles.section}
                externalControls={{
                  toggleIsOpen: () => this.toggleExpandState('waypoints', !this.state.expandState.waypoints),
                  isOpen: this.state.expandState.waypoints
                }}
                titleSize="md"
              >
                <WaypointsWrapper
                  canEdit={hasPermission({ permission: 'trails:manage' })}
                  mapId={this.props.waypointMapId}
                  trailId={trail.id}
                  waypoints={this.props.waypoints}
                  messagingChannel={messagingChannel}
                  refetchTrailProps={this.props.refetchTrailProps}
                  isLoading={this.props.areWaypointsLoading}
                />
              </Expandable>
            </div>
          )}
        </div>
      </div>
    );
  }
});

const TrailDetailsBoxIntlProvider = forwardRef((props, ref) => {
  const intl = useIntl();
  return <BaseTrailDetailsBox {...props} intl={intl} ref={ref} />;
});

const TrailDetailsBox = forwardRef((props, ref) => (
  <CustomProvider>
    <TrailDetailsBoxIntlProvider {...props} ref={ref} />
  </CustomProvider>
));

// eslint-disable-next-line import/prefer-default-export
export { TrailDetailsBox };
