import NewActivityCard from '@alltrails/shared/components/ActivityCard';
import MapCard from '@alltrails/shared/components/MapCard';
import Track from '@alltrails/shared/types/track';
import { useIntl } from '@alltrails/shared/react-intl';
import useLanguageRegionCode from '@alltrails/shared/hooks/useLanguageRegionCode';
import { getActivityFromTrack, getMapFromTrack } from 'utils/cardInfo';
import type { Context } from 'types/Context';
import useActivityDuration from 'hooks/useActivityDuration';
import useAdminCTAs, { ButtonConfig } from 'hooks/useAdminCTAs';
import CustomProvider from 'components/CustomProvider';
import hasPermission from 'utils/hasPermission';

type ActivityHoverCardProps = {
  track: Track;
  context: Context;
  handleCardClick: () => void;
  handleFavoriteClick: () => void;
  isFavorite?: boolean;
  handleMouseEnter?: () => void;
  handleMouseLeave?: () => void;
  bottomButtonsConfig?: ButtonConfig[];
};

const BaseActivityHoverCard = ({
  track,
  context,
  handleCardClick,
  handleFavoriteClick,
  isFavorite,
  handleMouseEnter,
  handleMouseLeave,
  bottomButtonsConfig
}: ActivityHoverCardProps): JSX.Element => {
  const intl = useIntl();
  const languageRegionCode = useLanguageRegionCode();
  const minutes = useActivityDuration(track);
  const adminCTAs = useAdminCTAs(bottomButtonsConfig, intl);

  const card =
    track.type === 'map' ? (
      <MapCard
        languageRegionCode={languageRegionCode}
        onCardClick={handleCardClick}
        onCardMouseEnter={handleMouseEnter}
        onCardMouseLeave={handleMouseLeave}
        onFavoriteClick={handleFavoriteClick}
        isFavorite={isFavorite}
        variant="small"
        map={getMapFromTrack(track, minutes)}
        isFirstParty={context?.currentUser?.id === track.user?.id}
        useMetric={context?.displayMetric}
        adminCTAs={adminCTAs && adminCTAs.length ? adminCTAs : undefined}
      />
    ) : (
      <NewActivityCard
        languageRegionCode={languageRegionCode}
        onCardClick={handleCardClick}
        onCardMouseEnter={handleMouseEnter}
        onCardMouseLeave={handleMouseLeave}
        onFavoriteClick={handleFavoriteClick}
        isFavorite={isFavorite}
        variant="small"
        activity={getActivityFromTrack(track, minutes)}
        isFirstParty={context?.currentUser?.id === track.user?.id}
        useMetric={context?.displayMetric}
        adminCTAs={adminCTAs && adminCTAs.length ? adminCTAs : undefined}
        showActivityType={hasPermission({ permission: 'trails:manage' })}
      />
    );

  return card;
};

export default function ActivityHoverCard({
  track,
  context,
  handleCardClick,
  handleFavoriteClick,
  isFavorite,
  handleMouseEnter,
  handleMouseLeave,
  bottomButtonsConfig
}: ActivityHoverCardProps) {
  return (
    <CustomProvider>
      <BaseActivityHoverCard
        track={track}
        context={context}
        handleCardClick={handleCardClick}
        handleFavoriteClick={handleFavoriteClick}
        isFavorite={isFavorite}
        handleMouseEnter={handleMouseEnter}
        handleMouseLeave={handleMouseLeave}
        bottomButtonsConfig={bottomButtonsConfig}
      />
    </CustomProvider>
  );
}
