import NewActivityCard from '@alltrails/shared/components/ActivityCard';
import MapCard from '@alltrails/shared/components/MapCard';
import useLanguageRegionCode from '@alltrails/shared/hooks/useLanguageRegionCode';
import type { Context } from 'types/Context';
import Track from '@alltrails/shared/types/track';
import useActivityDuration from 'hooks/useActivityDuration';
import { getActivityFromTrack, getMapFromTrack } from 'utils/cardInfo';

type ActivityCardProps = {
  track: Track;
  context: Context;
  forceFirstParty?: boolean;
  handleCardClick: () => void;
  handleFavoriteClick: () => void;
  isFavorite?: boolean;
  messagingChannel?: {
    publish: (event: string, track: Track) => void;
  };
  allowItemMoving?: boolean;
  dragHandleProps?: { [key: string]: any };
  allowItemDelete?: boolean;
  handleItemDeleteClick?: () => void;
  itemDeleteLabel?: string;
  updateCurrentlyHoveredResult?: (map: Track) => void;
  maxPhotos?: number;
};

const ActivityCard = ({
  track,
  context,
  forceFirstParty,
  handleCardClick,
  handleFavoriteClick,
  isFavorite,
  messagingChannel,
  allowItemMoving,
  dragHandleProps,
  allowItemDelete,
  handleItemDeleteClick,
  itemDeleteLabel,
  updateCurrentlyHoveredResult,
  maxPhotos = 6
}: ActivityCardProps): JSX.Element => {
  const languageRegionCode = useLanguageRegionCode();
  const minutes = useActivityDuration(track);
  const deleteButton =
    allowItemDelete && handleItemDeleteClick && itemDeleteLabel
      ? {
          title: itemDeleteLabel,
          onClick: handleItemDeleteClick
        }
      : undefined;
  const isFirstParty = forceFirstParty || context?.currentUser?.id === track.user?.id;

  const handleMapCardClick = () => {
    updateCurrentlyHoveredResult?.(undefined);
    handleCardClick?.();
  };

  const handleMouseEnter = () => {
    messagingChannel?.publish?.('item.mouseenter', track);
    updateCurrentlyHoveredResult?.(track);
  };

  const handleMouseLeave = () => {
    messagingChannel?.publish?.('item.mouseleave', track);
    updateCurrentlyHoveredResult?.(undefined);
  };

  const maximumPhotos = Math.min(track.photo_count || 0, maxPhotos);

  if (track.type === 'map') {
    return (
      <MapCard
        languageRegionCode={languageRegionCode}
        onCardClick={handleMapCardClick}
        onCardMouseEnter={handleMouseEnter}
        onCardMouseLeave={handleMouseLeave}
        onFavoriteClick={handleFavoriteClick}
        isFavorite={isFavorite}
        variant="standard"
        map={getMapFromTrack(track, minutes)}
        maxPhotos={maximumPhotos}
        isFirstParty={isFirstParty}
        useMetric={context?.displayMetric}
        dragHandleProps={allowItemMoving ? dragHandleProps : undefined}
        deleteButton={deleteButton}
      />
    );
  }

  return (
    <NewActivityCard
      languageRegionCode={languageRegionCode}
      maxPhotos={maximumPhotos}
      onCardClick={handleMapCardClick}
      onCardMouseEnter={handleMouseEnter}
      onCardMouseLeave={handleMouseLeave}
      onFavoriteClick={handleFavoriteClick}
      isFavorite={isFavorite}
      variant="standard"
      activity={getActivityFromTrack(track, minutes)}
      isFirstParty={isFirstParty}
      useMetric={context?.displayMetric}
      dragHandleProps={allowItemMoving ? dragHandleProps : undefined}
      deleteButton={deleteButton}
    />
  );
};

export default ActivityCard;
