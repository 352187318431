import { useContext, lazy, Suspense } from 'react';
import classNames from 'classnames';
import isEqual from 'underscore/modules/isEqual';
import mapboxgl from 'mapbox-gl';
import { getSlidRoute } from 'api/TrailRedraw';
import { lineString } from '@turf/helpers';
import { useIntl } from '@alltrails/shared/react-intl';
import ReportLocation from '@alltrails/analytics/enums/ReportLocation';
import { getLines } from '@alltrails/maps/utils/legacyGeoJSONConversions';
import { MapConsumerShim, MapProvider } from 'components/MapProvider';
import MapExpandControl, { MapExpandContext } from 'components/MapExpandControl';
import hasPermission from 'utils/hasPermission';
import RouteControls from '../../../../components/MapCreator/Routing/RouteControls';
import ZoomControls from '../../../../components/map_controls/ZoomControls/index';
import MapSelection from '../../../../components/MapSelection';
import { addAtMap } from '../../../../utils/mapbox/overlays/at_map';
import { fitMapToBounds } from '../../../../utils/mapbox/map_helpers';
import { decodeLngLats } from '../../../../utils/at_map_helpers';
import { MapMixin } from '../mixins/map/map_mixin';
import { TrailPlannerMixin } from '../mixins/map/trail_planner_mixin';
import { addPolylines } from '../../../../utils/mapbox/overlays/polylines';
import Lightbox from '../../../../components/shared/Lightbox';
import { urlReformatPhotos } from '../../../../utils/lightbox_helpers';
import { getAdminCustomizationSettings, setAdminCustomizationSetting } from '../../../../utils/mapbox/admin_customization_helpers';
import { fireLayerEvent } from '../../../../utils/mapbox/layers/layer_helpers';
import { removeWaypoints } from '../../../../utils/mapbox/overlays/waypoints';

const ElevationChart = lazy(() => import('@alltrails/maps/components/ElevationChart'));

// eslint-disable-next-line @typescript-eslint/no-var-requires
const createReactClass = require('create-react-class');

const ContributeMap = createReactClass({
  mixins: [MapMixin, TrailPlannerMixin],
  getInitialState() {
    return {
      currentMapLayer: 'alltrailsOutdoorsV2',
      currentLayerTerrainEnabled: true,
      enabledOverlays: this.props.enabledOverlays || [],
      elevationCollapsed: false,
      isMapReady: false,
      trailPlanner: null,
      insertTrailPlanner: null,
      routingActive: true,
      routingMode: 'hiking',
      showRoutingTips: { SMART_TIP: true, DRAW_TIP: true, NODE_TIP: true },
      trailPlannerColor: hasPermission({ permission: 'trails:manage' }) ? 'blue' : undefined,
      selectedFilterActivity: '',
      adminCustomizationSettings: getAdminCustomizationSettings(),
      activeKeyModifier: '',
      slidRoute: null
    };
  },
  componentDidMount() {
    this.trailPlannerMessagingSubscriptions();
    this.initLegacyMap();

    if (hasPermission({ permission: 'trails:manage' })) {
      this.getSlidRoute();
    }

    this.listeners.push(this.props.messagingChannel.subscribe('waypoint.hover', this.handleWaypointHover));
  },
  componentDidUpdate(prevProps, prevState) {
    if (!this.state.isMapReady) {
      return;
    }

    if (this.isMapFirstRender(prevState) || !isEqual(this.props.exploreMap, prevProps.exploreMap)) {
      const areWaypointsChanged =
        prevProps.exploreMap?.waypoints?.length !== this.props.exploreMap?.waypoints?.length ||
        prevProps.exploreMap?.editWaypointId !== this.props.exploreMap?.editWaypointId;

      // if the waypoints are of unequal lengths or there is a new editing ID we need to remove waypoints layer
      // before re-rendering
      if (this.props.exploreMap && prevProps.exploreMap) {
        if (areWaypointsChanged) {
          removeWaypoints(this.mbMap, 'atMap-waypoints', this.props.messagingChannel);
        }
      }
      this.drawMap();
    }
  },
  async getSlidRoute() {
    const trailId = this.props.selectedObject?.type === 'trail' ? this.props.selectedObject.ID : undefined;
    if (!trailId) {
      return;
    }
    const route = await getSlidRoute(trailId);

    if (typeof route === 'object' && route !== null) {
      this.setState({ slidRoute: route });
    }
  },

  drawMap() {
    if (!this.props.exploreMap) {
      return;
    }
    const bounds = new mapboxgl.LngLatBounds();
    // Show polyline in blue for admins for visibility against heatmap
    const polylineOpts = this.state.trailPlannerColor ? { 'line-color': this.state.trailPlannerColor } : null;
    addAtMap({
      map: this.mbMap,
      atMap: this.props.exploreMap,
      renderWaypointPopup: this.renderWaypointPopup,
      handlePhotoClick: this.handlePhotoClick,
      bounds,
      withWaypointLabels: false,
      messagingChannel: this.props.messagingChannel,
      polylineOpts
    });
    fitMapToBounds(this.mbMap, bounds);

    // Special admin functionality for debugging turn-by-turn directions OSM map-matching
    if (hasPermission({ permission: 'trails:manage' }) && this.props.mapMatchPolyline) {
      const multiCoords = decodeLngLats(this.props.mapMatchPolyline);
      const geojson = lineString(multiCoords);
      addPolylines(this.mbMap, 'map-matched', geojson, {} /* sourceOpts */, { 'line-color': 'rgba(179, 0, 255, 0.5)', 'line-width': 7 });
    }
  },
  handleWaypointHover({ id, location }) {
    const layerId = 'atMap-waypoints';
    const sourceId = layerId;

    // fire mouseenter causing map to pan to include waypoint and to show popup
    const lngLat = [location.longitude, location.latitude];
    const features = this.mbMap.querySourceFeatures(sourceId, {
      filter: ['match', ['get', 'id'], id, true, false]
    });
    if (features.length > 0) {
      fireLayerEvent(this.mbMap, layerId, 'mouseenter', { lngLat, features });
    }
  },
  handleElevationGainChanged(elevationGain) {
    if (this.props.handleRouteInfoChanged) {
      this.props.handleRouteInfoChanged({ elevationGain });
    }
  },

  onElevationChartToggle(hidden) {
    const bottomRightCtrls = document.querySelectorAll('.mapboxgl-ctrl-bottom-right');
    const bottomLeftCtrls = document.querySelectorAll('.mapboxgl-ctrl-bottom-left');
    // Function to add or remove 'elevation-collapsed' class based on conditions
    const updateElevationClass = (elements, addClass) => {
      elements.forEach(element => {
        if (addClass) {
          element.classList.add('elevation-collapsed');
        } else {
          element.classList.remove('elevation-collapsed');
        }
      });
    };

    // Determine if classes should be added or removed based on conditions
    if (hidden) {
      updateElevationClass(bottomRightCtrls, true);
      updateElevationClass(bottomLeftCtrls, true);
    } else {
      updateElevationClass(bottomRightCtrls, false);
      updateElevationClass(bottomLeftCtrls, false);
    }
  },

  renderElevationPane() {
    if (this.state.trailPlanner || getLines(this.props.exploreMap).length > 0) {
      return (
        <Suspense fallback={<div>Loading...</div>}>
          <ElevationChart
            map={this.props.plannerMap || this.props.exploreMap}
            displayMetric={this.props.context.displayMetric}
            handleMouseOver={this.handleElevationPaneMouseOver}
            shouldFetchElevationData={this.state.trailPlanner}
            handleElevationGainChanged={this.handleElevationGainChanged}
            isAdmin={hasPermission({ permission: 'trails:manage' })}
            onElevationChartToggle={this.onElevationChartToggle}
          />
        </Suspense>
      );
    }
    return null;
  },
  handleActivityFilterChange(e) {
    this.setState({ selectedFilterActivity: e.target.value });
  },

  handlePhotoClick(photoId, photos) {
    const lightboxStartingIndex = photos.findIndex(p => p.id === photoId);
    this.setState({ lightboxOpen: true, lightboxStartingIndex, lightboxPhotos: photos });
  },

  handleLightboxClose() {
    this.setState({ lightboxOpen: false, lightboxStartingIndex: null, lightboxPhotos: [] });
  },

  handleAdminCustomizationSettingsChange(key, value) {
    const adminCustomizationSettings = { ...this.state.adminCustomizationSettings, [key]: value };
    setAdminCustomizationSetting(key, value);
    this.setState({ adminCustomizationSettings });
  },

  onMapFirstLoaded({ center, zoom }) {
    this.props.setMapInitialData({ center, zoom });
  },

  onMapMove({ center, zoom }) {
    this.props.setMapData({ center, zoom });
  },

  render() {
    let zoomControlsVariant = '';
    const mapStyle = {};
    let mapFullClass = 'full-height-under-header';

    const elevationPane = this.renderElevationPane();
    if (elevationPane) {
      if (!this.state.elevationCollapsed) {
        zoomControlsVariant = 'with-elevation-pane';
        mapFullClass += ' with-graph withNewElevationChart';
      }
    }

    const isAuthenticated = !!this.props.context.currentUser;
    const isAdminOrPro = isAuthenticated && (hasPermission({ permission: 'trails:manage' }) || this.props.context.currentUser.pro);

    if (this.props.isMapExpanded) {
      mapStyle.left = 0;
      // eslint-disable-next-line no-undef
      $('#edit-trail-contribute-box').css('display', 'none');
    } else {
      // eslint-disable-next-line no-undef
      $('#edit-trail-contribute-box').css('display', 'block');
    }

    const permissions = this.getPermissions();

    return (
      <MapProvider getOverlayConfigParams={this.getOverlayConfigParams} mapInstance={this.mbMap}>
        <MapConsumerShim onMapMove={this.onMapMove} onMapFirstLoaded={this.onMapFirstLoaded} />
        <div id="contribute-fullscreen-map" className={classNames(mapFullClass, { expanded: this.props.isMapExpanded })} style={mapStyle}>
          <MapExpandControl />
          <div id="map-controls" className="map-routing-controls">
            {this.state.trailPlanner && (
              <RouteControls
                nodeSelected={this.state.selectedRouteNode}
                routingActive={this.state.routingActive}
                routingMode={this.state.routingMode}
                canRouteAlongSlidRoute={!!this.state.slidRoute}
                onSelectRoutingMode={this.setRoutingMode}
                toggleRouting={this.toggleRouting}
                showRoutingTips={hasPermission({ permission: 'trails:manage' }) ? null : this.state.showRoutingTips}
                closeRoutingTip={this.toggleRoutingTip}
              />
            )}
          </div>
          <div id={this.props.mapDivId} className="full-height" />
          <ZoomControls
            variant={zoomControlsVariant}
            handleUndoClick={this.state.trailPlanner ? this.undoClick : undefined}
            handleRedoClick={this.state.trailPlanner ? this.redoClick : undefined}
            handleZoomInClick={this.zoomInClicked}
            handleZoomOutClick={this.zoomOutClicked}
            handleCurrLocClick={this.compassClick}
          />
          <MapSelection
            enabledOverlays={this.state.enabledOverlays}
            onActivityFilterChange={this.handleActivityFilterChange}
            selectedFilterActivity={this.state.selectedFilterActivity}
            adminCustomizationSettings={this.state.adminCustomizationSettings}
            handleAdminCustomizationSettingsChange={this.handleAdminCustomizationSettingsChange}
            currentMapLayer={this.state.currentMapLayer}
            onCardClick={this.handleCardClick}
            onHeatmapClick={this.handleHeatmapOverlayClick}
            permissions={permissions}
            terrainActive={this.state.terrainActive}
            handleResetClick={this.disableAllOverlays}
            adminLayout={hasPermission({ permission: 'trails:manage' })}
            currentLayerTerrainEnabled={this.state.currentLayerTerrainEnabled}
            handleDisable3D={this.disable3D}
            handleEnable3D={this.enable3D}
            isAuthenticated={isAuthenticated}
            isPro={isAdminOrPro}
            compassRotation={this.state.compassRotation}
            handleCompassClick={this.handleCompassClick}
            trailId={this.props.selectedObject?.type === 'trail' ? this.props.selectedObject.ID : undefined}
          />
          {elevationPane}
          {this.state.lightboxOpen && (
            <Lightbox
              images={urlReformatPhotos(this.state.lightboxPhotos)}
              handleCloseRequest={this.handleLightboxClose}
              startingIndex={this.state.lightboxStartingIndex}
              context={this.props.context}
              analyticsReportLocation={ReportLocation.ContributeWebPage}
            />
          )}
        </div>
      </MapProvider>
    );
  }
});

const ContributeMapWithContext = props => {
  const { isMapExpanded } = useContext(MapExpandContext);
  const intl = useIntl();

  return <ContributeMap {...props} isMapExpanded={isMapExpanded} intl={intl} />;
};

export default ContributeMapWithContext;
