import { Modal } from '@alltrails/modules/Modal';
import { FormattedMessage, FormattedNumber } from '@alltrails/shared/react-intl';
import Logo from '@alltrails/shared/denali/components/Logo';
import { useEffect } from 'react';
import logWebProModalShown from '@alltrails/analytics/events/logWebProModalShown';
import PurchaseScreenTypePlan from '@alltrails/analytics/enums/PurchaseScreenTypePlan';
import CarouselDisplayTrigger from '@alltrails/analytics/enums/CarouselDisplayTrigger';
import logWebProModalPurchaseButtonTapped from '@alltrails/analytics/events/logWebProModalPurchaseButtonTapped';
import WebProModalPurchaseButtonCopyText from '@alltrails/analytics/enums/WebProModalPurchaseButtonCopyText';
import useUser from 'hooks/useUser';
import Typography from '@alltrails/shared/denali/components/Typography';
import * as styles from './PreviewUpsellModal.module.scss';

type Props = {
  headingNonPromo: React.ReactNode;
  headingPromo: React.ReactNode;
  onCloseRequest: () => void;
  returnTo: string;
  trigger: CarouselDisplayTrigger;
};

// https://www.figma.com/design/PtYSSpohQ9rL7ZWCcdsYJK/2025-Upsell-System-%E2%80%A2-Handoff?node-id=1881-96486&m=dev
export default function PreviewUpsellModal({ headingNonPromo, headingPromo, onCloseRequest, returnTo, trigger }: Props) {
  const { currencyCode, plan } = __AT_DATA__;
  const user = useUser()!;
  const { isPromoEligible } = user;

  useEffect(() => {
    logWebProModalShown({
      purchase_screen_type_plan: isPromoEligible ? PurchaseScreenTypePlan.FreeTrial : PurchaseScreenTypePlan.NonTrial,
      trigger
    });
  }, [isPromoEligible, trigger]);

  const href = `/plus/checkout?${new URLSearchParams({ returnTo }).toString()}`;

  return (
    <Modal
      buttons={{
        fill: true,
        primaryButton: {
          linkInfo: {
            href
          },
          onClick: () => {
            logWebProModalPurchaseButtonTapped({
              copy_text: isPromoEligible
                ? WebProModalPurchaseButtonCopyText.StartYourFreeTrial
                : WebProModalPurchaseButtonCopyText.UpgradeToAllTrailsPlus,
              purchase_screen_type_plan: isPromoEligible ? PurchaseScreenTypePlan.FreeTrial : PurchaseScreenTypePlan.NonTrial,
              trigger
            });
          },
          testId: 'start-your-free-trial',
          text: isPromoEligible ? (
            <FormattedMessage defaultMessage="Start your free trial" />
          ) : (
            <FormattedMessage defaultMessage="Upgrade to AllTrails+" />
          )
        }
      }}
      caption={<FormattedMessage defaultMessage="Cancel anytime" />}
      contentsClassName={styles.contents}
      isOpen
      onCloseRequest={onCloseRequest}
    >
      <Logo color="dark" size="xs" variant="plus-logomark" />
      <Typography variant="heading600">{isPromoEligible ? headingPromo : headingNonPromo}</Typography>
      <Typography variant="text200">
        {isPromoEligible ? (
          <FormattedMessage
            defaultMessage="Get 7 days free, then only {annualCost}/year."
            values={{
              annualCost: (
                <b>
                  <FormattedNumber
                    currency={currencyCode}
                    // eslint-disable-next-line react/style-prop-object
                    style="currency"
                    value={plan.priceTotal}
                  />
                </b>
              )
            }}
          />
        ) : (
          <FormattedMessage
            defaultMessage="Only {annualCost}/year (that’s {monthlyCost}/month)."
            values={{
              annualCost: (
                <b>
                  <FormattedNumber
                    currency={currencyCode}
                    // eslint-disable-next-line react/style-prop-object
                    style="currency"
                    value={plan.priceTotal}
                  />
                </b>
              ),
              monthlyCost: (
                <b>
                  <FormattedNumber
                    currency={currencyCode}
                    // eslint-disable-next-line react/style-prop-object
                    style="currency"
                    value={plan.priceMonth}
                  />
                </b>
              )
            }}
          />
        )}
      </Typography>
    </Modal>
  );
}
