import { defineMessages, IntlShape, useIntl } from '@alltrails/shared/react-intl';
import TrailCard from 'components/cards/TrailCard';
import type { Context } from 'types/Context';
import Link from '@alltrails/shared/denali/components/Link';
import type Trail from 'types/Trails/Trail';

import hasPermission from 'utils/hasPermission';
import * as styles from './styles/styles.module.scss';

const messages = defineMessages({
  SUGGEST: { defaultMessage: 'Suggest' },
  ADD: { defaultMessage: 'Add' },
  AS_NEW_TRAIL: { defaultMessage: 'as new trail' },
  LINKED_TRAIL: { defaultMessage: 'Linked trail:' }
});

type PrivateData = {
  private?: boolean;
};

type Props = {
  trackTrail?: Trail;
  context: Context;
  data: PrivateData;
  hideText: string;
  handleAddAsTrailClick?: (e: React.MouseEvent<HTMLElement>) => void;
  handleHideShowTrackClick?: (e: React.MouseEvent<HTMLElement>) => void;
  resultCardFunctions?: any;
  listMethods?: any;
  messagingChannel?: {
    publish: () => void;
    subscribe: () => void;
  };
};

const addAsTrailLink = (
  context: Context,
  data: PrivateData,
  handleAddAsTrailClick: (e: React.MouseEvent<HTMLElement>) => void,
  intl: IntlShape
): JSX.Element => {
  if (context.currentUser && !data.private) {
    return (
      <Link onClick={handleAddAsTrailClick}>
        {hasPermission({ permission: 'trails:manage' }) ? intl.formatMessage(messages.ADD) : intl.formatMessage(messages.SUGGEST)}{' '}
        {intl.formatMessage(messages.AS_NEW_TRAIL)}
      </Link>
    );
  }
  return null;
};

const HideStatusLink = (hideText: string, handleHideShowTrackClick: (e: React.MouseEvent<HTMLElement>) => void): JSX.Element => {
  if (hasPermission({ permission: 'trails:manage' }) && hideText !== null) {
    return (
      <span className={styles.hideContainer}>
        <Link onClick={handleHideShowTrackClick}>{hideText}</Link>
      </span>
    );
  }
  return null;
};

const LinkedCard = ({
  trackTrail,
  context,
  data,
  hideText,
  handleAddAsTrailClick,
  handleHideShowTrackClick,
  resultCardFunctions,
  listMethods,
  messagingChannel
}: Props): JSX.Element => {
  const intl = useIntl();
  const addAsTrail = addAsTrailLink(context, data, handleAddAsTrailClick, intl);
  const hideStatus = HideStatusLink(hideText, handleHideShowTrackClick);
  const allowClickingCompletedBadge = () => resultCardFunctions?.allowClickingCompletedBadge(trackTrail?.type, trackTrail?.ID);
  if (trackTrail) {
    return (
      <div className={styles.inlineCard}>
        <div className={styles.cardHeading}>
          <span className={styles.associatedWithTrail}>{intl.formatMessage(messages.LINKED_TRAIL)}</span>
          <span className={styles.linksContainer}>
            {hideStatus}
            {addAsTrail}
          </span>
        </div>
        <TrailCard
          allowClickingCompletedBadge={!!allowClickingCompletedBadge}
          handleCardClick={() => resultCardFunctions?.handleTrailClick(trackTrail)}
          handleCompletedBadgeClick={() => resultCardFunctions?.handleCompletedBadgeClick(trackTrail?.type, trackTrail?.ID)}
          handleFavoriteClick={() => listMethods?.handleFavoriteClick({ type: trackTrail?.type, id: trackTrail?.ID, objectId: trackTrail?.objectID })}
          isCompleted={listMethods?.isComplete(trackTrail?.type, trackTrail?.ID)}
          isFavorite={listMethods?.isFavorite(trackTrail?.type, trackTrail?.ID)}
          isVerified={listMethods?.isVerified(trackTrail?.type, trackTrail?.ID)}
          key={`trail-${trackTrail?.ID}`}
          messagingChannel={messagingChannel}
          trail={trackTrail}
        />
      </div>
    );
  }

  if (addAsTrail != null) {
    return (
      <div className={`${styles.inlineCard} ${styles.captionOnly}`}>
        <span className={styles.linksContainer}>
          {hideStatus}
          {addAsTrail}
        </span>
      </div>
    );
  }
  return null;
};

export default LinkedCard;
