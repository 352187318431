import postal from 'postal';
import { PageStrings } from '@alltrails/shared/utils/constants/pageStringHelpers';
import CustomProvider from 'components/CustomProvider';
import { MapExpandProvider } from 'components/MapExpandControl/MapExpandProvider';
import { AdditionalStyleId } from '@alltrails/maps/types/Styles';
import { Trail } from '@alltrails/shared/types/trail';
import type { Context } from 'types/Context';
import { useCallback, useEffect, useState } from 'react';
import useListItems from 'hooks/useListItems';
import { ApplyEditsResponse } from 'api/Edits';
import hasPermission from 'utils/hasPermission';
import Waypoint from 'types/Waypoint';
import getTrailProps from 'api/Trail';
import logError from 'utils/logError';
import { Page } from '../../../../components/shared/Page/Page';
import { getFormattedTrailUrls } from '../../../../utils/trail_helpers';
import { LanguageSupportUtil } from '../../../../utils/language_support_util';
import ContributeMap from './contribute_map';
import EditTrailEndBox from './edit_trail_end_box';
import EditTrailBox from './EditTrailBox';

type Props = {
  trail?: ApplyEditsResponse | Trail;
  context: Context;
  initialMyLists?: any;
  initialMyListItems?: any;
  allowNameEditing: boolean;
  hasObjectPermissions?: boolean;
  currentCity: string;
  rapidRoute: boolean;
  enabledOverlays: AdditionalStyleId[];
  mapMatchPolyline: boolean;
  mapboxAccessToken: string;
};

const ContributeTrailApp = ({
  trail,
  context,
  initialMyLists,
  initialMyListItems,
  allowNameEditing,
  hasObjectPermissions,
  currentCity,
  rapidRoute,
  enabledOverlays,
  mapMatchPolyline,
  mapboxAccessToken
}: Props) => {
  const formatTrailUrl = (trail: Trail) => getFormattedTrailUrls(trail, context.languageRegionCode).localizedUrl;
  const resultCardFunctions = {
    handleTrailClick: (trail: Trail) => {
      // Open Trail SEO Pages in new window instead of default behavior (opening up Explore trail page)
      window.open(formatTrailUrl(trail), '_blank');
    },
    formatTrailUrl
  };
  const [defaultMap, setDefaultMap] = useState<any | null>(trail?.defaultMap);
  const [contributeState, setContributeState] = useState(trail ? 'editTrailActive' : 'editTrailStart');
  const [routeInfo, setRouteInfo] = useState<any | null>(null);
  const [insertRouteInfo, setInsertRouteInfo] = useState<any | null>(null);
  const [zoom, setZoom] = useState<number | null>(null);
  const [center, setCenter] = useState<number[] | null>(null);
  const [waypoints, setWaypoints] = useState<Waypoint[] | null>(null);
  const [waypointMapId, setWaypointMapId] = useState<number | null>(null);
  const [areWaypointsLoading, setAreWaypointsLoading] = useState(false);

  const messagingChannel = postal.channel('contribute_messagingChannel');
  const { listMethods, renderModal } = useListItems(
    {
      lists: initialMyLists,
      listItems: initialMyListItems
    },
    PageStrings.CONTRIBUTE_TRAIL
  );

  const refetchTrailProps = async () => {
    if (!trail.id) return;
    try {
      if (trail.id) {
        setAreWaypointsLoading(true);
        const data = await getTrailProps(trail.id, false);
        if (!data.overviewProps) {
          setAreWaypointsLoading(false);
          return;
        }
        if (data.overviewProps) {
          const waypointTab = data.overviewProps.tabs.find(tab => tab.key === 'waypoints');
          setWaypoints(waypointTab.value.waypoints);
          setWaypointMapId(waypointTab.value.id);
          setAreWaypointsLoading(false);
        }
      }
    } catch (e) {
      setAreWaypointsLoading(false);
      logError(`Error fetching trail props: ${e}`);
    }
  };

  useEffect(() => {
    if (trail) {
      refetchTrailProps();
    }
  }, [trail]);

  const handleWaypointsChanged = (data: any) => {
    let { waypoints } = data;
    const { editWaypointId, activeWaypoint } = data;

    // if a waypoint is new and doesn't have a lat / lng, we want to coordinate it with the map
    if (typeof activeWaypoint !== 'undefined' && activeWaypoint.location.longitude === null && activeWaypoint.location.latitude === null) {
      const latitude = center ? center[0] : (trail as ApplyEditsResponse).location.latitude;
      const longitude = center ? center[1] : (trail as ApplyEditsResponse).location.longitude;
      waypoints = waypoints.map((wp: Waypoint) => {
        if (wp.id === null) {
          return { ...wp, location: { longitude, latitude } };
        }

        return wp;
      });
      messagingChannel.publish('waypoint.dragged.with_location', { waypointId: activeWaypoint.id, location: { latitude, longitude } });
    }

    setDefaultMap({ ...defaultMap, waypoints, editWaypointId });
    setWaypoints(waypoints);
  };

  useEffect(() => {
    if (!messagingChannel) {
      return;
    }
    messagingChannel.subscribe('waypointsChanged', handleWaypointsChanged);
  }, [center]);

  const handleRouteInfoChanged = (routeInfoChanges: any) => {
    if (!routeInfoChanges) {
      setRouteInfo(null);
      return;
    }
    const newRouteInfo = routeInfo ? { ...routeInfo, ...routeInfoChanges } : routeInfoChanges;
    setRouteInfo(newRouteInfo);
  };

  const handleInsertRouteInfoChanged = (routeInfoChanges: any) => {
    if (!routeInfoChanges) {
      setInsertRouteInfo(null);
      return;
    }
    const newInsertRouteInfo = insertRouteInfo ? { ...insertRouteInfo, ...routeInfoChanges } : routeInfoChanges;
    setInsertRouteInfo(newInsertRouteInfo);
  };

  const handleMapChange = (defaultMap: any) => {
    setDefaultMap(defaultMap);
  };

  const setMapData = ({ center, zoom }: { center: number[]; zoom: number }) => {
    setCenter(center);
    setZoom(zoom);
  };

  const endEditTrail = () => {
    setContributeState('editTrailEnd');
  };

  const handleAddTrailClick = () => {
    const url = LanguageSupportUtil.wrapUrlSafe(
      `/trail/new?latitude=${center?.[0]}&longitude=${center?.[1]}&zoom=${zoom}`,
      context.languageRegionCode
    );
    window.open(url, '_blank');
  };

  // TypeScript complains without this
  const EditTrailBoxProps = {
    allowNameEditing,
    hasObjectPermissions,
    context,
    currentCity,
    displayMetric: context ? context.displayMetric : null,
    handleAddTrailClick,
    key: `editTrail-${trail?.id}`,
    mapCenter: center,
    zoom,
    messagingChannel,
    nextTrailCallback: endEditTrail,
    rapidRoute,
    routeInfo,
    insertRouteInfo,
    trail,
    handleMapChange,
    defaultMap,
    mapboxAccessToken,
    waypointMapId,
    waypoints,
    refetchTrailProps,
    areWaypointsLoading
  };

  const contributeBox =
    // eslint-disable-next-line no-nested-ternary
    contributeState === 'editTrailActive' ? (
      <EditTrailBox {...EditTrailBoxProps} hasAdminFeatures={hasPermission({ permission: 'trails:manage' })} />
    ) : contributeState === 'editTrailEnd' ? (
      <EditTrailEndBox key="editTrailEnd" trail={trail} context={context} />
    ) : null;

  const id = trail?.id;
  const name = trail?.name;
  const slug = trail?.slug;
  const trailObj = id && name && slug ? { ID: id, type: 'trail', name, slug } : null;
  let initialCenter;
  if (trail && (trail as ApplyEditsResponse)) {
    const { latitude, longitude, zoom } = (trail as ApplyEditsResponse).location;
    if (zoom && latitude && longitude) {
      initialCenter = [latitude, longitude, zoom];
    }
  }

  useEffect(() => {
    if (defaultMap && trail && !defaultMap?.trailId) {
      setDefaultMap({ ...defaultMap, trailId: trail.id });
    }
  }, [trail]);

  return (
    <Page context={context}>
      <div id="contribute-fullscreen" className="full-height" data-testid="contribute-fullscreen">
        <div id="modalPortal" />
        {contributeBox}
        {renderModal?.()}
        <MapExpandProvider>
          <ContributeMap
            context={context}
            currentPage={PageStrings.CONTRIBUTE_TRAIL}
            enabledOverlays={enabledOverlays}
            exploreMap={defaultMap}
            profilePhoto={trail?.defaultPhoto}
            hasAdminFeatures={hasPermission({ permission: 'trails:manage' })}
            id="contribute-map"
            initialCenter={initialCenter}
            mapboxAccessToken={mapboxAccessToken}
            mapDivId="map"
            mapMatchPolyline={mapMatchPolyline}
            mapProviderName="mapBox"
            messagingChannel={messagingChannel}
            plannerMap={routeInfo ? routeInfo.plannerMap : null}
            handleRouteInfoChanged={handleRouteInfoChanged}
            handleInsertRouteInfoChanged={handleInsertRouteInfoChanged}
            resultCardFunctions={resultCardFunctions}
            selectedObject={trailObj}
            setMapData={setMapData}
            setMapInitialData={setMapData}
            initialMyListItems={initialMyListItems}
            initialMyLists={initialMyLists}
            listMethods={listMethods}
          />
        </MapExpandProvider>
      </div>
    </Page>
  );
};

export default function ContributeTrailAppWrapper(props: Props) {
  return (
    <CustomProvider>
      <ContributeTrailApp {...props} />
    </CustomProvider>
  );
}
