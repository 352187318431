import Typography from '@alltrails/shared/denali/components/Typography';
import { FormattedMessage, useIntl } from '@alltrails/shared/react-intl';
import * as styles from './styles/styles.module.scss';

type Props = {
  pctLowTrafficCells10?: number;
  heatmapFlag: boolean;
  overlappingSegmentsFlag: boolean;
  sharpAnglesFlag: boolean;
};

const TrailRedrawStats = ({ pctLowTrafficCells10, heatmapFlag, overlappingSegmentsFlag, sharpAnglesFlag }: Props) => {
  const intl = useIntl();
  const heatmapScore = 1 - pctLowTrafficCells10 || 0;
  const redrawIssues: string[] = [];
  if (heatmapFlag) {
    redrawIssues.push(intl.formatMessage({ defaultMessage: 'Heatmap' }));
  }
  if (overlappingSegmentsFlag) {
    redrawIssues.push(intl.formatMessage({ defaultMessage: 'Overlapping segments' }));
  }
  if (sharpAnglesFlag) {
    redrawIssues.push(intl.formatMessage({ defaultMessage: 'Sharp angles' }));
  }

  return (
    <div className={styles.topMargin}>
      <Typography variant="text100" component="div" mb="12">
        <FormattedMessage defaultMessage="Heatmap score: {score}%" values={{ score: heatmapScore * 100 }} />
      </Typography>
      <Typography variant="text100" component="div">
        <FormattedMessage
          defaultMessage="Redraw issue: {issues}"
          values={{ issues: redrawIssues.length ? redrawIssues.join(', ') : intl.formatMessage({ defaultMessage: 'none' }) }}
        />
      </Typography>
    </div>
  );
};

export default TrailRedrawStats;
