import ApplicationLayout from 'components/ApplicationLayout';
import CustomProvider from 'components/CustomProvider';
import PasswordResetForm from 'components/ResetPasswordForm';
import { Context } from 'types/Context';
import * as styles from './ResetPassword.module.scss';

// https://github.com/alltrails/alltrails/blob/a0485398dc5995ae2d5ae1b8bd41a3035627c527/app/views/devise/passwords/edit.html.erb#L1
type Props = {
  context: Context;
  currentPage: string;
  locationSlug: string;
  resetPasswordToken: string;
  resourceName: string;
  resetPasswordUrl: string;
};

export default function ResetPassword({ context, currentPage, locationSlug, resetPasswordToken, resourceName, resetPasswordUrl }: Props) {
  return (
    <CustomProvider>
      <ApplicationLayout context={context} currentPage={currentPage} locationSlug={locationSlug}>
        <div className={styles.content}>
          <PasswordResetForm resetPasswordToken={resetPasswordToken} resourceName={resourceName} resetPasswordUrl={resetPasswordUrl} />
        </div>
      </ApplicationLayout>
    </CustomProvider>
  );
}
